import { RefObject, useEffect, useState } from 'react';
import setInitialSheetHeight from '../utils/setInitialSheetHeight';

/**
 * When sheet is opened, update height to the default snap point, and update the sheetInitialised value to show when it's done
 */
const useSheetInitialisation = (
    isSheetOpen: boolean,
    sheetRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    goToSnapPoint: (snapPoint: number) => void,
    closeSheetWithTransition: () => void,
    isSheetMounted: boolean,
    cancelInProgressAnimations: () => void,
    sheetContentRef: RefObject<HTMLDivElement>,
    onOpenTransitionEnd?: () => void,
): boolean => {
    const [sheetInitialised, setSheetInitialised] = useState<boolean>(false);

    const isSheetOpenAndMounted = isSheetOpen && isSheetMounted;

    /**
     * Close the sheet when going back in the browser history.
     * e.g. when the user presses the browser or native back button
     */
    const closeSheetOnBack = () => {
        // Add a blank history state to allow the user to go back
        window.history.pushState({}, '');
        window.addEventListener('popstate', closeSheetWithTransition, { once: true });
    };

    useEffect(() => {
        // Trigger sheet to start animating closed
        if (!isSheetOpen) {
            closeSheetWithTransition();
        }
    }, [isSheetOpen]);

    useEffect(() => {
        if (isSheetOpenAndMounted) {
            closeSheetOnBack();
            setInitialSheetHeight(
                sheetRef,
                defaultSnapPoint,
                addSnapPoint,
                goToSnapPoint,
                setSheetInitialised,
                cancelInProgressAnimations,
                sheetContentRef,
                onOpenTransitionEnd,
            );
        } else {
            setSheetInitialised(false);
        }
    }, [isSheetOpenAndMounted]);

    return sheetInitialised;
};

export default useSheetInitialisation;
