// Lib
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SharePlugin } from '../../../capacitor_plugins/share';

// Actions
import {
    createCardElementForShareExtension,
    createImageElementForShareExtension,
    createVideoElementForShareExtension,
} from '../actions/shareExtensionActions';

// Constants
import { CAPACITOR_SHARE_PLUGIN_NAME } from '../../../capacitor_plugins/pluginConstants';

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Capacitor.isPluginAvailable(CAPACITOR_SHARE_PLUGIN_NAME)) return;

        SharePlugin.addListener('onShareText', ({ text }: { text: string }) => {
            dispatch(createCardElementForShareExtension(text));
        });

        SharePlugin.addListener('onShareImageUri', ({ uri, fileName }: { uri: string, fileName?: string }) => {
            dispatch(createImageElementForShareExtension(uri, fileName));
        });

        SharePlugin.addListener('onShareVideoUri', ({ uri, fileName }: { uri: string, fileName?: string }) => {
            dispatch(createVideoElementForShareExtension(uri, fileName));
        });

        SharePlugin.addListener('onShareOtherFileUri', ({ uri, receivedMimeType, fileName }: { uri: string, receivedMimeType?: string, fileName?: string }) => {
            dispatch(createFileElementForShareExtension(uri, receivedMimeType, fileName));
        });

        return () => {
            SharePlugin.removeAllListeners();
        };
    }, []);
}
