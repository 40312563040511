// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { identity, defer } from 'lodash';
import { pure, withHandlers } from '../../node_module_clones/recompose';

// Util
import { markEventAsHandled } from '../utils/react/reactEventUtil';
import * as elementRegistry from '../../common/elements/elementRegistry';
import { isDOMNodeInView, isPrimaryButton, stopPropagationOnly } from '../utils/domUtil';
import { getEventSelectionMode } from './selection/selectionUtils';
import { isTask, isBoard, isLine, isTaskList } from '../../common/elements/utils/elementTypeUtils';
import makeElementSelector from './selectors/elementSelector';
import { noLonger, now } from '../utils/react/propsComparisons';
import { clearSelection } from '../utils/keyboard/contentEditable';
import { getElementId, getElementType, getLocationParentId } from '../../common/elements/utils/elementPropertyUtils';
import { canFocusElement } from '../workspace/presentation/focusMode/focusModeUtils';
import { isReadOnly } from '../../common/permissions/permissionUtil';
import { hasCommandModifier } from '../utils/keyboard/keyboardUtility';
import { getLocalHidden } from './local/elementLocalDataUtils';
import currentlyVisibleElementsSingleton from './elementVisibility/currentlyVisibleElementsSingleton';
import { getObserverRootOptions } from './elementVisibility/elementContentVisibleObserverUtils';
import { canApplyDragClassManually } from '../workspace/dnd/elementDragUtils';

// Custom Decorators
import { zoomStateContextConsumer } from '../canvas/zoom/ZoomStateContext';
import { poiBoardSectionContextConsumer } from '../components/pointsOfInterest/PoiBoardSectionContext';
import measureElementDecorator from '../components/measurementsStore/elementMeasurements/measureElementDecorator';
import DraggableElement, { shouldAttachDragSource } from './dnd/DraggableElement';
import renderElementPlaceholderDecorator from './elementPlaceholder/renderElementPlaceholderDecorator';

// Components
import AnimationLock from '../components/animations/AnimationLock';
import ElementContentVisibleObserver from './elementVisibility/ElementContentVisibleObserver';

// Actions
import elementContainerMapDispatchToProps from './elementContainerMapDispatchToProps';

// Constants
import { MODIFIER_KEYS } from '../utils/dnd/modifierKeys/dragModifierKeysConstants';
import { HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL } from '../hybridApp/store/hybridAppStoreConstants';

// Styles
import './Element.scss';

const isNoLongerResizing = noLonger('isResizing');
const isNowDragging = now('isDragging');

const SMALL_VISUAL_VIEWPORT_HEIGHT = 550;

@connect(makeElementSelector, elementContainerMapDispatchToProps)
@poiBoardSectionContextConsumer
@zoomStateContextConsumer
@withHandlers({
    stopEditing:
        ({ dispatchStopEditing, element }) =>
        () =>
            element && dispatchStopEditing(getElementId(element)),
})
@DraggableElement
@measureElementDecorator
@renderElementPlaceholderDecorator
@pure
class ElementContainer extends React.Component {
    constructor(props) {
        super(props);

        this.preventClick = false;

        this.state = {
            isBatchedRenderInProgress: false,
        };
    }

    componentWillMount() {
        const { connectDragPreview } = this.props;
        if (connectDragPreview) {
            connectDragPreview(getEmptyImage());
        }
    }

    componentDidMount() {
        if (this.props.isSelected) {
            const domNode = ReactDOM.findDOMNode(this);
            if (!isDOMNodeInView(domNode)) domNode.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
    }

    componentWillReceiveProps(nextProps) {
        // Prevent a click which occurs immediately after a resize from selecting the element
        if (isNoLongerResizing(this.props, nextProps)) {
            this.preventClick = true;
            defer(() => {
                this.preventClick = false;
            });
        }
    }

    componentWillUnmount() {
        currentlyVisibleElementsSingleton.remove(this.props.elementId);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.animating) return false;

        // Any time it is a copy/alt drag, do a regular render as a different class should be applied.
        if (nextProps.dragModifierKeys.get(MODIFIER_KEYS.altKey)) return true;

        // This is to avoid re-rendering elements when isDragging is set to TRUE for performance improvements,
        // We don't prevent render when isDragging is set to false as other props may have changed that require a re-render
        if (isNowDragging(this.props, nextProps) && canApplyDragClassManually(this.props.element)) return false;

        return true;
    }

    /**
     * If the element receives the mouse down event (because an input hasn't stopped propagation of the mouse down
     * event).
     */
    onMouseDown = (event) => {
        if (event.handled) {
            // Reset the mouseDownFired state, so it doesn't stay as true on a future click
            this.mouseDownFired = false;
            return;
        }

        // This is added here due to this issue: https://github.com/Milanote/milanote/issues/3720
        // In Safari when text is selected and a drag start the browser seems to get confused and think text is being
        // dragged rather than the element div.
        // By clearing the selection on mouse-down the browser no longer gets confused.
        // NOTE: onDragStart is too late as the browser already thinks text is being dragged by then.
        // NOTE 2: This works because text input fields stop propagation onMouseDown so this handler doesn't get fired
        // when interacting with text fields.  If that proves to be incorrect we'll have to revisit this.
        clearSelection();

        this.mouseDownFired = true;

        // NOTE: event.stopPropagation() used to be called below here, however it interfered with the React DnD touch
        //  backend when trying to use mouse events to drag. This is useful on iPads to avoid long delays when using
        //  the native HTML5 DnD backend.
        // This used to stop parent ElementContainers from handling mouse events when a child has already handled
        //  the mouse event. Now, that responsibility is taken by the "markEventAsHandled" call and the
        //  "if (event.handled)" logic higher up in this function.
        markEventAsHandled(event);

        const {
            isRemotelySelected,
            isLocked,
            stopEditing,
            documentMode,
            isEditing,
            isEditingChild,
            isSelected,
            shouldFocusOnlyWhenSelected,
            element,
        } = this.props;
        if (documentMode) return;

        // FIXME-NOTES-SELECTION: Clean this up after the new notes selection feature is enabled
        // dont select the element directly when it is locked and it has the new notes selection feature enabled
        if (shouldFocusOnlyWhenSelected && isLocked && !isSelected) return;

        if (isLocked && !isSelected) this.selectThisElement(event);

        if (isTaskList(element) && isEditingChild) stopEditing();

        if (!isRemotelySelected && isEditing) stopEditing();
    };

    onClick = (event) => {
        // If the mousedown event didn't occur on this element then don't handle
        if (!this.mouseDownFired) return;

        this.mouseDownFired = false;

        const {
            isLocked,
            shouldFocusOnlyWhenSelected,
            dispatchGetCurrentlyEditingElement,
            dispatchDeselectAllElements,
            elementId,
        } = this.props;

        const currentlyEditingElement = dispatchGetCurrentlyEditingElement();

        // On a small screen its more likely that the user is simply tapping to deselect
        // or finish editing the element rather than wanting to select the new element
        if (
            currentlyEditingElement &&
            currentlyEditingElement !== elementId &&
            window.visualViewport.height <= SMALL_VISUAL_VIEWPORT_HEIGHT
        ) {
            dispatchDeselectAllElements();
            return;
        }

        // The locked selection is already handled by the onMouseDown
        // disable this behaviour for the new notes selection feature
        if (!shouldFocusOnlyWhenSelected && isLocked) return;

        // Clicks will be prevented during a resize as we don't want to select the element after resizing
        if (this.preventClick) return;

        this.selectThisElement(event);
    };

    onContextMenu = (event) => {
        const { element, isLocked, isEditing, isSelected, isEditable, dispatchGetSelectedElementIds } = this.props;
        // If the mousedown event didn't occur on this element then don't handle
        //
        // On the hybrid app, the context menu gesture doesn't trigger after a mouse down,
        // so we need a special way to tell if the event has came from there.
        // See hybridAppCustomDOMEventMiddleware.js for more detail.
        if (!this.mouseDownFired && event.detail !== HYBRID_APP_FAKE_CONTEXT_MENU_EVENT_DETAIL) return;

        this.mouseDownFired = false;

        if (
            isEditing || // Don't select the element if user is editing
            !isEditable || // Don't preventDefault if not editable
            isSelected || // Don't select the element on right click if it's already selected;
            isLocked || // The locked selection is already handled by the onMouseDown
            this.preventClick // Clicks will be prevented during a resize as we don't want to select the element after resizing
        )
            return;

        event.preventDefault();

        const selectedElementIds = dispatchGetSelectedElementIds();
        if (selectedElementIds.includes(getLocationParentId(element))) return;

        this.selectThisElement();
    };

    startEditing = ({ editorId, editorKey, inputType, editorFocusClientCoords }) => {
        const { element, isEditable, isRemotelyActive, dispatchStartEditingElement } = this.props;

        if (!isEditable || isRemotelyActive) return;

        dispatchStartEditingElement({
            id: getElementId(element),
            editorId,
            editorKey,
            inputType,
            editorFocusClientCoords,
        });
    };

    selectThisElement = (event) => {
        const {
            isFocusedForegroundElement,
            isRemotelyActive,
            element,
            permissions,
            getContextZoomScale,
            dispatchHandleSelectionModeSelectElement,
            dispatchShowGuestAppRegistrationForm,
            dispatchFocusElementStart,
            isPresentationModeEnabled,
            dispatchGetPresentationModeIsFocusModeAllowed,
        } = this.props;

        const elementId = getElementId(element);

        const shouldEnterFocusMode =
            isPresentationModeEnabled &&
            dispatchGetPresentationModeIsFocusModeAllowed() &&
            canFocusElement(element) &&
            !isFocusedForegroundElement &&
            event &&
            isPrimaryButton(event) &&
            !hasCommandModifier(event);

        if (shouldEnterFocusMode) {
            event && event.stopPropagation();
            event && event.preventDefault();

            const zoomScale = getContextZoomScale();

            // eslint-disable-next-line no-unused-vars
            const { measureElementRef, ...focusElementProps } = this.props;

            // Not sure if we should be filtering these props? E.g. Removing functions?
            dispatchFocusElementStart(elementId, zoomScale, focusElementProps);
            return;
        }

        if (!isBoard(element) && isReadOnly(permissions)) return dispatchShowGuestAppRegistrationForm();

        event && event.stopPropagation();

        if (isRemotelyActive) return;

        const selectionMode = getEventSelectionMode(event);

        return dispatchHandleSelectionModeSelectElement(elementId, selectionMode);
    };

    setIsBatchedRenderInProgress = (isBatchedRenderInProgress) => {
        this.setState({ isBatchedRenderInProgress });
    };

    elementEvents = {
        onMouseDown: this.onMouseDown,
        onClick: this.onClick,
        onDoubleClick: stopPropagationOnly,
        onContextMenu: this.onContextMenu,
    };

    render() {
        const {
            element,
            elementLocalData,
            elementId,
            isDragging,
            connectDragSource,
            dragModifierKeys,
            isResizing,
            animating,
            isClipboardCut,
            onVisibilityChange,
            shouldRenderPlaceholder,
            placeholderElement,
            allowElementPlaceholder,
            inTrash,
            boardSection,
        } = this.props;

        if (isClipboardCut) return null;

        const DisplayElement = elementRegistry.getElementContainerComponent(getElementType(element));
        if (!DisplayElement) {
            console.warn('Unable to find a display element for:', element);
            return <div />;
        }

        const copyDrag = dragModifierKeys.get(MODIFIER_KEYS.altKey);

        const elementClasses = classNames('Element', {
            dragging: isDragging && !copyDrag,
            copy: isDragging && copyDrag,
            resizing: isResizing,
            hidden: getLocalHidden(elementLocalData),
        });

        const observerRootOptions = getObserverRootOptions(boardSection, inTrash);

        // Columns use orchestrated list, we don't set the visibility until it finishes rendering otherwise it might not render into the visible area
        const setContentVisibility = !allowElementPlaceholder && !this.state.isBatchedRenderInProgress;

        const elementRender = (
            <div ref={this.props.measureElementRef} className={elementClasses} data-element-id={getElementId(element)}>
                <ElementContentVisibleObserver
                    domRef={this.props.measureElementRef}
                    onVisibilityChange={onVisibilityChange}
                    enable={!isLine(element)}
                    observerRootOptions={observerRootOptions}
                    setContentVisibility={setContentVisibility}
                    elementId={elementId}
                />
                <AnimationLock animating={animating}>
                    {shouldRenderPlaceholder ? (
                        placeholderElement
                    ) : (
                        <DisplayElement
                            selectThisElement={this.selectThisElement}
                            startEditing={this.startEditing}
                            elementEvents={this.elementEvents}
                            setIsBatchedRenderInProgress={this.setIsBatchedRenderInProgress}
                            {...this.props}
                        />
                    )}
                </AnimationLock>
            </div>
        );

        const wrappingFn = shouldAttachDragSource(this.props) && !isTask(element) ? connectDragSource : identity;
        return wrappingFn(elementRender);
    }
}

ElementContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementLocalData: PropTypes.object,
    elementId: PropTypes.string.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    isRemotelyActive: PropTypes.bool,
    isFocusedForegroundElement: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditingChild: PropTypes.bool,
    isEditable: PropTypes.bool,
    isDraggable: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isLocked: PropTypes.bool,
    isClipboardCut: PropTypes.bool,
    isOver: PropTypes.bool,
    isDragging: PropTypes.bool,
    isResizing: PropTypes.bool,
    isHovered: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    permissions: PropTypes.number,
    remoteSelectionData: PropTypes.object,
    connectDropTarget: PropTypes.func,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    dispatchHandleSelectionModeSelectElement: PropTypes.func,
    dispatchStartEditingElement: PropTypes.func,
    dispatchUpdateElement: PropTypes.func,
    dispatchStopEditing: PropTypes.func,
    dispatchDeselectAllElements: PropTypes.func,
    stopEditing: PropTypes.func,
    documentMode: PropTypes.bool,
    dragModifierKeys: PropTypes.object,
    dispatchGetSelectedElementIds: PropTypes.func,
    dispatchGetCurrentlyEditingElement: PropTypes.func,

    animating: PropTypes.bool,
    dispatchShowGuestAppRegistrationForm: PropTypes.func,
    measureElementRef: PropTypes.object,

    isPresentationModeEnabled: PropTypes.bool,
    getContextZoomScale: PropTypes.func,
    dispatchFocusElementStart: PropTypes.func,
    dispatchGetPresentationModeIsFocusModeAllowed: PropTypes.func,

    onVisibilityChange: PropTypes.func,
    shouldRenderPlaceholder: PropTypes.bool,
    placeholderElement: PropTypes.element,
    allowElementPlaceholder: PropTypes.bool,
    inTrash: PropTypes.bool,
    boardSection: PropTypes.string,
};

export default ElementContainer;
