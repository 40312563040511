// Utils
import { PlatformDetails } from '../../common/platform/platformTypes';
import { setLastAppMode } from './utils/platformAppModeUtils';
import { getInitialPlatformDetails } from './utils/platformInitialisationUtils';

export const platformSingleton = getInitialPlatformDetails();

/**
 * This is "dangerous" because it will update the singleton without triggering a redux update.
 * This method should only ever get called from the platformReducer to ensure they remain in sync.
 */
export const dangerouslyUpdatePlatformSingletonDetails = (newPlatform: Partial<PlatformDetails>): void => {
    Object.assign(platformSingleton, newPlatform);
    setLastAppMode(platformSingleton.appMode);
};

export default platformSingleton;
