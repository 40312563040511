import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsMobileAppMode } from '../../platform/platformSelector';

/**
 * HOC to switch between web and mobile components based on the client platform
 *
 * Typescript note: the returned component's props will be the union of both input components' props.
 * Ideally the input components will share a common prop type, but this allows for a bit of flexibility.
 */
export const MobileComponentSwitch =
    <A, B>(WebComponent: React.ComponentType<A>, MobileComponent: React.ComponentType<B>): React.FC<A & B> =>
    (props: any) => {
        const isMobile = useSelector(selectIsMobileAppMode);

        const Component = isMobile ? MobileComponent : WebComponent;
        return <Component {...props} />;
    };
