import React, { InputHTMLAttributes } from 'react';
import SearchClearButton from '../../../../components/form/SearchClearButton';
import Icon from '../../../../components/icons/Icon';

import './SheetSearchBar.scss';

type SheetSearchBarProps = {
    searchFilter: string;
    setSearchFilter: (value: string) => void;
    searchPlaceholder?: string;
    enterKeyHint?: InputHTMLAttributes<unknown>['enterKeyHint'];
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: (e: React.FormEvent) => void;
};

const SheetSearchBar = ({
    searchFilter,
    setSearchFilter,
    searchPlaceholder = '',
    enterKeyHint,
    onSubmit,
    onChange,
}: SheetSearchBarProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        inputRef.current?.blur();
        onSubmit?.(e);
    };

    return (
        <div className="SheetSearchBar">
            <form onSubmit={onSubmitHandler}>
                <Icon name="search-magnifying-glass" />
                <input
                    ref={inputRef}
                    type="text"
                    name={'search'}
                    value={searchFilter}
                    onChange={(e) => {
                        setSearchFilter(e.target.value);
                        onChange?.(e);
                    }}
                    placeholder={searchPlaceholder}
                    enterKeyHint={enterKeyHint}
                />
                <SearchClearButton
                    show={searchFilter.length > 0}
                    canClear={searchFilter.length > 0}
                    onClick={() => {
                        setSearchFilter('');
                    }}
                />
            </form>
            <div className="search-bar-fade" />
        </div>
    );
};

export default SheetSearchBar;
