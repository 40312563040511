import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuCardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-card"
        {...props}
    >
        <path
            fill="#323B4A"
            fillRule="evenodd"
            d="M20.5 4a2.25 2.25 0 0 1 2.25 2.25v11.5A2.25 2.25 0 0 1 20.5 20h-17a2.25 2.25 0 0 1-2.25-2.25V6.25A2.25 2.25 0 0 1 3.5 4h17zm0 1.5h-17a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h17a.75.75 0 0 0 .75-.75V6.25a.75.75 0 0 0-.75-.75zM15.75 13a.75.75 0 1 1 0 1.5h-9.5a.75.75 0 1 1 0-1.5h9.5zm2-4c.414 0 .75.375.75.75s-.336.75-.75.75H6.25c-.414 0-.75-.375-.75-.75S5.836 9 6.25 9h11.5z"
        />
    </svg>
);
const Memo = memo(ContextMenuCardIcon);
export default Memo;
