import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { getSelectedIcon, getSelectedImage } from './iconToolUtils';

// Selectors
import popupOpenSelector from '../../../../../components/popupPanel/popupOpenSelector';
import { popupModeSelector, firstElementIdSelector } from './popup/iconPopupSelector';

// Components
import Tool from '../../Tool';
import ElementIcon from '../../../../../components/elementIcon/ElementIcon';
import IconPopup from './popup/IconPopup';
import IconToolImage from './popup/IconToolImage';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';

// Actions
import { togglePopup, closePopup } from '../../../../../components/popupPanel/popupActions';

// Styles
import './IconTool.scss';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const iconToolSelector = createSelector(
    popupOpenSelector(PopupIds.ICON),
    popupModeSelector,
    firstElementIdSelector,
    (popupIsOpen, popupMode, firstElementId) => ({
        popupIsOpen,
        popupMode,
        firstElementId,
    }),
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    openIconPopup: () => dispatch(togglePopup(PopupIds.ICON)),
    closeIconPopup: () => dispatch(closePopup(PopupIds.ICON)),
});

const IconTool = (props) => {
    const { openIconPopup, selectedElements, popupIsOpen, closeIconPopup } = props;
    const selectedImage = getSelectedImage(selectedElements);
    const selectedIcon = getSelectedIcon(selectedElements);

    const iconElement = selectedImage ? (
        <IconToolImage {...props} imageSource={selectedImage} />
    ) : (
        <ElementIcon {...props} icon={selectedIcon} />
    );

    return (
        <div className="icon-tool-container">
            <Tool className="IconTool" name="Icon" onClick={openIconPopup} isActive={popupIsOpen}>
                {iconElement}
            </Tool>
            <ToolbarPopup
                popupId={PopupIds.ICON}
                className="icon-popup"
                buttonSelector=".IconTool .icon"
                closePopup={closeIconPopup}
            >
                <IconPopup {...props} />
            </ToolbarPopup>
        </div>
    );
};

IconTool.propTypes = {
    gridSize: PropTypes.number.isRequired,
    openIconPopup: PropTypes.func,
    closeIconPopup: PropTypes.func,
    popupIsOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    firstElementId: PropTypes.string,
};

export default connect(iconToolSelector, mapDispatchToProps)(IconTool);
