// Utils
import { getTimestamp } from '../../../common/utils/timeUtil';

// Constants
import { TOOLTIP_SHOW, TOOLTIP_HIDE } from './tooltipConstants';

export const showTooltip = ({ id, tooltip, fade = false }) => ({
    type: TOOLTIP_SHOW,
    id,
    tooltip,
    fade,
    activeTimestamp: getTimestamp() + (tooltip?.delay || 0),
});

export const hideTooltip = () => ({
    type: TOOLTIP_HIDE,
});
