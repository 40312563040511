export const LINK_DISPLAY_NAME = 'Link';

export const LINK_ELEMENT_LINK_TYPES = {
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    MAP: 'MAP',
};

export const PROVIDERS = {
    Twitter: 'Twitter',
    LinkedIn: 'linkedin',
    GoogleMaps: 'Google Maps',
    Google: 'Google',
    GoogleDocs: 'Google Docs',
    GitHub: 'GitHub',
    YouTube: 'YouTube',
    Vimeo: 'Vimeo',
    CodePen: 'CodePen',
    SoundCloud: 'SoundCloud',
    Spotify: 'Spotify',
    Figma: 'Figma',
    Airtable: 'Airtable',
    Instagram: 'instagram',
    Behance: 'Behance',
    Facebook: 'Facebook',
    ProductHunt: 'Product Hunt',
    TwitchTV: 'Twitch.tv',
    Tumblr: 'Tumblr',
    MarvelApp: 'MarvelApp',
    Kickstarter: 'Kickstarter',
    Typeform: 'Typeform',
    ConvertKit: 'ConvertKit',
    IndieGoGo: 'IndieGoGo',
    Gumroad: 'Gumroad',
    OverflowIO: 'Overflow.io',
    SmugMug: 'SmugMug',
    Reddit: 'reddit',
    Loom: 'Loom',
    Wistia: 'Wistia, Inc.',
};

export const DOMAIN_PROVIDERS: { [key: string]: string } = {
    'docs.google.com': PROVIDERS.GoogleDocs,
};
