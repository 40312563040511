import { getSnapPointSheetHeight } from '../../../../mobile/structural/sheet/utils/sheetHeightUtils';
import { RefObject, useEffect, useState } from 'react';
import { COLUMN_GAP, EMOJI_MIN_SIZE, REACTION_DEFAULT_SNAP_POINT } from '../MobileReactionSheet';

const LIST_DEFAULT_SIZE = 300;
const TOTAL_HORIZONTAL_SHEET_PADDING = 48;

const useListResizeHandlers = (
    listRef: RefObject<any>,
    heightControllerRef: RefObject<HTMLDivElement>,
    setColumnCount: (count: number) => void,
    setEmojiButtonSize: (size: number) => void,
    sheetIsOpen: boolean,
) => {
    const [listWidth, setListWidth] = useState(LIST_DEFAULT_SIZE);
    const [listHeight, setListHeight] = useState(getSnapPointSheetHeight(REACTION_DEFAULT_SNAP_POINT));

    const changeListHeight = (height: number | undefined) => {
        if (!height) return;

        listRef.current && listRef.current.resetAfterIndex(0);
        setListHeight(height);
    };

    /**
     * Set list height to container height. This is useful for when we want the list to
     * fill the contents area, or resize smaller (e.g. when keyboard is shown)
     */
    const setListHeightToContainerHeight = () => {
        changeListHeight(heightControllerRef.current?.getBoundingClientRect()?.height);
    };

    useEffect(() => {
        const width = window.innerWidth - TOTAL_HORIZONTAL_SHEET_PADDING;
        setListWidth(width);

        // Get row count from emoji min width
        const newColumnCount = Math.floor(width / EMOJI_MIN_SIZE);
        setColumnCount(newColumnCount);

        // Get exact width for emojis so that they fill the space
        const gapSpace = COLUMN_GAP * (newColumnCount - 1);
        const size = (width - gapSpace) / newColumnCount;
        setEmojiButtonSize(size);
    }, []);

    useEffect(() => {
        if (!heightControllerRef.current || !sheetIsOpen) return;

        const resizeObserver = new ResizeObserver(setListHeightToContainerHeight);
        resizeObserver.observe(heightControllerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [sheetIsOpen]);

    return { listWidth, listHeight };
};

export default useListResizeHandlers;
