// Lib
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Allows lifecycle methods to be invoked without using a class component
 */
class LifecycleComponent extends React.Component {
    componentWillUnmount() {
        const { componentWillUnmount } = this.props;
        return componentWillUnmount && componentWillUnmount();
    }

    render() {
        return this.props.children ? React.Children.only(this.props.children) : null;
    }
}

LifecycleComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    componentWillUnmount: PropTypes.func,
};

export default LifecycleComponent;
