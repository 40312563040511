import React from 'react';
import SheetContainer, { Content, Overlay, Sheet } from '../../../../mobile/structural/sheet';
import { UseSheetReturnType } from '../../../../mobile/structural/sheet/hooks/useSheet';

type MobileToolbarSheetProps = UseSheetReturnType & {
    className?: string;
    onCloseTransitionStart?: () => void;
    children: React.ReactNode;
    onCloseTransitionEnd?: () => void;
};

const MobileToolbarSheet = (props: MobileToolbarSheetProps) => {
    const { children, ...rest } = props;

    return (
        <SheetContainer defaultSnapPoint={'0.5max'} {...rest}>
            <Overlay />
            <Sheet>
                <Content>{children}</Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileToolbarSheet;
