// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getReminderOptions } from './taskDueDateReminderUtils';

// Components
import Icon from '../../../../../components/icons/Icon';

// Constants
import { REMINDER_VALUES } from './taskDueDateConstants';

// Styles
import './DueDateReminderSelect.scss';

const REMINDER_TEXT = {
    [REMINDER_VALUES.NONE]: 'No reminder',
    [REMINDER_VALUES.WHEN_DUE]: "When it's due",
    [REMINDER_VALUES.TEN_MINUTES_BEFORE]: '10 minutes before',
    [REMINDER_VALUES.ONE_HOUR_BEFORE]: '1 hour before',
    [REMINDER_VALUES.NINE_AM_DAY_OF]: "9am the day it's due",
    [REMINDER_VALUES.NINE_AM_DAY_BEFORE]: '9am the day before',
};

const DueDateReminderSelect = (props) => {
    const { value, onChange, hasDueDateTime } = props;

    const reminderOptions = getReminderOptions(hasDueDateTime);

    const onSelectChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="DueDateReminderSelect">
            <div className="bell-icon-container">
                <Icon name="reminder-bell" />
            </div>
            <select value={value} onChange={onSelectChange}>
                {reminderOptions.map((reminderOption, i) => (
                    <option key={i} value={reminderOption}>
                        {REMINDER_TEXT[reminderOption]}
                    </option>
                ))}
            </select>
            <div className="arrow-icon-container">
                <Icon name="dropdown-arrow" />
            </div>
        </div>
    );
};

DueDateReminderSelect.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    hasDueDateTime: PropTypes.bool,
};

export default DueDateReminderSelect;
