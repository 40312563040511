import React, { createContext, useContext, ReactNode } from 'react';
import { identity } from 'lodash';

// Utils
import globalLogger from '../../../../logger';
import useProductFetching from './useCheckoutProductFetching';
import useCheckoutNavigationStack from './useCheckoutNavigationStack';

// Components
import CheckoutErrorModal from '../error/CheckoutErrorModal';

// Hooks
import useCheckoutReducer, { CheckoutStoredState } from './useCheckoutReducer';

// Types
import { CheckoutPrice, CheckoutProduct } from '../../../../../common/payment/products/productTypes';
import { MNSubscription } from '../../../../../common/payment/subscriptions/subscriptionTypes';
import { ImMap } from '../../../../../common/utils/immutableHelper';
import { ImMNCurrentUser } from '../../../currentUserSelector';

const logger = globalLogger.createChannel('checkout');

type CheckoutContextProviderProps = {
    children: ReactNode;
    subscription: ImMap<MNSubscription> | undefined;
    currentUser: ImMNCurrentUser;
    location: {
        pathname: string;
    };
    dispatchOpenIntercomMessages: () => void;
    dispatchFetchSubscriptionDetails: () => void;
    dispatchNavigateTo: (url: string) => void;
    dispatchNavigateBrowserBack: () => void;
};

type CheckoutContextArgs = {
    products: CheckoutProduct[];
    selectedPriceId: string | undefined;
    selectedProductId: string | undefined;
    selectedProduct?: CheckoutProduct;
    selectedPrice?: CheckoutPrice;
    addMemberIds: string[];
    removeMemberIds: string[];
    initialMemberIds: string[];
    quantity?: number;
    selectedSeatCount?: number;
    subscription: ImMap<MNSubscription> | undefined;
    currentUser: ImMNCurrentUser;
    resetState: () => void;
    updateState: (state: Partial<CheckoutStoredState>) => void;
    clearNavigationStack: () => void;
    dispatchOpenIntercomMessages: () => void;
    dispatchFetchSubscriptionDetails: () => void;
    dispatchNavigateTo: (url: string) => void;
    dispatchNavigateBack: () => void;
};

// Create a context
const CheckoutContext = createContext<CheckoutContextArgs>({
    selectedProductId: '',
    selectedPriceId: '',
    selectedProduct: undefined,
    selectedPrice: undefined,
    addMemberIds: [],
    removeMemberIds: [],
    initialMemberIds: [],
    products: [],

    resetState: identity,
    updateState: identity,
    clearNavigationStack: identity,

    // stuff from redux
    subscription: undefined,
    currentUser: {} as ImMNCurrentUser, // this will be replaced by the actual user as soon as the context is initialized
    dispatchOpenIntercomMessages: identity,
    dispatchFetchSubscriptionDetails: identity,
    dispatchNavigateTo: identity,
    dispatchNavigateBack: identity,
});

// Create a provider for components to consume and access the state
export const CheckoutContextProvider = ({
    children,
    subscription,
    currentUser,
    location,
    dispatchOpenIntercomMessages,
    dispatchFetchSubscriptionDetails,
    dispatchNavigateTo,
    dispatchNavigateBrowserBack,
}: CheckoutContextProviderProps) => {
    const {
        selectedProductId,
        selectedPriceId,
        quantity,
        selectedSeatCount,
        addMemberIds,
        removeMemberIds,
        initialMemberIds,
        reset,
        update,
    } = useCheckoutReducer();

    const [products, productsFetchError] = useProductFetching(selectedProductId, update);

    const [navigationStack, clearNavigationStack] = useCheckoutNavigationStack(location);

    const dispatchNavigateBack = () => {
        const currentIndex = navigationStack.findIndex((step) => step === location.pathname);
        const previousStep = navigationStack[currentIndex - 1];

        if (previousStep) {
            return dispatchNavigateTo(previousStep);
        }

        dispatchNavigateBrowserBack();
    };

    const selectedProduct = products.find((product) => product._id === selectedProductId);
    const selectedPrice = selectedProduct?.prices.find((price) => price._id === selectedPriceId);

    const contextValue = {
        selectedPriceId,
        selectedProductId,
        addMemberIds,
        removeMemberIds,
        initialMemberIds,
        quantity,
        selectedSeatCount,

        products,
        selectedProduct,
        selectedPrice,
        subscription,
        currentUser,

        resetState: reset,
        updateState: update,

        clearNavigationStack,

        dispatchOpenIntercomMessages,
        dispatchFetchSubscriptionDetails,
        dispatchNavigateTo,
        dispatchNavigateBack,
    };

    logger.debug('CheckoutContext', {
        selectedPriceId,
        selectedProductId,
        products,
    });

    return (
        <CheckoutContext.Provider value={contextValue}>
            {productsFetchError ? <CheckoutErrorModal error={productsFetchError} /> : children}
        </CheckoutContext.Provider>
    );
};

export const useCheckoutContext = () => useContext(CheckoutContext);
