// Lib
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { isGuest } from '../../user/userUtil';
import { prop } from '../../../common/utils/immutableHelper';
import { hasSeen } from '../../../common/users/userEducationUtil';
import { isPlatformNativeMobileApp } from '../utils/platformDetailsUtils';
import { getIsSupportedUserAgent } from '../utils/platformBrowserSupportUtils';
import { getUserEducation } from '../../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentUser, ImMNCurrentUser } from '../../user/currentUserSelector';
import { getPlatformDetailsSelector } from '../platformSelector';

// Components
import PlatformCookieFullScreenWarning from './PlatformCookieFullScreenWarning';
import PlatformUnsupportedFullScreenWarning from './PlatformUnsupportedFullScreenWarning';
import BrowserMobileFullScreenSignupSuccess from './MobileWebFullScreenSignupSuccess';

// Hooks
import useLocalStorage from '../../utils/services/localStorage/useLocalStorage';

// Types
import { MilanoteApplicationMode, PlatformDetails } from '../../../common/platform/platformTypes';
import { ONBOARDING_EDUCATION_CODES } from '../../../common/users/userEducationConstants';

const BROWSER_UNSUPPORTED_ACKNOWLEDGEMENT_KEY = 'unsupportedAcknowledgement';

/**
 * Shows the mobile signup warning if the user is on mobile-web and hasn't completed their onboarding.
 */
const shouldShowMobileSignupWarning = (currentUser: ImMNCurrentUser, platformDetails: PlatformDetails): boolean => {
    if (!currentUser) return false;

    // Only logged in users should see this warning
    if (isGuest(currentUser)) return false;

    // Don't show if we're still fetching the user
    if (!prop('fetched', currentUser)) return false;

    // Only show the warning if the user hasn't completed desktop onboarding
    if (hasSeen(getUserEducation(currentUser), ONBOARDING_EDUCATION_CODES.DESKTOP_ONBOARDING)) return false;

    // Only show the warning if the user is on mobile
    const isMobileAppMode =
        platformDetails.appMode === MilanoteApplicationMode.mobile ||
        platformDetails.appMode === MilanoteApplicationMode.mobileLegacy;
    if (!isMobileAppMode) return false;

    // If the user is currently running within an app, don't show the warning
    if (isPlatformNativeMobileApp(platformDetails)) return false;

    return true;
};

/**
 * Determines whether the browser is supported and also has the features required to run Milanote smoothly.
 */
const shouldShowUnsupportedBrowserWarning = (platformDetails: PlatformDetails): boolean => {
    if (!getIsSupportedUserAgent(platformDetails.userAgent)) return true;
    if (!platformDetails.features.supportsSvg) return true;
    if (!platformDetails.features.supportsContentEditable) return true;
    if (!platformDetails.features.supportsWebSockets) return true;

    return false;
};

/**
 * This component is responsible for checking if the user's platform
 */
const PlatformSupportManager = (): ReactElement | null => {
    const [userHasAcknowledged, setUserHasAcknowledged] = useLocalStorage(
        BROWSER_UNSUPPORTED_ACKNOWLEDGEMENT_KEY,
        false,
    );

    const acknowledgeUnsupportedBrowser = () => setUserHasAcknowledged(true);

    const platformDetails = useSelector(getPlatformDetailsSelector);
    const currentUser = useSelector(getCurrentUser);

    // if user does not have local storage enabled
    if (!platformDetails.features.supportsLocalStorage) return <PlatformCookieFullScreenWarning />;

    // if user is not using a supported browser, or their browser doesn't support required features
    if (shouldShowUnsupportedBrowserWarning(platformDetails) && !userHasAcknowledged) {
        return <PlatformUnsupportedFullScreenWarning acknowledgeUnsupportedBrowser={acknowledgeUnsupportedBrowser} />;
    }

    if (shouldShowMobileSignupWarning(currentUser, platformDetails)) return <BrowserMobileFullScreenSignupSuccess />;

    return null;
};

export default PlatformSupportManager;
