import React from 'react';
import PropTypes from 'prop-types';

import ToolbarTool from '../../../ToolbarTool';

const TextStyleRichTextTool = ({ isOpen, name, disabled }) => (
    <ToolbarTool
        className="TextStyleRichTextTool"
        isOpen={isOpen}
        name={name}
        disabled={disabled}
        preventFocusChange // Prevents unfocusing current text selection when clicking on the tool
    >
        {/* using a div with a .png image here instead of an svg Icon
        because the gradient does not export correctly */}
        <div className="text-style-icon" />
    </ToolbarTool>
);

TextStyleRichTextTool.propTypes = {
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default TextStyleRichTextTool;
