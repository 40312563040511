import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuCameraIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-camera"
        {...props}
    >
        <g fill="#323B4A">
            <path d="M14.5 3.25a.75.75 0 0 1 .576.27l2.276 2.73H20a2.75 2.75 0 0 1 2.745 2.582L22.75 9v9A2.75 2.75 0 0 1 20 20.75H4A2.75 2.75 0 0 1 1.25 18V9A2.75 2.75 0 0 1 4 6.25h2.647l2.277-2.73a.75.75 0 0 1 .466-.262l.11-.008h5zm-.352 1.5H9.851L7.576 7.48a.75.75 0 0 1-.466.262L7 7.75H4c-.69 0-1.25.56-1.25 1.25v9c0 .69.56 1.25 1.25 1.25h16c.69 0 1.25-.56 1.25-1.25V9c0-.69-.56-1.25-1.25-1.25h-3a.75.75 0 0 1-.576-.27l-2.276-2.73z" />
            <path d="M12 9.25a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm0 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuCameraIcon);
export default Memo;
