import React from 'react';
import usePauseEditorFocusWhileComponentRendered from '../../../boardsPage/editing/usePauseEditorFocusWhileComponentRendered';
import TiptapTextStylePopupContent from '../../../../workspace/toolbar/components/selectionTools/editorTools/textStylePopup/TiptapTextStylePopupContent';
import TextStyleRichTextToolPopupContent from '../../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextToolPopup';
import { prop } from '../../../../../common/utils/immutableHelper';
import { MobileTextStyleRichTextToolProps } from './MobileTextStyleRichTextTool';

const MobileTextStyleSheetContent = (props: MobileTextStyleRichTextToolProps) => {
    const isTiptapEditor = usePauseEditorFocusWhileComponentRendered();
    const element = prop(0, props.selectedElements);

    const TextStylePopupContent = isTiptapEditor ? TiptapTextStylePopupContent : TextStyleRichTextToolPopupContent;

    return <TextStylePopupContent {...props} showBlockStyleTools={true} element={element} />;
};

export default MobileTextStyleSheetContent;
