// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { selectIsMobileAppMode } from '../../../../../platform/platformSelector';

// Components
import TaskDueDatePillDesktop from './TaskDueDatePillDesktop';
import TaskDueDatePillMobile from './TaskDueDatePillMobile';

// Types
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

// Styles
import './TaskDueDatePill.scss';

const TaskDueDatePill = (props: { className?: string; element: ImMNElement; isEditable: boolean }) => {
    const isMobileAppMode = useSelector(selectIsMobileAppMode);

    if (isMobileAppMode) return <TaskDueDatePillMobile {...props} />;

    return <TaskDueDatePillDesktop {...props} />;
};

export default TaskDueDatePill;
