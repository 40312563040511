// Utils
import { getElement } from '../../../../../../common/elements/utils/elementTraversalUtils';
import {
    getDueDate,
    getDueReminder,
    getDueReminderTimestamp,
    getHasDueDateTime,
    getShowDueDate,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getDueDatePopupId } from '../assignmentUiUtils';
import { getTimestampForReminder } from './taskDueDateReminderUtils';

// Selectors
import { getElements } from '../../../../selectors/elementsSelector';
import { getCurrentlyEditingElement, getCurrentlyEditingId } from '../../../../selection/currentlyEditingSelector';

// Actions
import { updateElement } from '../../../../actions/elementActions';
import { closePopupsMatching, openPopup } from '../../../../../components/popupPanel/popupActions';

// Constants
import { ELEMENT_UPDATE_TYPE } from '../../../../../../common/elements/elementConstants';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { MilanoteApplicationMode } from '../../../../../../common/platform/platformTypes';
import { getMilanoteApplicationModeSelector } from '../../../../../platform/platformSelector';
import { closeSheet, openSheet } from '../../../../../mobile/structural/sheet/sheetActions';
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import { getSheetKey } from '../../../../../mobile/structural/sheet/hooks/useSheet';

const openDesktopDueDatePopup = (id: string, dispatch: Function) => {
    const popupId = getDueDatePopupId(id);

    dispatch(openPopup(popupId));
    dispatch(
        closePopupsMatching({
            predicateFn: (activePopupId: string) => activePopupId.startsWith('task') && activePopupId !== popupId,
        }),
    );
};

const openMobileDueDateSheet = (elementId: string, dispatch: Function) => {
    const sheetId = SheetId.DueDateTool;
    const sheetKey = getSheetKey(sheetId, elementId);
    dispatch(openSheet(sheetId, sheetKey));
};

export const toggleElementShowDueDatePill =
    ({ id, sync = true }: { id: string; sync?: boolean }) =>
    (dispatch: Function, getState: Function) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingDueDate = getShowDueDate(element);

        const isMobile = getMilanoteApplicationModeSelector(state) === MilanoteApplicationMode.mobile;
        const sheetId = SheetId.DueDateTool;
        const sheetKey = getSheetKey(sheetId, id);

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE,
                id,
                changes: {
                    showDueDate: !isCurrentlyShowingDueDate,
                    dueDate: null,
                    hasDueDateTime: false,
                    dueReminder: null,
                    dueReminderTimestamp: null,
                },
                sync,
            }),
        );

        if (isCurrentlyShowingDueDate && isMobile) {
            dispatch(closeSheet(sheetId, sheetKey));
        }

        if (!isCurrentlyShowingDueDate) {
            const openDueDatePopup = isMobile ? openMobileDueDateSheet : openDesktopDueDatePopup;
            openDueDatePopup(id, dispatch);
        }
    };

export const removeElementDueDate =
    ({ id, showDueDate = false }: { id: string; showDueDate?: boolean }) =>
    (dispatch: Function, getState: Function) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);

        const isCurrentlyShowingDueDate = getShowDueDate(element);

        if (!isCurrentlyShowingDueDate) return;

        dispatch(
            updateElement({
                updateType: ELEMENT_UPDATE_TYPE.TOGGLE_DUE_DATE,
                id,
                changes: {
                    showDueDate,
                    dueDate: null,
                    hasDueDateTime: false,
                    dueReminder: null,
                    dueReminderTimestamp: null,
                },
            }),
        );
    };

type DueDateUpdateDefinition = {
    id: string;
    updateType: string;
    changes: {
        showDueDate: boolean;
        dueDate: Date | number | string;
        hasDueDateTime: boolean;
        dueReminder: number;
        dueReminderTimestamp: number | null;
    };
    undoChanges?: {
        showDueDate: boolean;
        dueDate: Date | number | string;
        hasDueDateTime: boolean;
        dueReminder: number;
        dueReminderTimestamp: number;
    };
    sync?: boolean;
    transactionId?: number;
};

/**
 * This supports setting a temporary due date state (while the due date popup is open) and committing the
 * updated due date state (when the popup closes).
 */
export const setElementDueDate = ({
    taskId,
    dueDate,
    hasDueDateTime,
    dueReminder,
    sync = true,
    initialElement,
}: {
    taskId: string;
    dueDate: Date | number | string;
    hasDueDateTime: boolean;
    dueReminder: number;
    sync?: boolean;
    initialElement?: MNElement;
}) => {
    const dueReminderTimestamp = getTimestampForReminder({ dueDate, dueReminder });

    const updateDefinition: DueDateUpdateDefinition = {
        id: taskId,
        updateType: ELEMENT_UPDATE_TYPE.DUE_DATE,
        changes: {
            showDueDate: true,
            dueDate,
            hasDueDateTime,
            dueReminder,
            dueReminderTimestamp,
        },
        sync,
    };

    if (sync && initialElement) {
        updateDefinition.undoChanges = {
            showDueDate: getShowDueDate(initialElement),
            dueDate: getDueDate(initialElement),
            hasDueDateTime: getHasDueDateTime(initialElement),
            dueReminder: getDueReminder(initialElement),
            dueReminderTimestamp: getDueReminderTimestamp(initialElement),
        };
    } else {
        // If un-synced then we're making temporary updates, so don't save it to the undo-redo stack
        updateDefinition.transactionId = -1;
    }

    return updateElement(updateDefinition);
};

export const openCurrentlyEditingDueDatePopup = () => (dispatch: Function, getState: Function) => {
    const state = getState();
    const currentlyEditingId = getCurrentlyEditingId(state);
    const currentElement = getCurrentlyEditingElement(state);

    const isShowingDueDate = getShowDueDate(currentElement);

    const isMobile = getMilanoteApplicationModeSelector(state) === MilanoteApplicationMode.mobile;
    const openDueDatePopup = isMobile ? openMobileDueDateSheet : openDesktopDueDatePopup;

    return isShowingDueDate
        ? openDueDatePopup(currentlyEditingId, dispatch)
        : // Don't sync this change, otherwise it might conflict with text updates in the editor
          // This could mean that the "showAssignments" property won't be saved on the server if the
          // user doesn't select anything, but this is an edge case with unimportant consequences
          dispatch(toggleElementShowDueDatePill({ id: currentlyEditingId, sync: false }));
};
