// Lib
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// Selector
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Components
import TooltipSource from '../../../components/tooltips/TooltipSource';

// Constants
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Types
import { MilanoteApplicationMode } from '../../../../common/platform/platformTypes';

// Styles
import './Tool.scss';

type ToolProps = {
    children: React.ReactNode;
    name?: string;
    iconName?: string;
    isDraggable?: boolean;
    isDragging?: boolean;
    isActive?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    className?: string;
    id?: string;
    tooltip?: string;
    disabled?: boolean;
    tooltipEnabled?: boolean;
    mobileAllowPointerDown?: boolean;
    tooltipText?: string;
    shortcut?: string[];
    preventFocusChange?: boolean;
};

const Tool = (props: ToolProps) => {
    const platformDetails = useSelector(getPlatformDetailsSelector);
    const isMobile = platformDetails.appMode === MilanoteApplicationMode.mobile;
    const supportsMouse = platformDetails.features.supportsMouse;

    const tooltipPosition = isMobile ? TooltipPositions.TOP : TooltipPositions.RIGHT;

    const classes = {
        dragging: props.isDragging,
        active: props.isActive,
        draggable: props.isDraggable,
        hoverable: !isMobile && supportsMouse,
        disabled: props.disabled,
    };

    const toolNameElement = props.name ? <span className="tool-name">{props.name}</span> : null;

    return (
        <div
            id={props.id}
            className={classNames('Tool', props.className, classes)}
            onClick={props.onClick}
            onPointerDown={(event) => props.preventFocusChange && event.preventDefault()}
        >
            <div className="contents">
                <TooltipSource
                    enabled={props.tooltipEnabled}
                    tooltipText={props.tooltipText}
                    shortcut={props.shortcut}
                    position={tooltipPosition}
                    delay={700}
                    pollPosition
                    triggerOnMouseEnter
                >
                    <div className="icon">
                        {props.isDraggable && <div className="draggable-shadow">{props.children}</div>}
                        {props.children}
                    </div>
                </TooltipSource>
                {toolNameElement}
            </div>
        </div>
    );
};

export default Tool;
