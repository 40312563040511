import { useEffect, useRef } from 'react';

/**
 * Calls a function on each animation frame, with the delta time since the last frame.
 */
const useAnimationFrameLoop = (
    callback: (delta: number) => void,
): {
    start: () => void;
    stop: () => void;
} => {
    const requestRef = useRef<number>();
    const lastTimeRef = useRef<number>(performance.now());
    const callbackRef = useRef<(delta: number) => void>(callback);
    const animationRunningRef = useRef<boolean>(false);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const animate = (time: number) => {
        if (!animationRunningRef.current) return;

        const delta = (time - lastTimeRef.current) / 1000;
        lastTimeRef.current = time;

        // only call the callback if the delta is positive
        // this can occur on the first frame, when the animation starts, which can cause a jump to the end state
        if (delta > 0) {
            callbackRef.current?.(delta);
        }

        requestRef.current = requestAnimationFrame(animate);
    };

    const start = () => {
        if (requestRef.current) cancelAnimationFrame(requestRef.current);
        animationRunningRef.current = true;

        lastTimeRef.current = performance.now();
        requestRef.current = requestAnimationFrame(animate);
    };

    const stop = () => {
        if (requestRef.current) cancelAnimationFrame(requestRef.current);
        animationRunningRef.current = false;
    };

    return { start, stop };
};

export default useAnimationFrameLoop;
