export enum IconUiCategory {
    DEFAULT = '___default___',
    LETTERS = '___letters___',
    IMAGES = '___images___',
}

export type IconCategoryData = {
    slug: string;
    title: string;
};
