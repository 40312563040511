import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ContextMenuTaskListIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        className="Icon context-menu-task-list"
        {...props}
    >
        <g fill="#323B4A" fillRule="evenodd">
            <path d="M20.5 4a2.25 2.25 0 0 1 2.25 2.25v11.5A2.25 2.25 0 0 1 20.5 20h-17a2.25 2.25 0 0 1-2.25-2.25V6.25A2.25 2.25 0 0 1 3.5 4h17zm0 1.5h-17a.75.75 0 0 0-.75.75v11.5c0 .414.336.75.75.75h17a.75.75 0 0 0 .75-.75V6.25a.75.75 0 0 0-.75-.75z" />
            <path d="M15.448 8.637a.75.75 0 1 1 1.19.915l-5.044 5.905a.75.75 0 0 1-1.124.073l-2.72-2.72c-.293-.292-.247-.813 0-1.06.247-.247.768-.293 1.06 0l2.116 2.115 4.522-5.228z" />
        </g>
    </svg>
);
const Memo = memo(ContextMenuTaskListIcon);
export default Memo;
