// Lib
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getElementId } from '../../../../../../../common/elements/utils/elementPropertyUtils';

// Selector
import { selectMobileIconModes } from './mobileIconListSelector';

// Actions
import { setIconPopupMode } from '../popup/iconsPopupActions';

// Components
import SheetContainer, {
    CloseButton,
    Content,
    Header,
    Heading,
    Overlay,
    Sheet,
} from '../../../../../../mobile/structural/sheet';
import MobileIconModeList from './MobileIconModeList';
import SheetSearchBar from '../../../../../../mobile/structural/sheet/searchBar/SheetSearchBar';

// Types
import { UseSheetReturnType } from '../../../../../../mobile/structural/sheet/hooks/useSheet';
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

// Styles
import './MobileIconSheet.scss';

export type MobileIconSheetProps = {
    sheetProps: UseSheetReturnType;
    selectedElements: Immutable.List<ImMNElement>;
};

// Structured as a separate component to ensure that mount only occurs when the sheet is open
const MobileIconSheetContent = (props: MobileIconSheetProps): ReactElement => {
    const { selectedElements, sheetProps } = props;

    const [query, setQuery] = useState('');

    const dispatch = useDispatch();
    const dispatchSubmitSearch = useCallback(() => {
        if (!query) return;

        dispatch(setIconPopupMode({ mode: query, id: getElementId(selectedElements.first()) }));

        requestAnimationFrame(() => {
            const sheetContentEl = document.querySelector('.MobileIconSheet .Content');

            if (!sheetContentEl) return;

            sheetContentEl.scroll({ top: 0, left: 0, behavior: 'smooth' });
        });
    }, [query, selectedElements]);

    // @ts-ignore iconPopupSelector isn't TS so struggles with the types
    const modeList = useSelector((state) => selectMobileIconModes(state, { selectedElements }));

    return (
        <>
            <SheetSearchBar
                searchPlaceholder="Search icons..."
                searchFilter={query}
                setSearchFilter={setQuery}
                sheetContentRef={sheetProps.sheetContentRef}
                enterKeyHint="search"
                onSubmit={dispatchSubmitSearch}
            />
            <Content>
                {modeList.map((iconMode) => (
                    <MobileIconModeList
                        key={iconMode.slug}
                        slug={iconMode.slug}
                        title={iconMode.title}
                        selectedElements={selectedElements}
                    />
                ))}
            </Content>
        </>
    );
};

const MobileIconSheet = (props: MobileIconSheetProps): ReactElement => {
    const { sheetProps, selectedElements } = props;

    return (
        <SheetContainer className="MobileIconSheet" {...sheetProps} defaultSnapPoint={0.5} snapPoints={[0.5, 0.9]}>
            <Overlay />
            <Sheet>
                <Header>
                    <Heading>Icon</Heading>
                    <CloseButton />
                </Header>
                <MobileIconSheetContent selectedElements={selectedElements} sheetProps={sheetProps} />
            </Sheet>
        </SheetContainer>
    );
};

export default MobileIconSheet;
