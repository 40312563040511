// Lib
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

// Utils
import { scrollToBottom } from './scrollableParentUtils';

/**
 * This hook ensures that the scrollable area remains scrolled to the bottom if the height of the
 * scrollable area or child changes and the scrollable area was scrolled to the bottom prior to
 * the height change.
 */
const useKeepScrolledToBottom = (scrollableElementRef, childElementRef, isDragging) => {
    // The "scrollTop" value that means we're currently scrolled to the bottom
    const bottomScrollTop = React.useRef();

    const onResize = React.useCallback((...args) => {
        if (!scrollableElementRef.current) return;

        const availableScrollTop =
            scrollableElementRef.current.scrollHeight - scrollableElementRef.current.clientHeight;

        const isScrolledToBottom =
            scrollableElementRef.current.scrollTop >= bottomScrollTop.current ||
            scrollableElementRef.current.scrollTop === availableScrollTop;

        // Don't scroll if the user has scrolled to a different point themselves
        if (!isScrolledToBottom) return;

        // First ensure that the element is currently scrolled to bottom
        scrollToBottom(scrollableElementRef.current);

        requestAnimationFrame(() => {
            if (!scrollableElementRef.current) return;

            // Update the scrollTop value that keeps us scrolled to the bottom
            bottomScrollTop.current = scrollableElementRef.current.scrollTop;
        });
    }, []);

    React.useEffect(() => {
        if (!scrollableElementRef.current) return;

        // Start the element scrolled to bottom
        scrollToBottom(scrollableElementRef.current);

        // Keep a reference to the current scrollTop when the scrollable element is at the bottom
        // So we can check it on resize and make sure we're still at the bottom
        bottomScrollTop.current = scrollableElementRef.current.scrollTop;

        const resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(scrollableElementRef.current);
        childElementRef.current && resizeObserver.observe(childElementRef.current);

        return () => {
            resizeObserver?.disconnect();
        };
    }, []);

    // Scroll to the bottom when starting a drag
    React.useEffect(() => {
        if (!isDragging || !scrollableElementRef.current) return;

        scrollToBottom(scrollableElementRef.current);
    }, [isDragging]);
};

export default useKeepScrolledToBottom;
