import React, {
    ChangeEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
    MouseEventHandler,
    useEffect,
    useRef,
    FC,
    RefObject,
    useCallback,
} from 'react';

// Utils
import { getFetching } from '../attachments/attachmentsSelector';
import { prop } from '../../../common/utils/immutableHelper';
import { getElementId, getLinkType } from '../../../common/elements/utils/elementPropertyUtils';
import { getLocalError, getLocalShouldLoadPreview, getLocalUrl } from '../local/elementLocalDataUtils';

// Constants & types
import { LINK_ELEMENT_LINK_TYPES } from '../../../common/links/linkConstants';
import { ElementType } from '../../../common/elements/elementTypes';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { LinkEditorProps } from './LinkEditor';

// Styles
import './LinkInput.scss';

const getLinkInputPlaceholder = (element: ImMNElement): string => {
    switch (getLinkType(element)) {
        case LINK_ELEMENT_LINK_TYPES.VIDEO:
            return 'Paste a link from YouTube or Vimeo';
        case LINK_ELEMENT_LINK_TYPES.AUDIO:
            return 'Paste a link from Spotify or Soundcloud';
        case LINK_ELEMENT_LINK_TYPES.MAP:
            return 'Paste a link from Google Maps';
        default:
            return 'Enter a link URL';
    }
};

type LinkTextInputProps = {
    element: ImMNElement;
    setLocalData: (data: any) => void;
    stopEditing: () => void;
    onEmptyDelete?: () => void;
    onClick: MouseEventHandler;
    url: string;
    linkInputRef: RefObject<HTMLInputElement>;
    isEditing: boolean;
    isFetching: boolean;
    isHidden: boolean;
};

const LinkTextInput: FC<LinkTextInputProps> = ({
    element,
    setLocalData,
    stopEditing,
    onEmptyDelete,
    onClick,
    url,
    linkInputRef,
    isEditing,
    isFetching,
    isHidden,
}) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            setLocalData({ url: event.target.value || '' });
        },
        [setLocalData],
    );

    const handleKey: KeyboardEventHandler = useCallback(
        (event) => {
            event.stopPropagation();
            if (event.key === 'Escape') {
                stopEditing();
                return;
            }

            const url = (event.target as HTMLInputElement).value || '';
            if (!url && ['Backspace', 'Delete'].includes(event.key)) {
                event.preventDefault();
                onEmptyDelete?.();
                return;
            }
        },
        [stopEditing, onEmptyDelete],
    );

    // we want the input to remain mounted & active while an error message is shown
    // so that we don't have to deal with race conditions when trying to focus it
    const hiddenStyle = isHidden ? { opacity: 0 } : undefined;

    return (
        <div className="input-wrapper" style={hiddenStyle} onClick={onClick}>
            <input
                ref={linkInputRef}
                value={url || ''}
                disabled={!isEditing || isFetching}
                placeholder={getLinkInputPlaceholder(element)}
                onChange={handleChange}
                onKeyDown={handleKey}
                type="url"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                enterKeyHint="done"
            />
        </div>
    );
};

type LinkErrorProps = {
    message: string;
    onClearError: () => void;
    duration?: number;
    onClick: MouseEventHandler;
};

const LinkError: FC<LinkErrorProps> = ({ message, onClick, onClearError, duration = 2000 }) => {
    useEffect(() => {
        const timeout = setTimeout(onClearError, duration);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div onClick={onClick} className="error-message">
            {message || 'URL not found'}
        </div>
    );
};

const LinkInput: FC<LinkEditorProps> = ({
    element,
    elementLocalData,
    attachment,
    isSelected,
    isEditing,
    shouldFocusOnlyWhenSelected,
    isSingleSelected,
    setLinkUrl,
    setLocalData,
    startEditing,
    stopEditing,
    moveElementsToTrash,
    deselectAll,
}) => {
    const linkInputRef = useRef<HTMLInputElement>(null);
    const focus = useCallback(() => {
        linkInputRef.current?.focus();
    }, []);

    const clearError = useCallback(() => {
        const url = getLocalUrl(elementLocalData);
        setLocalData({ error: null, url });
    }, [elementLocalData, setLocalData]);

    const startEditingElement = useCallback(() => {
        startEditing?.({
            editorId: `${getElementId(element)}-${ElementType.LINK_TYPE}`,
            editorKey: ElementType.LINK_TYPE,
            inputType: 'url',
        });
    }, [element, startEditing]);

    const onClick = useCallback(() => {
        if (!shouldFocusOnlyWhenSelected || (shouldFocusOnlyWhenSelected && isSingleSelected)) {
            startEditingElement();
        }
    }, [shouldFocusOnlyWhenSelected, isSingleSelected, startEditingElement]);

    const handleMouseDown: MouseEventHandler = useCallback(
        (event) => {
            if (!isEditing) return;
            event.stopPropagation();
        },
        [isEditing],
    );

    const loadPreview = useCallback(() => {
        const url = getLocalUrl(elementLocalData);
        const validationResponse = setLinkUrl(url);

        if (!validationResponse) return; // success

        // otherwise show the error
        setLocalData({ error: validationResponse, url, shouldLoadPreview: false });
    }, [elementLocalData, setLinkUrl, setLocalData]);

    // Clear the error and focus on the input whenever editing starts
    useEffect(() => {
        if (!isEditing) return;
        clearError();
        focus();
    }, [isEditing]);

    // load the preview as soon as the element loses editing or selected status,
    // or whenever shouldLoadPreview goes true
    const shouldLoadPreview = getLocalShouldLoadPreview(elementLocalData);
    useEffect(() => {
        if (!shouldLoadPreview) {
            if (isSelected || isEditing) return;
        }

        linkInputRef.current?.blur();
        const url = getLocalUrl(elementLocalData);
        if (url) loadPreview();
    }, [shouldLoadPreview, isSelected, isEditing]);

    const handleSubmit: FormEventHandler = useCallback(
        (event) => {
            event.preventDefault();
            loadPreview();
            deselectAll?.();
        },
        [loadPreview, deselectAll],
    );

    const url = getLocalUrl(elementLocalData);
    const error = getLocalError(elementLocalData);
    const isFetching = getFetching(attachment);

    return (
        <div className="LinkInput" tabIndex={-1} onMouseDown={handleMouseDown}>
            <form className="link-form" onSubmit={handleSubmit}>
                {error && <LinkError onClick={onClick} message={prop('message', error)} onClearError={clearError} />}
                <LinkTextInput
                    isHidden={!!error}
                    element={element}
                    setLocalData={setLocalData}
                    stopEditing={stopEditing}
                    onEmptyDelete={moveElementsToTrash}
                    onClick={onClick}
                    url={url}
                    linkInputRef={linkInputRef}
                    isEditing={isEditing}
                    isFetching={isFetching}
                />
            </form>
        </div>
    );
};

export default LinkInput;
