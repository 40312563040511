// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Actions
import { removeTaskAssignments } from './taskAssignmentActions';

// Components
import TaskPill from './DesktopTaskPill';
import TaskAssignmentPopup from './TaskAssignmentPopup';

// Styles
import './TaskAssignmentPill.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchRemoveAssignments: ({ elementId }) => dispatch(removeTaskAssignments({ id: elementId })),
});

const TaskAssignmentPillLayout = ({
    children,
    className,
    popupId,
    elementId,
    dispatchRemoveAssignments,
    populated,
    isEditable,
}) => (
    <TaskPill
        className={classNames('TaskAssignmentPill', className)}
        popupId={popupId}
        elementId={elementId}
        onDelete={dispatchRemoveAssignments}
        populated={populated}
        isEditable={isEditable}
        popup={<TaskAssignmentPopup popupId={popupId} elementId={elementId} />}
    >
        {children}
    </TaskPill>
);

TaskAssignmentPillLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    popupId: PropTypes.string.isRequired,
    elementId: PropTypes.string.isRequired,
    dispatchRemoveAssignments: PropTypes.func,
    populated: PropTypes.bool,
    isEditable: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(TaskAssignmentPillLayout);
