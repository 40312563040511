// Lib
import React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { getCreatedTime, getPlatform } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getLastCheckedQuickNotes } from '../../user/currentUserSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Components
import { poiBoardSectionContextConsumer } from '../../components/pointsOfInterest/PoiBoardSectionContext';
import { SimpleActivityIndicator } from './ActivityIndicator';

// Constants
import { PLATFORMS } from '../../../common/users/userConstants';
import { WORKSPACE_SECTIONS } from '../../workspace/workspaceConstants';
import { BoardSections } from '../../../common/boards/boardConstants';
import { ImMNElement } from '../../../common/elements/elementModelTypes';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

type Props = {
    element: ImMNElement;
    boardSection: BoardSections;
};

const QuickNotesActivityIndicator = (props: Props) => {
    const { element, boardSection } = props;

    const platformDetails = useSelector(getPlatformDetailsSelector);
    const lastCheckedQuickNotes = useSelector(getLastCheckedQuickNotes);

    if (platformDetails.appMode === MilanoteApplicationMode.mobile) return null;

    if (boardSection !== WORKSPACE_SECTIONS.QUICK_NOTES) return null;

    const platform = getPlatform(element);
    if (platform !== PLATFORMS.IPHONE && platform !== PLATFORMS.ANDROID) return null;

    const creationTime = getCreatedTime(element);
    if (creationTime < lastCheckedQuickNotes) return null;

    return <SimpleActivityIndicator />;
};

export default poiBoardSectionContextConsumer(QuickNotesActivityIndicator);
