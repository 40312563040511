// Utils
import { addMargins } from '../../../common/maths/geometry/rect';
import { getScrollForTarget, ViewportOptions } from '../dom/scrollableParentUtils';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';

type AnimateScrollArgs = {
    scrollableParent: HTMLElement;
    fromScrollLeft: number;
    toScrollLeft: number;
    fromScrollTop: number;
    toScrollTop: number;
    interpolationFactor: number;
};

/**
 * Animates a scroll from one position to another using an interpolation type animation (like an ease-out).
 */
export const animateScroll = ({
    scrollableParent,
    fromScrollLeft,
    toScrollLeft,
    fromScrollTop,
    toScrollTop,
    interpolationFactor,

    onAnimationEnd = () => {},
}: AnimateScrollArgs & { onAnimationEnd?: () => void }) => {
    let animationId: number | null = null;
    const cancel = () => {
        if (!animationId) return;

        scrollableParent.classList.remove('is-scrolling');
        return cancelAnimationFrame(animationId);
    };

    scrollableParent.classList.add('is-scrolling');

    const step = (originX: number, destX: number, originY: number, destY: number) => {
        const distX = destX - originX;
        const distY = destY - originY;

        // Terminal case, when both distances are less than a pixel we've reached our last animation frame
        if (Math.abs(distX) < 1 && Math.abs(distY) < 1) {
            scrollableParent.scrollLeft = destX;
            scrollableParent.scrollTop = destY;

            scrollableParent.classList.remove('is-scrolling');
            onAnimationEnd();

            return;
        }

        // This will perform a scroll over a duration that is relative to the distance it needs to go.
        // This is better than a constant duration otherwise small distances would move very slowly
        const deltaX = interpolationFactor * distX;
        const deltaY = interpolationFactor * distY;

        const nextX = originX + deltaX;
        const nextY = originY + deltaY;

        // Safari/Chrome do not support decimal scroll values, and by default will round down the values.
        // So we want round them to the nearest pixel to make the animation smoother.
        scrollableParent.scrollTop = Math.round(nextY);
        scrollableParent.scrollLeft = Math.round(nextX);

        animationId = requestAnimationFrame(() => step(nextX, destX, nextY, destY));
    };

    animationId = requestAnimationFrame(() => step(fromScrollLeft, toScrollLeft, fromScrollTop, toScrollTop));

    return cancel;
};

export const animateScrollPromise = ({
    scrollableParent,
    fromScrollLeft,
    toScrollLeft,
    fromScrollTop,
    toScrollTop,
    interpolationFactor,
}: AnimateScrollArgs) =>
    new Promise<void>((resolve) => {
        animateScroll({
            scrollableParent,
            fromScrollLeft,
            toScrollLeft,
            fromScrollTop,
            toScrollTop,
            interpolationFactor,
            onAnimationEnd: resolve,
        });
    });

/**
 * Scrolls a target rectangle into view using an interpolation type animation (like an ease-out).
 * The interpolation factor can be provided to speed up or slow down the scroll animation.
 */
const animateScrollIntoView = ({
    scrollableParent,
    targetRect,
    margins = {},
    viewportOptions = {},
    interpolationFactor = 0.3,
}: {
    scrollableParent: HTMLElement;
    targetRect: Rect;
    margins?: { top?: number; right?: number; bottom?: number; left?: number };
    viewportOptions?: ViewportOptions;
    interpolationFactor?: number;
}) => {
    margins.top = margins.top || 0;
    margins.right = margins.right || 0;
    margins.bottom = margins.bottom || 0;
    margins.left = margins.left || 0;

    const targetRectWithMargin = addMargins(margins, targetRect);

    // Most important to get top left into view
    const scrollTarget = getScrollForTarget({
        scrollableParent,
        targetRect: targetRectWithMargin,
        viewportOptions,
    });

    if (!scrollTarget) return;

    return animateScroll({
        scrollableParent,
        // Starting point
        fromScrollLeft: scrollableParent.scrollLeft,
        fromScrollTop: scrollableParent.scrollTop,
        toScrollLeft: scrollTarget.scrollLeft,
        toScrollTop: scrollTarget.scrollTop,
        interpolationFactor,
    });
};

export default animateScrollIntoView;
