import { useRef, TouchEvent, useCallback, MutableRefObject } from 'react';
import { ScatterDataPoint } from 'chart.js';
import { getNewActiveSnapPoint, getSnapPointTopValue } from '../utils/snapPointUtils';
import {
    getNewSheetHeight,
    getSnapPointSheetHeight,
    setContentHeightToMax,
    setContentHeightToVisibleSpace,
    setSheetHeight,
} from '../utils/sheetHeightUtils';
import { isMoveScroll } from '../utils/sheetScrollUtils';
import {
    animateSheet,
    getAnimationStartValues,
    INITIAL_ANIMATION_STATE,
    saveGraphData,
} from '../utils/animateSheetSnap';
import useAnimationFrameLoop from '../../../../utils/react/useAnimationFrameLoop';
import plotSheetAnimationVelocity from '../../../../debug/sheetAnimations/plotSheetAnimationVelocity';
import { SheetProps } from '../SheetContainer';
import { isDebugEnabledSelector, isDebugToolbarEnabledSelector } from '../../../../debug/debugStateSelector';
import { useSelector } from 'react-redux';

const DRAGGING_CLASS = 'dragging';
const PREVENT_OVERSCROLL_CLASS = 'prevent-overscroll';
const SHEET_COVERS_SCREEN_CLASS = 'sheet-covers-screen';
const IGNORE_DRAGS_ATTRIBUTE = 'data-sheet-ignore-drags';

const INITIAL_DRAG_STATE = {
    isDragging: false,
    isScrolling: false,
    isDragCancelled: false,
    hasDoneFirstTouchMove: false,
    sheetTouchOffset: 0,
    currentY: 0,
    velocity: 0,
    touchStartTimestamp: 0,
    prevMoveTimestamp: 0,
    velocities: [],
    latestSheetHeight: 0,
};

export type DragState = {
    isDragging: boolean;
    isScrolling: boolean;
    isDragCancelled: boolean;
    hasDoneFirstTouchMove: boolean;
    sheetTouchOffset: number;
    currentY: number;
    velocity: number;
    touchStartTimestamp: number;
    prevMoveTimestamp: number;
    velocities: number[];
    latestSheetHeight: number;
    graphData?: {
        speedDataDragging: ScatterDataPoint[];
        speedDataNotDragging: ScatterDataPoint[];
    };
};

export type SheetHandlers = {
    handleSheetTouchStart: (event: TouchEvent) => void;
    handleSheetTouchMove: (event: TouchEvent) => void;
    handleSheetTouchEnd: () => void;
    handleSheetTouchCancel: () => void;
};

/**
 * Handle the touch events on the sheet.
 */
const useSheetHandlers = (
    props: SheetProps,
    sheetInitialised: boolean,
    snapPointsState: number[],
    goToSnapPoint: (snapPoint: number) => void,
    cancelInProgressDragAnimation: MutableRefObject<(() => void) | null>,
    setIsSheetMounted: (isMounted: boolean) => void,
): SheetHandlers => {
    const {
        activeSnapPoint,
        sheetRef,
        sheetContentRef,
        dispatchUpdateActiveSnapPoint,
        onCloseTransitionStart,
        onCloseTransitionEnd,
        dispatchCloseSheet,
        onDragStart,
        onDragEnd,
    } = props;

    const dragState = useRef<DragState>(INITIAL_DRAG_STATE);
    const highestSnapPoint = Math.max(...snapPointsState);
    const highestSnapPosition = getSnapPointTopValue(highestSnapPoint);

    const isDebugEnabled = useSelector(isDebugEnabledSelector);
    const isDebugToolbarEnabled = useSelector(isDebugToolbarEnabledSelector);
    const debugToolbarEnabled = isDebugEnabled && isDebugToolbarEnabled;

    const saveDebugGraphData = debugToolbarEnabled && saveGraphData(dragState);

    const animationState = useRef(INITIAL_ANIMATION_STATE);
    const animationController = useAnimationFrameLoop(animateSheet(sheetRef, animationState, saveDebugGraphData));

    /**
     * Handle the touch start event on the sheet content.
     * Get all the starting measurements and set up the timeout to allow
     * the scroll handler to run before we start the drag
     * @param event
     */
    const handleSheetTouchStart = (event: TouchEvent) => {
        if (!sheetRef.current) return;

        document.querySelector('html')?.classList.add(PREVENT_OVERSCROLL_CLASS);

        const ignoreDrag = (event.target as HTMLElement).closest(`[${IGNORE_DRAGS_ATTRIBUTE}]`) !== null;

        const startY = event.touches[0].clientY;

        dragState.current = {
            ...INITIAL_DRAG_STATE,
            sheetTouchOffset: startY - sheetRef.current.getBoundingClientRect().top,
            currentY: startY,
            touchStartTimestamp: performance.now(),
            prevMoveTimestamp: performance.now(),
            isDragCancelled: ignoreDrag,
            velocities: [],
            latestSheetHeight: 0,
            ...(debugToolbarEnabled && { graphData: { speedDataDragging: [], speedDataNotDragging: [] } }),
        };
    };

    /**
     * Handle the touch move event on the sheet content.
     * Check if drag is accepted and if so adjust the sheet height.
     * @param event
     */
    const handleSheetTouchMove = useCallback(
        (event: TouchEvent) => {
            const { prevMoveTimestamp, currentY, hasDoneFirstTouchMove, isDragging, isDragCancelled } =
                dragState.current;

            if (isDragCancelled) return;

            const newY = event.touches[0].clientY;
            const timeNow = performance.now();
            const timeSinceLastMove = timeNow - prevMoveTimestamp;
            if (timeSinceLastMove === 0) return;

            dragState.current.velocity = (currentY - newY) / timeSinceLastMove;
            dragState.current.prevMoveTimestamp = performance.now();
            dragState.current.currentY = newY;
            dragState.current.velocities.push(dragState.current.velocity);
            if (saveDebugGraphData) saveDebugGraphData(dragState.current.velocity);

            if (!hasDoneFirstTouchMove) {
                dragState.current.hasDoneFirstTouchMove = true;
                dragState.current.isScrolling = isMoveScroll(event, dragState, sheetRef);
            }

            const shouldBeginDrag = !isDragging && !dragState.current.isScrolling && sheetInitialised;
            if (shouldBeginDrag) {
                dragState.current.isDragging = true;
                sheetRef.current?.classList.add(DRAGGING_CLASS);
                sheetRef.current?.classList.remove(SHEET_COVERS_SCREEN_CLASS);
                setContentHeightToMax(sheetContentRef);
                if (onDragStart) onDragStart();
            }

            if (!dragState.current.isDragging) return;

            const newSheetHeight = getNewSheetHeight(dragState, highestSnapPosition);
            if (newSheetHeight === undefined) return;

            dragState.current.latestSheetHeight = newSheetHeight;
            setSheetHeight(sheetRef, newSheetHeight);
        },
        [sheetInitialised],
    );

    /**
     * Handle the touch end event on the sheet content.
     * If the sheet is being dragged, go to the new snap point.
     */
    const handleSheetTouchEnd = useCallback(() => {
        document.querySelector('html')?.classList.remove(PREVENT_OVERSCROLL_CLASS);

        if (!dragState.current.isDragging) return;

        dragState.current.isDragging = false;

        const newActiveSnapPoint = getNewActiveSnapPoint(dragState.current, snapPointsState);
        const newHeight = getSnapPointSheetHeight(newActiveSnapPoint);

        const sheetWillClose = newActiveSnapPoint === 0;

        if (sheetWillClose) {
            onCloseTransitionStart?.();
        }

        sheetRef.current?.classList.toggle(SHEET_COVERS_SCREEN_CLASS, newHeight === window.innerHeight);

        const completeAnimation = () => {
            animationController.stop();
            dispatchUpdateActiveSnapPoint(newActiveSnapPoint);
            cancelInProgressDragAnimation.current = null;

            setContentHeightToVisibleSpace(sheetContentRef);

            sheetRef.current?.classList.remove(DRAGGING_CLASS);

            if (onDragEnd) onDragEnd();

            if (debugToolbarEnabled) plotSheetAnimationVelocity(dragState);

            if (sheetWillClose) {
                dispatchCloseSheet();
                setIsSheetMounted(false);
                onCloseTransitionEnd?.();
            }
        };

        cancelInProgressDragAnimation.current = completeAnimation;
        animationState.current = getAnimationStartValues(sheetRef, dragState, newActiveSnapPoint, completeAnimation);
        animationController.start();
    }, [snapPointsState]);

    /**
     * Handle the touch cancel event on the sheet content.
     * Remove the classes and go to the previous snap point.
     */
    const handleSheetTouchCancel = useCallback(() => {
        sheetRef.current?.classList.remove(DRAGGING_CLASS);
        document.querySelector('html')?.classList.remove(PREVENT_OVERSCROLL_CLASS);

        // Reset to original height
        goToSnapPoint(activeSnapPoint);
    }, [activeSnapPoint]);

    return {
        handleSheetTouchStart,
        handleSheetTouchMove,
        handleSheetTouchEnd,
        handleSheetTouchCancel,
    };
};

export default useSheetHandlers;
