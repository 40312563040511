// Utils
import { Point } from '../../../../common/maths/geometry/pointTypes';
import platformSingleton from '../../../platform/platformSingleton';
import { getViewportOffset } from '../../../utils/domUtil';

const FAKE_INPUT_ELEMENT_ID = 'fake-element-create-input';
// Adding the class 'mousetrap' to this will mean that keyboard shortcuts WON'T be ignored while the input has focus
const FAKE_INPUT_ELEMENT_CLASS = `${FAKE_INPUT_ELEMENT_ID} mousetrap`;

type FocusFakeInputOptions = {
    allowAutocomplete?: boolean;
    readOnly?: boolean;
    inputType?: string;
};

const DEFAULT_OPTIONS: FocusFakeInputOptions = {
    allowAutocomplete: true,
    readOnly: false,
    inputType: 'text',
};

const getFakeInput = (): HTMLInputElement => {
    // Get the fake input using a selector if it exists
    const input = document.getElementById(FAKE_INPUT_ELEMENT_ID) as HTMLInputElement;
    if (input) return input;

    // If it doesn't exist, create it
    const newInput = document.createElement('input');
    newInput.setAttribute('id', FAKE_INPUT_ELEMENT_ID);
    newInput.setAttribute('class', FAKE_INPUT_ELEMENT_CLASS);
    newInput.setAttribute('type', 'text');
    return newInput;
};

/**
 * Based on the page position coordinates, this function determines the appropriate place to position
 * the fake input field, considering the container element it will be placed inside of.
 */
const getFakeInputContainerOffset = (pagePosition: Point, container: Element) => {
    const containerRect = container.getBoundingClientRect();

    const viewportOffset = getViewportOffset();

    const x = pagePosition.x + container.scrollLeft - containerRect.left - viewportOffset.x;
    const y = pagePosition.y + container.scrollTop - containerRect.top - viewportOffset.y;

    return {
        x,
        y,
    };
};

/**
 * This function improves the behaviour of editing on certain devices, such as iPads,
 * by immediately focusing a "fake input", and then allowing focus to move to the new element's
 * input/content editable.
 * It seems that these devices don't allow asynchronous focus shifting to places far from where
 * the cursor currently is, for some reason. So this will shift the cursor to the place where
 * the element is dropped, and then when the new element is created it will take the cursor back
 * itself.
 *
 */
export const focusFakeInput = (
    pagePosition: Point,
    containerDomNode: Element,
    options: FocusFakeInputOptions = DEFAULT_OPTIONS,
) => {
    if (!platformSingleton.features.isTouch) return;

    if (!containerDomNode || !pagePosition) return;

    const newInputPosition = getFakeInputContainerOffset(pagePosition, containerDomNode);

    // Get the fake input
    const input = getFakeInput();

    // If it's not a child of the container, move it to the container so that scrolling will work correctly
    if (input.parentElement !== containerDomNode) {
        containerDomNode.appendChild(input);
    }

    // This is used to ensure the container gets scrolled correctly
    input.style.left = `${newInputPosition.x}px`;
    input.style.top = `${newInputPosition.y}px`;

    // HACK - As stated above, we need to focus the input on the first mouseup of the double tap
    //  in order to show the virtual keyboard on the second mouseup (and subsequent element creation).
    //  However, to ensure that the virtual keyboard doesn't show on the first mouseup we set the
    //  input field to read only.
    //  By setting it to editable on the second mouseup the virtual keyboard shows sooner and makes the
    //  overall card creation feel more performant.
    input.readOnly = options.readOnly || false;

    // If we allow autocomplete, we need to set it to on
    input.autocomplete = options.allowAutocomplete ? 'on' : 'off';

    // Set the input type
    input.type = options.inputType || 'text';

    input.focus();

    // There's a bug on the iPad App where if you double tap to open a sketch the fake input will show the focus
    // cursor until the next element is edited, so we set it back to read only after it's done its job.
    if (!options.readOnly) {
        requestAnimationFrame(() => {
            const focusInput = getFakeInput();
            focusInput.readOnly = true;
        });
    }
};

/**
 * Synchronously focuses a fake input based on the position of a mouse event.
 * This is used on iPads to ensure that the new card gets focused correctly - for some reason iPads need
 * to focus synchronously after a mouse event otherwise editable focus won't be possible. So this fakes
 * a synchronous focus and then the new element can take the focus asynchronously once it's created.
 */
export const focusFakeInputFromMouseEvent = (event: MouseEvent, options: FocusFakeInputOptions = DEFAULT_OPTIONS) => {
    if (!platformSingleton.features.isTouch) return;

    if (!event) return;

    const container = event.currentTarget;
    if (!container) return;

    // determine that the event target is a dom node
    if (!(container instanceof HTMLElement)) return;

    return focusFakeInput({ x: event.pageX, y: event.pageY }, container, options);
};

/**
 * Move focus to a fake input to prevent the focus from being lost.
 * Option to show or hide the keyboard
 */
export const holdFocus = (options: FocusFakeInputOptions = DEFAULT_OPTIONS) => {
    const container = document.querySelector('.MobileWorkspace');
    if (!container) return;

    focusFakeInput({ x: 0, y: 0 }, container, options);
};

/**
 * Move focus to a fake input to prevent the focus from being lost. This sets
 * the input to read only so that the keyboard does not appear.
 */
export const holdFocusWithoutKeyboard = () => {
    holdFocus({ allowAutocomplete: true, readOnly: true });
};
