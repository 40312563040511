// Lib
import React from 'react';

// Constants
import { KEY_CODES } from '../../../../../utils/keyboard/keyConstants';

/**
 * This hook will allow keypress events to be intercepted by a component before they reach the global
 * keyboardShortcuts handler.
 */
const useKeyInterceptor = (onDelete) => {
    React.useEffect(() => {
        const onKeyDown = (event) => {
            switch (event.keyCode) {
                case KEY_CODES.DELETE:
                case KEY_CODES.BACKSPACE:
                    return onDelete && onDelete(event);
                default:
                    return null;
            }
        };

        document.body.addEventListener('keydown', onKeyDown);

        return () => {
            document.body.removeEventListener('keydown', onKeyDown);
        };
    }, []);
};

export default useKeyInterceptor;
