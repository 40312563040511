import { useState, useEffect, useRef } from 'react';
import { getTimestamp } from '../../../common/utils/timeUtil';

/**
 * Ensures that the value remains true for a minimum amount of time.
 * Also allows the transition to be delayed by a constant amount.
 */
export default function useStalledBoolean(bool, minActiveTime = 1000, delayedTransitionTime = 0) {
    // State and setters for debounced value
    const [stalledBool, setStalledBool] = useState(bool);

    const activeTimestamp = useRef(0);

    useEffect(() => {
        let handler;

        if (bool) {
            activeTimestamp.current = getTimestamp();
            setStalledBool(bool);
        } else {
            const currentTime = getTimestamp();
            const delay = Math.max(0, activeTimestamp.current + minActiveTime - currentTime) + delayedTransitionTime;

            handler = setTimeout(() => {
                setStalledBool && setStalledBool(bool);
            }, delay);
        }

        // Clear the timeout on the next change
        return () => clearTimeout(handler);
    }, [bool]);

    return stalledBool;
}
