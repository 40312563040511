// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Actions
import { finishEditingCurrentElement } from '../../../../../element/selection/selectionActions';

// Components
import useSheet from '../../../../../mobile/structural/sheet/hooks/useSheet';
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';
import SheetContainer, { Content, Overlay, Sheet } from '../../../../../mobile/structural/sheet';
import MobileElementContextMenuContent from './MobileElementContextMenuContent';

import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';

const MobileContextMenuTool = (props: any) => {
    const dispatch = useDispatch();
    const sheetProps = useSheet(SheetId.ContextMenuTool);

    const handleToolClick = () => {
        dispatch(finishEditingCurrentElement());
        sheetProps.dispatchOpenSheet();
    };

    return (
        <>
            <ToolbarTool {...props} name={props.name} onClick={handleToolClick}>
                <Icon name="toolbar-context-menu" />
            </ToolbarTool>
            <SheetContainer defaultSnapPoint={'0.5max'} {...sheetProps}>
                <Overlay />
                <Sheet>
                    <Content>
                        <MobileElementContextMenuContent {...props} />
                    </Content>
                </Sheet>
            </SheetContainer>
        </>
    );
};

MobileContextMenuTool.propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
};

export default MobileContextMenuTool;
