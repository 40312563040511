import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const ToastDismissIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        className="Icon toast-dismiss"
        {...props}
    >
        <path
            fill="#1A1A1A"
            fillRule="evenodd"
            d="M6.913 5.587 10 8.674l3.087-3.087a.938.938 0 0 1 1.22-.09l.106.09a.938.938 0 0 1 .09 1.22l-.09.106L11.326 10l3.087 3.087a.938.938 0 0 1-1.326 1.326L10 11.326l-3.087 3.087a.938.938 0 0 1-1.22.09l-.106-.09a.938.938 0 0 1-.09-1.22l.09-.106L8.674 10 5.587 6.913a.938.938 0 0 1 1.326-1.326z"
        />
    </svg>
);
const Memo = memo(ToastDismissIcon);
export default Memo;
