import React from 'react';
import SheetContainer, { Content, Overlay, Sheet } from '../../../../../mobile/structural/sheet';
import useSheet from '../../../../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import TaskDueDatePopupContent from './TaskDueDatePopupContent';
import { holdFocusWithoutKeyboard } from '../../../../../mobile/boardsPage/editing/touchScreenFocusUtils';

import './MobileDueDateSheet.scss';

const MobileDueDateSheet = ({ elementId }: { elementId: string }) => {
    const sheetProps = useSheet(SheetId.DueDateTool, elementId);

    return (
        <SheetContainer className="due-date-tool" {...sheetProps} onCloseTransitionStart={holdFocusWithoutKeyboard}>
            <Overlay />
            <Sheet>
                <Content>
                    <TaskDueDatePopupContent closePopup={sheetProps.dispatchCloseSheet} elementId={elementId} />
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileDueDateSheet;
