import MoreTool from '../../components/moreTool/MoreTool';

// Types
import { MoreToolsSettings, ToolbarItemConfig } from '../../toolbarTypes';

import { COLLAPSE_ORDER } from '../toolbarToolConfigConstants';
import ToolSeparator from '../../components/ToolSeparator';
import { MilanoteApplicationMode } from '../../../../../common/platform/platformTypes';

export const MetaToolIds = {
    MORE: 'META_MORE',
    DIVIDER: 'META_DIVIDER',
    HIDE_TOOLBAR: 'META_HIDE_TOOLBAR',
};

const META_MORE: ToolbarItemConfig = {
    id: MetaToolIds.MORE,
    name: 'More',
    height: {
        large: 16,
        medium: 16,
        small: 14,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    component: MoreTool,
    toolSettings: {
        groupSize: 3,
        items: [],
        popupId: '',
    } as MoreToolsSettings,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

const META_DIVIDER: ToolbarItemConfig = {
    id: MetaToolIds.DIVIDER,
    name: 'Divider',
    height: {
        large: 1,
        medium: 1,
        small: 1,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: ToolSeparator,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

// This tools is used in scenarios when we specifically want to hide the toolbar completely
const META_HIDE_TOOLBAR: ToolbarItemConfig = {
    id: MetaToolIds.HIDE_TOOLBAR,
    name: 'Empty Tool',
    height: {
        large: 0,
        medium: 0,
        small: 0,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: () => null,
    appModeAvailability: [MilanoteApplicationMode.desktop, MilanoteApplicationMode.mobile],
};

export default {
    META_MORE,
    META_DIVIDER,
    META_HIDE_TOOLBAR,
};
