import { useEditorDispatch } from '../../../components/editor/store/components/EditorStoreProvider';
import {
    getEditorIdThunk,
    getEditorStateThunk,
    saveEditorSelection,
} from '../../../components/editor/store/components/editorStoreThunks';
import { SelectionState } from 'draft-js';
import {
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../components/tiptapEditor/store/tiptapEditorStoreHooks';
import { useEffect } from 'react';

/**
 * On render get the current focused element and blur it.
 * On unmount refocus that editor.
 * Works for both draftJS and tiptap editors.
 *
 * For use with sheets:
 * - Run this hook on the component that is rendered within the sheet (not the component with the sheet container).
 * - you MUST pass the onCloseTransitionStart={holdFocusWithoutKeyboard} prop to the sheet so that the focus is not lost during the transition.
 */
const usePauseEditorFocusWhileComponentRendered = (): {
    focusEditor: () => void;
    isTiptapEditor: boolean;
} => {
    const editorDispatch = useEditorDispatch();
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorState = () => editorDispatch(getEditorStateThunk());
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorId = () => editorDispatch(getEditorIdThunk());
    const dispatchForceEditorSelection = (editorId: string, selection: SelectionState) =>
        editorDispatch(saveEditorSelection(editorId, selection));

    const isTiptapEditor = useTiptapActiveEditorSelector((activeEditor) => !!activeEditor);

    const focusDraftEditor = (): void => {
        const editorState = dispatchGetEditorState();
        if (!editorState) return;

        const currentSelection = editorState.getSelection();

        const editorId = dispatchGetEditorId() as unknown as string;
        dispatchForceEditorSelection(editorId, currentSelection);
    };

    const focusTiptapEditor = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.commands.focus();
    });

    const focusEditor = isTiptapEditor ? focusTiptapEditor : focusDraftEditor;

    useEffect(() => {
        const activeElement = document.activeElement as HTMLElement;
        activeElement?.blur();

        return () => {
            focusEditor();
        };
    }, []);

    return isTiptapEditor;
};

export default usePauseEditorFocusWhileComponentRendered;
