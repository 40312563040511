// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { debounce, defer } from 'lodash/fp';
import classNames from 'classnames';

// Utils
import doesEditorJsonHaveText from '../../../common/tiptap/utils/jsonContentUtils/doesEditorJsonHaveText';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';
import { noLongerEditingElement } from '../../components/editor/milanoteEditor/editorPropComparisons';
import { getCreatorId, getElementId, getThreadId } from '../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { getLocalCommentInputIsEditing, getLocalCommentInputText } from './store/commentsInputSelector';
import { getCurrentUser } from '../../user/currentUserSelector';

// Components
import AnchorButton from '../../components/buttons/AnchorButton';
import CommentEditor from './commentEditor/CommentEditorSwitch';
import CommentLine from './CommentLine';

// Actions
import {
    startEditingComment,
    stopEditingComment,
    preCommitComment,
    clearPreCommitComment,
} from './store/localCommentActions';
import { commentsTypingPing, commentsTypingStop } from './store/commentThreadActivityActions';
import { finishEditingElement } from '../selection/selectionActions';

// Constants
import { TIMES } from '../../../common/utils/timeUtil';
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './CommentForm.scss';

const ExtraPadding = () => (
    <div className="CommentForm">
        <div className="extra-padding" />
    </div>
);

const debounceNotFixed = debounce.convert({ fixed: false });

const currentUserIsCreator = ({ element, currentUserId }) => getCreatorId(element) === currentUserId;

const noCommentsAndCurrentUserIsNotCreator = ({ element, currentUserId, commentList }) =>
    (!commentList || !commentList.length) && !currentUserIsCreator({ element, currentUserId });

const noCommentsAndCurrentUserIsCreator = ({ element, currentUserId, commentList }) =>
    (!commentList || !commentList.length) && currentUserIsCreator({ element, currentUserId });

const mapStateToProps = createStructuredSelector({
    currentUser: getCurrentUser,
    isReplying: (state, { element }) => getLocalCommentInputIsEditing(state, { _id: getElementId(element) }),
    localText: (state, { element }) => getLocalCommentInputText(state, { _id: getElementId(element) }),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchStopEditingComment: ({ _id }) => dispatch(stopEditingComment({ _id })),
    dispatchStartEditingComment: ({ _id, elementId }) => dispatch(startEditingComment({ _id, elementId })),
    dispatchPreCommitContent: ({ _id, text }) => dispatch(preCommitComment({ _id, text })),
    dispatchClearPreCommitContent: ({ _id }) => dispatch(clearPreCommitComment({ _id })),
    dispatchFinishEditingElement: () => dispatch(finishEditingElement()),

    dispatchCommentsTypingPing: ({ threadId }) => dispatch(commentsTypingPing({ threadId })),
    dispatchCommentsTypingStop: ({ threadId }) => dispatch(commentsTypingStop({ threadId })),
});

@connect(mapStateToProps, mapDispatchToProps)
class CommentForm extends React.Component {
    constructor() {
        super();
        this.replyButtonRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        // Need to defer because it requires the "localText" to be set, which is performed by
        // the "this.saveContent" function which is executed after componentDidUpdate
        if (nextProps.isReplying && noLongerEditingElement(this.props, nextProps)) defer(this.stopEditingIfEmpty);
    }

    componentDidMount() {
        // on mount, if the thread is inside a collapsable popup,
        // and the current user created the thread,
        // start editing
        if (this.props.isCollapsedThread && noCommentsAndCurrentUserIsCreator(this.props)) {
            this.startEditing();
        }
    }

    componentWillUnmount() {
        this.clearAllTimeouts();
    }

    onAddCommentClick = () => {
        if (this.props.isDuplicating) return;

        this.startEditing();
    };

    onEmptyBackspace = () => {
        const { element, dispatchStopEditingComment, dispatchFinishEditingElement, moveElementsToTrash } = this.props;

        if (noCommentsAndCurrentUserIsCreator(this.props)) moveElementsToTrash();

        // Return element to non editing state
        dispatchStopEditingComment({ _id: getElementId(element) });
        dispatchFinishEditingElement();
        this.stopTypingActivity();
    };

    onSubmit = (text) => {
        const { onSubmit, element, dispatchFinishEditingElement, dispatchClearPreCommitContent } = this.props;

        this.stopTypingActivity();
        dispatchFinishEditingElement();
        onSubmit(text);

        defer(() => dispatchClearPreCommitContent({ _id: getElementId(element) }));
    };

    startEditing = () => {
        const { startEditing, dispatchStartEditingComment, element } = this.props;

        if (!startEditing) return;

        const elementId = getElementId(element);
        dispatchStartEditingComment({ _id: elementId, elementId });

        const elementRect = this.replyButtonRef.current?.getBoundingClientRect();
        const editorFocusClientCoords = elementRect && {
            x: elementRect.x,
            y: elementRect.y,
        };

        startEditing({
            editorId: `${elementId}-${ElementType.COMMENT_THREAD_TYPE}`,
            editorKey: ElementType.COMMENT_THREAD_TYPE,
            editorFocusClientCoords,
        });
    };

    stopEditingIfEmpty = () => {
        const { element, dispatchStopEditingComment, isReplying, localText } = this.props;
        if (isReplying && !doesEditorJsonHaveText(localText))
            dispatchStopEditingComment({ _id: getElementId(element) });
    };

    saveContent = (text) => {
        const { dispatchPreCommitContent, element } = this.props;
        dispatchPreCommitContent({ text, _id: getElementId(element) });
    };

    stopTypingActivity = () => {
        const { dispatchCommentsTypingStop, element } = this.props;
        this.clearAllTimeouts();
        dispatchCommentsTypingStop({ threadId: getThreadId(element) });
    };

    clearAllTimeouts = () => {
        if (this.stopTypingTimeout) clearTimeout(this.stopTypingTimeout);
        this.broadcastActivity.cancel();
    };

    broadcastActivity = debounceNotFixed(
        TIMES.SECOND,
        () => {
            if (this.stopTypingTimeout) clearTimeout(this.stopTypingTimeout);

            const { dispatchCommentsTypingPing, element } = this.props;
            dispatchCommentsTypingPing({ threadId: getThreadId(element) });

            this.stopTypingTimeout = setTimeout(this.stopTypingActivity, 5 * TIMES.SECOND);
        },
        { leading: true, trailing: false, maxWait: 3 * TIMES.SECOND },
    );

    render() {
        const {
            isReplying,
            localText,
            currentUser,
            currentEditorKey,
            currentEditorId,
            isEditing,
            isEditable,
            isPresentational,
            isPreview,
            isSingleSelected,
            inTrash,
            isDuplicating,
            activeUsers,
            openHyperlinkPopup,
            hideEditing,
            spellCheck,
            postButtonRef,
        } = this.props;

        if (noCommentsAndCurrentUserIsNotCreator(this.props)) return null;
        if (inTrash) return <ExtraPadding />;

        if (!isEditable && !isPresentational && !hideEditing) return null;

        const showEditor = isReplying || noCommentsAndCurrentUserIsCreator(this.props);

        const showingActivityIndicator = activeUsers && activeUsers.size;

        const classes = classNames('CommentForm', {
            'showing-activity': showingActivityIndicator,
            replying: showEditor,
            hide: hideEditing,
        });

        if (!showEditor) {
            return (
                <div className={classes} ref={this.replyButtonRef}>
                    <AnchorButton
                        className={classNames('reply-button', { disabled: isDuplicating })}
                        onClickFn={this.onAddCommentClick}
                    >
                        Reply
                    </AnchorButton>
                </div>
            );
        }

        const editorId = getMainEditorId(this.props);
        const editorKey = getMainEditorKey(this.props);

        return (
            <CommentLine className={classes} user={currentUser}>
                <CommentEditor
                    isUpdating
                    isPreview={isPreview}
                    postButtonRef={postButtonRef}
                    onActivity={this.broadcastActivity}
                    editorId={editorId}
                    editorKey={editorKey}
                    currentEditorKey={currentEditorKey}
                    currentEditorId={currentEditorId}
                    placeholder="Write a comment..."
                    textContent={localText}
                    saveContent={this.saveContent}
                    onSubmit={this.onSubmit}
                    onEmptyBackspace={this.onEmptyBackspace}
                    startEditing={this.startEditing}
                    isEditing={isEditing}
                    isEditable={isEditable}
                    isSingleSelected={isSingleSelected}
                    spellCheck={spellCheck}
                    openHyperlinkPopup={openHyperlinkPopup}
                />
            </CommentLine>
        );
    }
}

CommentForm.propTypes = {
    threadId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    startEditing: PropTypes.func,
    saveContent: PropTypes.func,
    dispatchStartEditingComment: PropTypes.func,
    dispatchStopEditingComment: PropTypes.func,
    dispatchPreCommitContent: PropTypes.func,
    dispatchClearPreCommitContent: PropTypes.func,
    moveElementsToTrash: PropTypes.func,
    element: PropTypes.object.isRequired,
    currentUserId: PropTypes.string,
    currentUser: PropTypes.object,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isPreview: PropTypes.bool,
    hideEditing: PropTypes.bool,
    inTrash: PropTypes.bool,
    spellCheck: PropTypes.bool,
    isCollapsedThread: PropTypes.bool,
    isDuplicating: PropTypes.bool,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    commentList: PropTypes.array,
    isReplying: PropTypes.bool,
    localText: PropTypes.object,
    onEmptyBackspace: PropTypes.func,
    dispatchCommentsTypingStop: PropTypes.func,
    dispatchCommentsTypingPing: PropTypes.func,
    dispatchFinishEditingElement: PropTypes.func,
    activeUsers: PropTypes.object,
    openHyperlinkPopup: PropTypes.func,
    postButtonRef: PropTypes.object,
};

export default CommentForm;
