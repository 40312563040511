// Utils
import { CheckoutPrice, ProductPricingScheme } from '../products/productTypes';
import { getSubscriptionProvider } from '../subscriptionPropertyUtil';

// Types
import {
    MNAppStoreSubscription,
    MNFullAppStoreSubscriptionDetail,
    MNFullStripeSubscriptionDetail,
    MNFullSubscriptionDetail,
    MNStripeSubscription,
    MNSubscription,
    MNSubscriptionProvider,
} from './subscriptionTypes';
import { ImMap } from '../../utils/immutableHelper';

/**********************************
 * isStripeSubscription
 **********************************/

// TS Overloads - These are more specific type definitions of various usages of isStripeSubscription
export function isStripeSubscription(subscription: MNSubscription): subscription is MNStripeSubscription;
export function isStripeSubscription(
    subscription: MNFullSubscriptionDetail,
): subscription is MNFullStripeSubscriptionDetail;
export function isStripeSubscription(
    subscription: ImMap<MNFullSubscriptionDetail>,
    // @ts-ignore - This is technically correct, but limitations in Immutable JS prevents us from typing this correctly
): subscription is ImMap<MNFullStripeSubscriptionDetail>;

// Implementation
export function isStripeSubscription(
    subscription: MNSubscription | MNFullSubscriptionDetail | ImMap<MNFullSubscriptionDetail>,
) {
    return getSubscriptionProvider(subscription) === MNSubscriptionProvider.STRIPE;
}

/**********************************
 * isAppStoreSubscription
 **********************************/

// TS Overloads - These are more specific type definitions of various usages of isAppStoreSubscription
export function isAppStoreSubscription(subscription: MNSubscription): subscription is MNAppStoreSubscription;
export function isAppStoreSubscription(sub: MNFullSubscriptionDetail): sub is MNFullAppStoreSubscriptionDetail;
export function isAppStoreSubscription(
    subscription: ImMap<MNFullSubscriptionDetail>,
    // @ts-ignore - This is technically correct, but limitations in Immutable JS prevents us from typing this correctly
): subscription is ImMap<MNFullAppStoreSubscriptionDetail>;

// Implementation
export function isAppStoreSubscription(
    subscription: MNSubscription | MNFullSubscriptionDetail | ImMap<MNFullSubscriptionDetail>,
): boolean {
    return getSubscriptionProvider(subscription) === MNSubscriptionProvider.APPLE;
}

/**********************************
 * Others
 **********************************/

export const getSubscriptionMaxSeats = (
    pricingScheme: ProductPricingScheme,
    quantity: number,
    price: CheckoutPrice,
): number => {
    switch (pricingScheme) {
        case ProductPricingScheme.SEAT:
            return quantity;
        case ProductPricingScheme.TIERED:
            return price.maxSeats || 1;
        case ProductPricingScheme.FREE:
        default:
            return 0;
    }
};
