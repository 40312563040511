import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';

import { getIsIconActive } from './iconListUtils';
import Spinner from '../../../../../../../../components/loaders/Spinner';

import './IconList.scss';

const IconListLoading = (props) => (
    <div className="IconList">
        <div className="iconlist-loading">
            <Spinner show />
        </div>
    </div>
);

const IconListEmpty = ({ popupQuery, setModeDefault }) => (
    <div className="IconList">
        <div className="iconlist-loading">
            <div className="no-results">
                <p>No icons found for &quot;{popupQuery}&quot;.</p>
                <p>
                    <a href="#" onClick={setModeDefault}>
                        See recommended icons
                    </a>
                </p>
            </div>
        </div>
    </div>
);

IconListEmpty.propTypes = {
    popupQuery: PropTypes.string,
    setModeDefault: PropTypes.func,
};

export const IconListResults = (props) => (
    <div className="IconList">
        <ul>
            {props.iconList.map((icon, i) => (
                <li key={i}>
                    <IconButton {...props} icon={icon} isActive={getIsIconActive(props.selectedElements, icon)} />
                </li>
            ))}
        </ul>
    </div>
);

IconListResults.propTypes = {
    iconList: PropTypes.array,
    selectedElements: PropTypes.object,
};

const IconList = (props) => {
    const { iconList } = props;

    if (!iconList) return <IconListLoading {...props} />;
    if (!iconList.length) return <IconListEmpty {...props} />;
    return <IconListResults {...props} />;
};

IconList.propTypes = {
    iconList: PropTypes.array,
};

export default IconList;
