// Utils
import { asObject, getMany } from '../../../common/utils/immutableHelper';
import { searchForIcon } from '../../components/elementIcon/elementIconService';
import { isBoard } from '../../../common/elements/utils/elementTypeUtils';
import makeStringIcon from '../../components/elementIcon/makeStringIconDef';
import { getFirstIconString } from '../../components/elementIcon/elementIconUtils';
import { updateElement } from '../actions/elementActions';
import { boardVisibleElementGraphSelector, elementGraphSelector } from '../selectors/elementGraphSelector';
import { getClosestAncestorBoardId, getElement } from '../../../common/elements/utils/elementTraversalUtils';
import { getChildrenViaGraph } from '../../../common/elements/utils/elementGraphUtils';
import { getElements } from '../selectors/elementsSelector';
import {
    getCanvasOriginCoordinates,
    getElementId,
    getXPosition,
    getYPosition,
} from '../../../common/elements/utils/elementPropertyUtils';
import { sendBoardIconSetEvent } from '../../components/elementIcon/elementIconAnalyticsUtils';
import { pickUnusedIcon } from './boardActionsUtil';
import { setCanvasInitialSize } from '../../canvas/store/canvasActions';
import { isDualColor } from '../../../common/colors/dualColorUtils';

// Selectors
import currentBoardSuggestedColorsSelector from '../../workspace/toolbar/components/selectionTools/colorTools/currentBoardSuggestedColorsSelector';

// Errors
import CanvasSizeError from '../../canvas/CanvasSizeError';
import { manuallyReportError } from '../../analytics/rollbarService';

// Constants
import * as TYPES from '../../../common/elements/elementConstants';
import { DEFAULT_BOARD_COLORS_ARRAY } from '../../../common/colors/colorConstants';
import { ICON_SEARCH_MODES } from '../../../common/icons/iconConstants';
import { ELEMENT_UPDATE_TYPE } from '../../../common/elements/elementConstants';
import { MAXIMUM_CANVAS_ORIGIN, MINIMUM_CANVAS_ORIGIN } from '../../../common/elements/utils/elementPositionUtils';
import { currentBoardDualColorListSelector } from '../../workspace/toolbar/components/selectionTools/colorTools/dualColors/currentBoardDualColorListSelector';
import { combineDualColorAndSuggestionLists } from '../../workspace/toolbar/components/selectionTools/colorTools/dualColors/dualColorListUtils';

const COLOR_INDEX_MAX = 100;
let colorIndex = Math.floor(Math.random() * COLOR_INDEX_MAX);

const getPeerBoards = (element, state) => {
    const elementId = getElementId(element);
    const elements = getElements(state);

    const parentBoardId = getClosestAncestorBoardId(elements, elementId);
    const elementGraph = boardVisibleElementGraphSelector(state);

    const peerBoards = getChildrenViaGraph({ elements, elementGraph, parentId: parentBoardId }).filter(isBoard);

    return peerBoards;
};

const getFallbackNumber = (peerBoards) => {
    if (!peerBoards) return 1;
    const boardCount = peerBoards.size;

    return boardCount.toString();
};

const makeFallbackBoardIcon = ({ title, peerBoards }) => {
    const iconString = getFirstIconString(title) || getFallbackNumber(peerBoards);
    return makeStringIcon(iconString);
};

export const getColor = (title, state) => {
    const matchedColorWord = DEFAULT_BOARD_COLORS_ARRAY.find(
        (color) => (title || '').toUpperCase().indexOf(color) >= 0,
    );
    if (matchedColorWord) return matchedColorWord;

    colorIndex = (1 + colorIndex) % COLOR_INDEX_MAX;

    const suggestedColors = currentBoardSuggestedColorsSelector(state);
    const dualColorList = currentBoardDualColorListSelector(state);
    const selectionList = combineDualColorAndSuggestionLists(suggestedColors, dualColorList);
    return selectionList[colorIndex % selectionList.length];
};

export const getBoardDecoration = async ({ id, title, state }) => {
    let icons = [];
    let isFallback = false;

    try {
        const { data } = await searchForIcon(title, 4, ICON_SEARCH_MODES.BOARD_CREATE);

        icons = data.icons;
        isFallback = data.isFallback;
    } catch (e) {
        // Do nothing
    }

    const element = state.getIn(['elements', id]);
    const peerBoards = getPeerBoards(element, state);

    let icon = pickUnusedIcon(icons, peerBoards);
    if (!icon) {
        icon = makeFallbackBoardIcon({ title, peerBoards });
        isFallback = true;
    }

    sendBoardIconSetEvent({
        boardId: id,
        icon,
        searchQuery: title,
        setMode: ICON_SEARCH_MODES.BOARD_CREATE,
        isFallback,
    });

    return { icon };
};

export const initializeBoard =
    ({ id, title, currentColor, currentSecondaryColor, transactionId }) =>
    async (dispatch, getState) => {
        const state = getState();

        // Force a local update prior to the icon being set
        dispatch(
            updateElement({
                type: TYPES.ELEMENT_UPDATE,
                id,
                changes: {
                    title,
                },
                transactionId,
                sync: false,
            }),
        );

        dispatch({
            type: TYPES.ELEMENT_ICON_FIND,
            id,
            title,
        });

        let color = currentColor;
        let secondaryColor = currentSecondaryColor;

        // only check for primary color here because its important to keep secondary null if there is no secondary passed in
        // this allows for black/white based on the primary color
        if (!color) {
            const colorItem = getColor(title, state);
            const isDual = isDualColor(colorItem);
            color = isDual ? colorItem.primary : colorItem;
            secondaryColor = isDual ? colorItem.secondary : null;
        }

        const decoration = await getBoardDecoration({ id, title, state });

        dispatch({ type: TYPES.ELEMENT_ICON_FIND_SUCCESS, id });

        dispatch(
            updateElement({
                type: TYPES.ELEMENT_UPDATE,
                id,
                changes: {
                    ...decoration,
                    title,
                    color,
                    secondaryColor,
                    autoColorIndex: colorIndex,
                },
                transactionId,
            }),
        );
    };

/**
 * Updates the canvas origin for a given board ID.
 */
export const updateBoardCanvasOrigin =
    ({ id, canvasOrigin, ...rest }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const element = getElement(elements, id);
        const initialCanvasOrigin = asObject(getCanvasOriginCoordinates(element));

        if (canvasOrigin.x < MINIMUM_CANVAS_ORIGIN || canvasOrigin.y < MINIMUM_CANVAS_ORIGIN) {
            // Error debugging - Determine if any of the elements are already beyond the canvas origin.
            //  If so, the board is in an errored state and can't be recovered from without further
            //  intervention.  Somehow elements have ended up beyond the canvas origin.
            const elementGraph = elementGraphSelector(state);
            const boardChildIds = elementGraph[id] || [];
            const boardChildren = getMany(boardChildIds, elements);

            const inaccessibleElements = new Set();

            boardChildren.forEach((child) => {
                const childId = getElementId(child);
                const xPosition = getXPosition(child);
                const yPosition = getYPosition(child);

                const elementIsNotAccessible =
                    (xPosition < initialCanvasOrigin.x && xPosition < MINIMUM_CANVAS_ORIGIN) ||
                    (yPosition < initialCanvasOrigin.y && yPosition < MINIMUM_CANVAS_ORIGIN);

                if (elementIsNotAccessible) {
                    inaccessibleElements.add(childId);
                }
            });

            if (inaccessibleElements.size !== 0) {
                manuallyReportError({
                    errorMessage: 'Board is in a broken state - elements are beyond the canvas origin',
                    custom: {
                        currentBoardId: id,
                        elementIds: [...inaccessibleElements].join(','),
                    },
                    getState,
                });
            }

            throw new CanvasSizeError(`Cannot extend the canvas origin beyond -500,-500`, {
                friendlyMessage: "The canvas can't grow this far",
                tip: 'Try moving the content towards the bottom right',
                details: {
                    canvasOrigin,
                },
            });
        }

        if (canvasOrigin.x > MAXIMUM_CANVAS_ORIGIN || canvasOrigin.y > MAXIMUM_CANVAS_ORIGIN) {
            throw new CanvasSizeError(`Cannot extend the canvas origin beyond 0,0`, {
                friendlyMessage: 'An error has occurred with the canvas size',
                details: {
                    canvasOrigin,
                },
            });
        }

        dispatch(setCanvasInitialSize({ boardId: id, canvasOrigin: initialCanvasOrigin }));

        dispatch(
            updateElement({
                id,
                updateType: ELEMENT_UPDATE_TYPE.CANVAS_ORIGIN,
                changes: {
                    canvasOrigin,
                },
                ...rest,
            }),
        );
    };
