import { DragState } from '../hooks/useSheetHandlers';
import { RefObject } from 'react';
import { getInterpolatedPosition } from '../../../../utils/animation/springAnimationUtils';
import { CLOSE_SHEET_TRANSFORM_POS, getSheetHeightAdjustedForClose, setSheetHeight } from './sheetHeightUtils';

const NUM_VELOCITIES_TO_AVERAGE = 4;
const MIN_VELOCITY = 0.1;

const START_STIFFNESS = 500;
const END_STIFFNESS = 200;
const DAMPING = 30;

export const INITIAL_ANIMATION_STATE = {
    initialHeight: 0,
    height: 0,
    velocity: 0,
    finalHeight: 0,
    directionUp: false,
    completeAnimation: () => {},
};

export type AnimationStartValues = {
    initialHeight: number;
    height: number;
    velocity: number;
    finalHeight: number;
    directionUp: boolean;
    completeAnimation: () => void;
};

export const saveGraphData = (dragState: RefObject<DragState>) => (velocityPxMs: number) => {
    if (!dragState.current?.graphData) return;

    const data = {
        x: performance.now() - dragState.current.touchStartTimestamp,
        y: velocityPxMs,
    };

    dragState.current.isDragging
        ? dragState.current.graphData?.speedDataDragging.push(data)
        : dragState.current.graphData?.speedDataNotDragging.push(data);
};

export const getAverageOfLastValuesInArray = (array: number[], num: number): number | undefined => {
    const sliceLength = Math.min(array.length, num);
    const numbersToAverage = array.slice(-sliceLength);

    if (numbersToAverage.length < 1) return;

    return numbersToAverage.reduce((a, b) => a + b) / num;
};

export const getAnimationStartValues = (
    sheetRef: RefObject<HTMLDivElement>,
    dragState: RefObject<DragState>,
    newActiveSnapPoint: number,
    completeAnimation: () => void,
): AnimationStartValues => {
    if (!sheetRef.current || !dragState.current) return INITIAL_ANIMATION_STATE;

    const { velocities } = dragState.current;

    const initialHeight = dragState.current.latestSheetHeight;
    const finalHeight = getSheetHeightAdjustedForClose(newActiveSnapPoint);

    const directionUp = finalHeight > initialHeight;
    const avgVelocityPxMs = getAverageOfLastValuesInArray(velocities, NUM_VELOCITIES_TO_AVERAGE) || MIN_VELOCITY;
    const velocityPxS = avgVelocityPxMs * 1000;

    return {
        initialHeight,
        height: initialHeight,
        velocity: velocityPxS,
        finalHeight,
        directionUp,
        completeAnimation,
    };
};

const getStiffnessValue = (startValue: number, endValue: number, progress: number): number =>
    startValue + progress * (endValue - startValue);

export const animateSheet =
    (
        sheetRef: RefObject<HTMLDivElement>,
        animationState: RefObject<AnimationStartValues>,
        saveGraphData?: ((velocity: number) => void) | undefined,
    ) =>
    (delta: number) => {
        if (!sheetRef.current || !animationState.current) return;

        const { initialHeight, height, velocity, finalHeight, directionUp, completeAnimation } = animationState.current;

        if (saveGraphData) saveGraphData(velocity / 1000);

        const totalDistance = Math.abs(finalHeight - initialHeight);
        const distanceTraveled = Math.abs(height - initialHeight);
        const stiffnessValue = getStiffnessValue(START_STIFFNESS, END_STIFFNESS, distanceTraveled / totalDistance);

        const [nextHeight, nextVelocity] = getInterpolatedPosition(
            delta,
            height,
            velocity,
            finalHeight,
            stiffnessValue,
            DAMPING,
        );

        // Finish animation once within 1px of final height
        const nearFinalHeight = directionUp ? height >= finalHeight - 1 : height <= finalHeight + 1;
        const outsideWindow = nextHeight > window.innerHeight || nextHeight < CLOSE_SHEET_TRANSFORM_POS;
        const finishAnimation = nearFinalHeight || outsideWindow;
        const newHeight = finishAnimation ? finalHeight : nextHeight;

        animationState.current.velocity = nextVelocity;
        animationState.current.height = newHeight;

        setSheetHeight(sheetRef, newHeight);

        if (finishAnimation) {
            completeAnimation();
        }
    };
