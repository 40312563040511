// Lib
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import {
    getDueDate,
    getDueReminder,
    getElementId,
    getHasDueDateTime,
} from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getNewReminderOption } from './taskDueDateReminderUtils';
import { removeElementDueDate } from './taskDueDateActions';
import { getAreDatesSameDay } from '../../../../../../common/utils/timeUtil';

// Selectors
import { getElement } from '../../../../selectors/elementSelector';
import { userLanguagePreferenceSelector } from '../../../../../user/currentUserSelector';

// Components
import useKeyInterceptor from './useKeyInterceptor';
import LifecycleComponent from './LifecycleComponent';
import Calendar from '../../../../../components/datetime/Calendar';
import DueDateTimePicker from './DueDateTimePicker';
import DueDateReminderSelect from './DueDateReminderSelect';

// Hooks
import useTaskPopupFocus from '../useTaskPopupFocus';
import useTaskDueDateDispatchFunctions from './useTaskDueDateDispatchFunctions';

// Constants
import { REMINDER_VALUES } from './taskDueDateConstants';

// Styles
import './TaskDueDatePopupContent.scss';

const getNewDate = () => {
    const newDate = new Date();

    // By default, make it due at 5pm
    newDate.setHours(17);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
};

const TaskDueDatePopupContent = (props: { elementId: string; closePopup: () => void }) => {
    const userLanguagePreference = useSelector(userLanguagePreferenceSelector);
    const element = useSelector((state) => getElement(state, props));

    const [userPersistedDueReminder, setUserPersistedDueReminder] = useState<number | null>(null);

    const elementId = getElementId(element);
    const initialDate = getDueDate(element);

    let hasDueDateTime = getHasDueDateTime(element) || false;
    let dueDate = initialDate ? new Date(initialDate) : getNewDate();
    let dueReminder = getDueReminder(element) || REMINDER_VALUES.NINE_AM_DAY_OF;

    const { dispatchSetDueDate, dispatchSetTemporaryDueDate, dispatchSetFocusToPopupInput, onDelete } =
        useTaskDueDateDispatchFunctions(props);

    useKeyInterceptor(onDelete);

    const dispatch = useDispatch();

    // This is used so the original task editor can have its focus returned to
    useTaskPopupFocus({ ...props, dispatchSetFocusToPopupInput, dispatch });

    // On component will mount, save a reference to the initial element so that
    // the undo update event can be created correctly
    const initialElementRef = React.useRef(element);

    // On close, save the due date
    const componentWillUnmount = () => {
        // If no initial date, then the user hasn't selected anything
        if (!initialDate) return;

        const dueDateTimestamp = new Date(dueDate).toISOString();
        dispatchSetDueDate({
            elementId,
            dueDate: dueDateTimestamp,
            hasDueDateTime,
            dueReminder,
            initialElement: initialElementRef.current,
        });
    };

    // If the user changes from a time to no time, then they might have selected a reminder option that no longer
    // makes sense. If that happens, return to the default 9am day of
    const updateHasDueDateTime = (updatedHasDueDateTime: boolean) => {
        hasDueDateTime = updatedHasDueDateTime;

        dueReminder = getNewReminderOption({
            dueDate,
            hasDueDateTime,
            dueReminder,
            userPersistedDueReminder,
        });

        dispatchSetTemporaryDueDate(elementId, dueDate, hasDueDateTime, dueReminder);
    };

    const updateDueDate = (updatedDate: Date) => {
        dueDate = updatedDate;
        dueReminder = getNewReminderOption({
            dueDate,
            hasDueDateTime,
            dueReminder,
            userPersistedDueReminder,
        });
        dispatchSetTemporaryDueDate(elementId, dueDate, hasDueDateTime, dueReminder);
    };

    const onCalendarChange = (updatedDate: Date) => {
        // Clicking the selected date should remove the due date, but not close the popup
        if (getDueDate(element) && getAreDatesSameDay(updatedDate, dueDate)) {
            dispatch(removeElementDueDate({ id: elementId, showDueDate: true }));
            return;
        }

        updateDueDate(updatedDate);
    };

    const updateDueReminder = (updatedReminder: number) => {
        setUserPersistedDueReminder(updatedReminder);
        dueReminder = updatedReminder;
        dispatchSetTemporaryDueDate(elementId, dueDate, hasDueDateTime, dueReminder);
    };

    return (
        <div className="TaskDueDatePopupContent">
            <LifecycleComponent componentWillUnmount={componentWillUnmount} />
            <div className="calendar-section">
                <Calendar value={dueDate} onChange={onCalendarChange} locale={userLanguagePreference} futureOnly />
            </div>
            <div className="time-section">
                <DueDateTimePicker
                    hasDueDateTime={hasDueDateTime}
                    setHasDueDateTime={updateHasDueDateTime}
                    dueDate={dueDate}
                    setDueDate={updateDueDate}
                />
            </div>
            <div className="reminder-section">
                <DueDateReminderSelect
                    hasDueDateTime={hasDueDateTime}
                    value={dueReminder}
                    onChange={updateDueReminder}
                />
            </div>
        </div>
    );
};

export default TaskDueDatePopupContent;
