// Lib
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

// Actions
import { finishEditingCurrentElement } from '../../../element/selection/selectionActions';

// Utils
import {
    getCurrentlyEditingEditorKey,
    getCurrentlyEditingId,
} from '../../../element/selection/currentlyEditingSelector';
import { getIsAnySheetOpen } from '../../structural/sheet/sheetSelectors';

const KEYBOARD_REVEAL_DURATION = 300;

export let maxKeyboardHeight = 250;

/**
 * Listens to changes in the visual viewport to dispatch the following events:
 * - keyboardDidShow
 * - keyboardWillHide
 */
export const useMobileKeyboardEventHandler = (): void => {
    const dispatch = useDispatch();
    const dispatchFinishEditing = () => dispatch(finishEditingCurrentElement());

    const editorKey = useSelector(getCurrentlyEditingEditorKey);
    const isEditingElement = !!editorKey;

    const isAnySheetOpen = useSelector(getIsAnySheetOpen);
    const isAnySheetOpenRef = useRef(isAnySheetOpen);

    const currentlyEditingElementId = useSelector(getCurrentlyEditingId);
    const currentlyEditingElementIdRef = useRef(currentlyEditingElementId);

    useEffect(() => {
        isAnySheetOpenRef.current = isAnySheetOpen;
    }, [isAnySheetOpen]);

    useEffect(() => {
        currentlyEditingElementIdRef.current = currentlyEditingElementId;
    }, [currentlyEditingElementId]);

    const isKeyboardRevealed = useRef<boolean>(false);

    const updateKeyboardHeight = (keyboardHeight: number) => {
        document.body.style.setProperty('--keyboard-height', `${keyboardHeight}px`);
        if (keyboardHeight > maxKeyboardHeight) {
            maxKeyboardHeight = keyboardHeight;
        }
    };

    const onKeyboardWillShow = (keyboardHeight: number) => {
        isKeyboardRevealed.current = true;

        updateKeyboardHeight(keyboardHeight);

        window.dispatchEvent(new CustomEvent('keyboardWillShow', { detail: { keyboardHeight } }));
    };

    const onKeyboardDidShow = (keyboardHeight: number) => {
        isKeyboardRevealed.current = true;

        document.body.classList.add('keyboard-open');

        window.dispatchEvent(new CustomEvent('keyboardDidShow', { detail: { keyboardHeight } }));
    };

    const onKeyboardWillHide = () => {
        isKeyboardRevealed.current = false;

        document.body.classList.remove('keyboard-open');

        // Finish editing element when the keyboard is hides, e.g. when you tap the done button on iOS keyboards.
        // An exception is when another sheet is opened -- like the text style sheet or context menu, in which case,
        // we don't want to finish editing the element.
        if (!isAnySheetOpenRef.current) dispatchFinishEditing();

        window.dispatchEvent(new CustomEvent('keyboardWillHide'));
    };

    // FIXME: Capacitor.isPluginAvailable('Keyboard') returns false in live-reload-ngrok mode, which means that
    //        the toolbar does not get correctly revealed in this mode (https://linear.app/milanote/issue/MOB-1672)
    // On Capacitor apps, use the Keyboard Capacitor plugin to listen to keyboardWillShow event
    useEffect(() => {
        if (Capacitor.isPluginAvailable('Keyboard')) {
            Keyboard.removeAllListeners();

            Keyboard.addListener('keyboardWillShow', (info) => onKeyboardWillShow(info.keyboardHeight));
            Keyboard.addListener('keyboardDidShow', (info) => onKeyboardDidShow(info.keyboardHeight));
            Keyboard.addListener('keyboardWillHide', () => onKeyboardWillHide());

            return () => {
                Keyboard.removeAllListeners();
            };
        }
    }, [editorKey]);

    useEffect(() => {
        // Toolbar is always hidden on cleanup so if we're not editing, we don't need to do anything
        if (!isEditingElement) return;

        // On Capacitor apps, also hide keyboard when isEditingElement updates to false
        //
        // NOTE: This is doing the same thing as the keyboardWillHide event listener above, with the idea that whichever
        //   comes first will hide the keyboard, as each method can be a bit late in timing the keyboard hide event
        //   depending on situation.
        if (Capacitor.isPluginAvailable('Keyboard')) {
            return () => {
                onKeyboardWillHide();
            };
        }

        // On non-Capacitor apps, manually calculate the keyboard position on resize/scroll events

        const recalculateKeyboardPosition = () => {
            if (!window.visualViewport) return;

            const keyboardHeight = window.innerHeight - window.visualViewport.height;

            // Reveal keyboard after 300ms timeout to wait until keyboard has fully shown
            const isRevealingKeyboard = !isKeyboardRevealed.current && keyboardHeight > 0;
            if (isRevealingKeyboard) {
                onKeyboardWillShow(keyboardHeight);
                setTimeout(() => onKeyboardDidShow(keyboardHeight), KEYBOARD_REVEAL_DURATION);
            }

            // Update keyboard height if it's already shown
            // this can occur when the auto-correct bar is shown/hidden
            const isUpdatingKeyboardHeight = isKeyboardRevealed.current && keyboardHeight > 0;
            if (isUpdatingKeyboardHeight) {
                updateKeyboardHeight(keyboardHeight);
            }

            const isHidingKeyboard = isKeyboardRevealed.current && keyboardHeight === 0;
            if (isHidingKeyboard) onKeyboardWillHide();
        };

        recalculateKeyboardPosition();

        window.visualViewport?.addEventListener('resize', recalculateKeyboardPosition);

        return () => {
            window.visualViewport?.removeEventListener('resize', recalculateKeyboardPosition);

            onKeyboardWillHide();
        };
    }, [isEditingElement]);
};

export default useMobileKeyboardEventHandler;
