// Lib
import { useRef, useState } from 'react';
import requestSecondAnimationFrame from '../../../../../common/utils/lib/requestSecondAnimationFrame';

// Hooks
import useSheetInitialisation from './useSheetInitialisation';
import useSheetHandlers, { SheetHandlers } from './useSheetHandlers';
import useSheetSnapPointState from './useSheetSnapPointState';
import useSheetObstructionHandler from './useSheetObstructionHandler';

// Types
import { SheetProps } from '../SheetContainer';

export const MAX_SUFFIX = 'max';

export interface SheetContextState extends SheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isSheetMounted: boolean;
    setIsSheetMounted: (isMounted: boolean) => void;
    closeSheetWithTransition: () => void;
}

const useSheetContextState = (props: SheetProps): SheetContextState => {
    const {
        sheetRef,
        sheetContentRef,
        sheetKey,
        snapPoints = [],
        defaultSnapPoint,
        isSheetOpen,
        dispatchCloseSheet,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        onCloseTransitionStart,
        preventDismiss = false,
        onCloseTransitionEnd,
        onOpenTransitionEnd,
    } = props;

    const [isSheetMounted, setIsSheetMounted] = useState(false);

    const cancelInProgressCloseTransition = useRef<(() => void) | null>(null);
    const cancelInProgressDragAnimation = useRef<(() => void) | null>(null);

    const { snapPointsState, goToSnapPoint, addSnapPoint } = useSheetSnapPointState(
        snapPoints,
        sheetRef,
        sheetContentRef,
        activeSnapPoint,
        dispatchUpdateActiveSnapPoint,
        preventDismiss,
    );

    const cancelInProgressAnimations = () => {
        // Cancel close transition functionality triggered in the closeSheetWithTransition function
        // This includes cancelling the animation frame request (if it hasn't run yet) and removing the event listener
        if (cancelInProgressCloseTransition.current) {
            cancelInProgressCloseTransition.current();
        }

        // Cancel any in-progress drag animations that run from when sheet drag ends until it reaches the snap point
        if (cancelInProgressDragAnimation.current) {
            cancelInProgressDragAnimation.current();
        }
    };

    /**
     * Close the sheet with a css transition.
     * This is used when the sheet is NOT closed by a drag.
     */
    const closeSheetWithTransition = () => {
        if (!isSheetMounted || !sheetRef.current) return;

        cancelInProgressAnimations();
        dispatchCloseSheet();
        onCloseTransitionStart?.();

        const transitionEndCallback = () => {
            cancelInProgressCloseTransition.current = null;
            setIsSheetMounted(false);
            onCloseTransitionEnd?.();
        };

        const cancelAnimationFrame = requestSecondAnimationFrame(() => {
            if (!sheetRef.current) return;

            goToSnapPoint(0);

            sheetRef.current.addEventListener('transitionend', transitionEndCallback, { once: true });
        });

        cancelInProgressCloseTransition.current = () => {
            if (!sheetRef.current) return;

            sheetRef.current.removeEventListener('transitionend', transitionEndCallback);

            cancelAnimationFrame();
            cancelInProgressCloseTransition.current = null;
        };
    };

    const sheetInitialised = useSheetInitialisation(
        isSheetOpen,
        sheetRef,
        defaultSnapPoint,
        addSnapPoint,
        goToSnapPoint,
        closeSheetWithTransition,
        isSheetMounted,
        cancelInProgressAnimations,
        sheetContentRef,
        onOpenTransitionEnd,
    );

    const handlers = useSheetHandlers(
        props,
        sheetInitialised,
        snapPointsState,
        goToSnapPoint,
        cancelInProgressDragAnimation,
        setIsSheetMounted,
    );

    useSheetObstructionHandler(sheetKey, isSheetOpen, snapPoints, goToSnapPoint);

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        closeSheetWithTransition,
        isSheetMounted,
        setIsSheetMounted,
    };
};

export default useSheetContextState;
