// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withProps, withState, withHandlers } from '../../../../../node_module_clones/recompose';
import { isEmpty } from 'lodash/fp';

// Selectors
import {
    getCurrentlyAssignedUserId,
    getDisabledSuggestions,
    getEnabledSuggestions,
    getFilteredSuggestions,
} from './taskAssignmentPopupSelector';

// Actions
import { assignTask, removeTaskAssignments } from './taskAssignmentActions';
import { closePopup } from '../../../../components/popupPanel/popupActions';
import { startEditingElement } from '../../../selection/selectionActions';

// Components
import ListFocusManager from '../../../../components/lists/ListFocusManager';
import ListKeyboardManager, { LIST_DIRECTIONS } from '../../../../components/lists/ListKeyboardManager';
import AssignmentSearchForm from './AssignmentSearchForm';
import AssignmentSuggestionResults from './AssignmentSuggestionResults';

// Hooks
import useTaskPopupFocus from './useTaskPopupFocus';

// Styles
import './TaskAssignmentPopupContent.scss';

const mapStateToProps = createStructuredSelector({
    suggestions: getFilteredSuggestions,
    enabledSuggestions: getEnabledSuggestions,
    disabledSuggestions: getDisabledSuggestions,
    currentlyAssignedUserId: getCurrentlyAssignedUserId,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAssignTask: ({ elementId, popupId, suggestion }) => {
        if (!suggestion) return;

        dispatch(
            assignTask({
                taskId: elementId,
                assigneeId: suggestion.id,
                assigneeName: suggestion.name,
            }),
        );

        dispatch(closePopup(popupId, false));
    },
    dispatchRemoveTaskAssignments: ({ elementId, popupId }) => {
        dispatch(removeTaskAssignments({ id: elementId }));
        dispatch(closePopup(popupId));
    },
    dispatchClosePopup: (popupId) => dispatch(closePopup(popupId, false)),
    dispatchSetFocusToPopupInput: ({ elementId }) =>
        dispatch(
            startEditingElement({
                id: elementId,
                editorId: `${elementId}-assignment`,
                editorKey: 'TASK',
            }),
        ),
    dispatch,
});

const enhance = compose(
    withState('searchTerm', 'setSearchTerm', ''),
    connect(mapStateToProps, mapDispatchToProps),
    withProps((ownProps) => ({
        initialFocusIndex: -1,
        listSize: ownProps.enabledSuggestions ? ownProps.enabledSuggestions.length : 0,
    })),
    ListFocusManager(), // eslint-disable-line new-cap
    withHandlers({
        onClickSuggestion:
            ({ elementId, popupId, dispatchAssignTask }) =>
            (suggestion) =>
                dispatchAssignTask({ elementId, popupId, suggestion }),
        onSubmit: (props) => (event) => {
            const { focusedIndex, enabledSuggestions, elementId, popupId, dispatchAssignTask } = props;

            const suggestion = enabledSuggestions[focusedIndex];
            if (!suggestion) return;

            dispatchAssignTask({ elementId, popupId, suggestion });
        },
        onClose: (props) => (event) => {
            const { dispatchClosePopup, popupId } = props;
            return dispatchClosePopup(popupId);
        },
        onDelete: (props) => (event) => {
            const { elementId, searchTerm, popupId, dispatchRemoveTaskAssignments } = props;

            // If the search term isn't empty, let the browser handle the backspace / delete
            if (!isEmpty(searchTerm)) return;

            event.preventDefault();
            event.stopPropagation();

            return dispatchRemoveTaskAssignments({ elementId, popupId });
        },
    }),
    ListKeyboardManager({ listDirection: LIST_DIRECTIONS.VERTICAL }), // eslint-disable-line new-cap
);

const TaskAssignmentPopupContent = (props) => {
    const { dispatchClosePopup, popupId } = props;

    const closePopup = () => {
        dispatchClosePopup(popupId);
    };
    // This is used so the original task editor can have its focus returned to
    useTaskPopupFocus({ ...props, closePopup });

    return (
        <div className="TaskAssignmentPopupContent">
            <div className="search-section">
                <AssignmentSearchForm searchTerm={props.searchTerm} setSearchTerm={props.setSearchTerm} />
            </div>
            <AssignmentSuggestionResults
                currentlyAssignedUserId={props.currentlyAssignedUserId}
                searchTerm={props.searchTerm}
                focusedIndex={props.focusedIndex}
                enabledSuggestions={props.enabledSuggestions}
                disabledSuggestions={props.disabledSuggestions}
                onClickSuggestion={props.onClickSuggestion}
            />
        </div>
    );
};

TaskAssignmentPopupContent.propTypes = {
    popupId: PropTypes.string,
    elementId: PropTypes.string,
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func,
    enabledSuggestions: PropTypes.array,
    disabledSuggestions: PropTypes.array,
    dispatchAssignTask: PropTypes.func,
    dispatchClosePopup: PropTypes.func,
    onClickSuggestion: PropTypes.func,
    focusedIndex: PropTypes.number,
    setFocusIndex: PropTypes.func,
    currentlyAssignedUserId: PropTypes.string,
    dispatchSetFocusToPopupInput: PropTypes.func,
    dispatchRemoveTaskAssignments: PropTypes.func,
};

export default enhance(TaskAssignmentPopupContent);
