export enum ToolbarLevel {
    // The large toolbar that shows at the bottom of the screen
    PRIMARY = 'primary',
    // The smaller toolbar that shows when the virtual keyboard is visible
    SECONDARY = 'secondary',
}

export const MOBILE_TOOLBAR_HEIGHT = {
    [ToolbarLevel.PRIMARY]: 78,
    [ToolbarLevel.SECONDARY]: 56,
};
