import React from 'react';
import Icon from '../../../../../components/icons/Icon';
import { getDueDatePillContent } from '../assignmentUiUtils';
import { getDueReminder } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { REMINDER_VALUES } from './taskDueDateConstants';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

type TaskPillContentsProps = {
    element: ImMNElement;
};

const TaskPillContents = ({ element }: TaskPillContentsProps) => {
    const dueReminder = getDueReminder(element);
    const hasReminder = dueReminder && dueReminder !== REMINDER_VALUES.NONE;

    return (
        <div>
            {hasReminder && <Icon name="reminder-bell-mini" />}
            {getDueDatePillContent(element)}
        </div>
    );
};

export default TaskPillContents;
