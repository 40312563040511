import { useSelector } from 'react-redux';
import { getSheetsState } from '../sheetSelectors';
import { useEffect } from 'react';
import { isEmpty, omit } from 'lodash';
import { SheetReducerState } from '../sheetTypes';
import usePrevious from '../../../../utils/react/usePrevious';

export default (
    sheetKey: string,
    isSheetOpen: boolean,
    snapPoints: number[],
    goToSnapPoint: (snapPoint: number) => void,
) => {
    const sheetsState = useSelector(getSheetsState);
    const prevSheetsState = usePrevious(sheetsState);

    const goToMaxSnapPoint = () => {
        if (isEmpty(snapPoints)) return;

        goToSnapPoint(Math.max(...snapPoints));
    };

    /**
     * When another supporting sheet is opened, expand the current sheet to its max snap point
     */
    useEffect(() => {
        if (!isSheetOpen) return;

        const otherOpenSheets: SheetReducerState = omit(sheetsState, sheetKey);
        const prevOtherOpenSheets: SheetReducerState = omit(prevSheetsState, sheetKey);
        const otherSheetsJustOpened = isEmpty(prevOtherOpenSheets) && !isEmpty(otherOpenSheets);
        if (!otherSheetsJustOpened) return;

        const currentSheetContainsEditor = sheetsState[sheetKey]?.config?.containsElementEditor || false;
        const otherSheetsContainsEditor = Object.values(otherOpenSheets).some(
            (sheet) => sheet?.config?.containsElementEditor,
        );

        if (currentSheetContainsEditor && !otherSheetsContainsEditor) goToMaxSnapPoint();
    }, [isSheetOpen, sheetsState]);

    /**
     * When the keyboard is shown, expand the sheet to its max snap point
     */
    useEffect(() => {
        if (!isSheetOpen) return;

        window.addEventListener('keyboardWillShow', goToMaxSnapPoint);

        return () => {
            window.removeEventListener('keyboardWillShow', goToMaxSnapPoint);
        };
    }, [isSheetOpen]);
};
