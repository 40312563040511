// Constants
import { BoardSections } from '../../common/boards/boardConstants';

export const WORKSPACE_SECTIONS = {
    CANVAS: BoardSections.CANVAS,
    INBOX: BoardSections.INBOX,
    QUICK_NOTES: BoardSections.QUICK_NOTES,
    TRASH_PERSONAL: 'TRASH_PERSONAL',
    TRASH_OTHERS: 'TRASH_OTHERS',
};
