// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity } from 'lodash';
import { createSelector } from 'reselect';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { gridSizeSelector } from '../../utils/grid/gridSizeSelector';
import { getElementId, getIsCollapsed, getThreadId } from '../../../common/elements/utils/elementPropertyUtils';
import { isLocationAttached } from '../../../common/elements/utils/elementLocationUtils';
import {
    getIsPresentationModeEditingDisabled,
    getIsPresentationModeHideCommentsEnabled,
} from '../../workspace/presentation/presentationSelector';

import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';

// Actions
import { commentsAdd } from './store/commentActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';

// Components
import CommentThread from './CommentThread';

const mapDispatchToProps = (dispatch) => ({
    dispatchMoveElementsToTrash: ({ currentBoardId, elementIds }) =>
        dispatch(moveElementsToTrash({ currentBoardId, elementIds })),
    dispatchCommentsAdd: ({ threadId, text }) => dispatch(commentsAdd({ threadId, text })),
});

const mapStateToProps = createSelector(
    gridSizeSelector,
    getIsPresentationModeHideCommentsEnabled,
    getIsPresentationModeEditingDisabled,
    ({ gridSize }, isHideCommentsEnabled, isEditingDisabled) => ({
        gridSize,
        isHideCommentsEnabled,
        isEditingDisabled,
    }),
);

@connect(mapStateToProps, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
class CommentThreadContainer extends React.Component {
    moveElementsToTrash = () => {
        const { currentBoardId, element, dispatchMoveElementsToTrash } = this.props;
        dispatchMoveElementsToTrash({ currentBoardId, elementIds: [getElementId(element)] });
    };

    addComment = (text) => {
        const { dispatchCommentsAdd, element } = this.props;
        const threadId = getThreadId(element);
        dispatchCommentsAdd({ threadId, text });
    };

    startEditing = ({ editorId, editorKey, editorFocusClientCoords }) => {
        const { element, isEditable, dispatchStartEditingElement } = this.props;

        // The comment thread can be edited even when it's remotely selected
        if (!isEditable) return;

        dispatchStartEditingElement({ id: getElementId(element), editorId, editorKey, editorFocusClientCoords });
    };

    render() {
        const {
            connectLineEdgeDropTarget,
            canConnectLineEdge,
            element,
            isHideCommentsEnabled,
            isPresentationModeEnabled,
            isEditingDisabled,
        } = this.props;

        const isCollapsed = getIsCollapsed(element) || isLocationAttached(element);

        const lineEdgeConnector =
            !isCollapsed && connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        const hideComments = isPresentationModeEnabled && isHideCommentsEnabled;
        const hideEditing = isPresentationModeEnabled && isEditingDisabled;

        return lineEdgeConnector(
            <div>
                <CommentThread
                    {...this.props}
                    moveElementsToTrash={this.moveElementsToTrash}
                    startEditing={this.startEditing}
                    addComment={this.addComment}
                    hideComments={hideComments}
                    hideEditing={hideEditing}
                />
            </div>,
        );
    }
}

CommentThreadContainer.propTypes = {
    element: PropTypes.object.isRequired,
    currentBoardId: PropTypes.string.isRequired,
    createCard: PropTypes.func,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    connectDropTarget: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    dispatchCommentsAdd: PropTypes.func,
    isEditable: PropTypes.bool,
    isHideCommentsEnabled: PropTypes.bool,
    isEditingDisabled: PropTypes.bool,
    isPresentationModeEnabled: PropTypes.bool,
    connectLineEdgeDropTarget: PropTypes.func,
    dispatchStartEditingElement: PropTypes.func,
    canConnectLineEdge: PropTypes.bool,
};

export default CommentThreadContainer;
