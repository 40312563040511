// Lib
import React, { ReactElement } from 'react';

// Hooks
import { holdFocusWithoutKeyboard } from '../../../boardsPage/editing/touchScreenFocusUtils';
import useSheet from '../../../structural/sheet/hooks/useSheet';

// Components
import MobileToolbarSheet from '../../../../workspace/toolbar/components/mobileToolbarSheet/MobileToolbarSheet';
import TextStyleRichTextTool from '../../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextTool';
import MobileTextStyleSheetContent from './MobileTextStyleSheetContent';

// Types
import { ImMNCurrentUser, MNCurrentUser } from '../../../../user/currentUserSelector';
import { ImMNElement } from '../../../../../common/elements/elementModelTypes';
import { SheetId } from '../../../structural/sheet/sheetTypes';

// Styles
import './MobileTextStyleRichTextTool.scss';

export type MobileTextStyleRichTextToolProps = {
    currentUser: ImMNCurrentUser | MNCurrentUser;
    currentUserId: string;
    currentBoardId: string;
    gridSize: number;
    gridSizeName: string;
    permissions: number;
    selectedElementIds: Immutable.List<string>;
    selectedElements: Immutable.List<ImMNElement>;
    editorKey: string;
    hasEditorSelection: boolean | undefined;
};

// FIXME-MOBILE - Add a style to the current editor to show the current selection
//  NOTE: Investigate whether it might be easier to do this using vanilla JS, so that we don't
//        accidentally save the selection state to the DB
const MobileTextStyleRichTextTool = (props: MobileTextStyleRichTextToolProps): ReactElement => {
    const sheetProps = useSheet(SheetId.TextStyleRichTextTool);

    const onClickToolButton = () => {
        sheetProps.dispatchOpenSheet();
    };

    return (
        <div className="MobileTextStyleRichTextTool">
            <div onClick={onClickToolButton}>
                <TextStyleRichTextTool isOpen={sheetProps.isSheetOpen} name="Text style" />
            </div>
            <MobileToolbarSheet
                {...sheetProps}
                className="text-style-rich-text-tool"
                onCloseTransitionStart={holdFocusWithoutKeyboard}
            >
                <MobileTextStyleSheetContent {...props} />
            </MobileToolbarSheet>
        </div>
    );
};

export default MobileTextStyleRichTextTool;
