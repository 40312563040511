import { RefObject, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsSheetOpen, getSheetActiveSnapPoint } from '../sheetSelectors';
import { closeSheet, openSheet, updateSheetActiveSnapPoint } from '../sheetActions';

import { SheetId } from '../sheetTypes';

export type UseSheetReturnType = {
    sheetRef: RefObject<HTMLDivElement>;
    sheetContentRef: RefObject<HTMLDivElement>;
    sheetId: SheetId;
    sheetKey: string;
    isSheetOpen: boolean;
    activeSnapPoint: number;
    dispatchOpenSheet: () => void;
    dispatchCloseSheet: () => void;
    dispatchUpdateActiveSnapPoint: (activeSnapPoint: number) => void;
};

export const getSheetKey = (sheetId: SheetId, instanceId?: string) =>
    instanceId ? `${sheetId}-${instanceId}` : sheetId;

const useSheet = (
    sheetId: SheetId,

    // Include instanceId if multiple instances of the same sheet are used (e.g. element-specific sheets)
    instanceId?: string,
): UseSheetReturnType => {
    const sheetKey = getSheetKey(sheetId, instanceId);

    const sheetRef = useRef<HTMLDivElement>(null);
    const sheetContentRef = useRef(null);

    const isSheetOpen = useSelector(getIsSheetOpen(sheetKey));
    const activeSnapPoint = useSelector(getSheetActiveSnapPoint(sheetKey));

    const dispatch = useDispatch();

    const dispatchOpenSheet = () => dispatch(openSheet(sheetId, sheetKey));
    const dispatchCloseSheet = () => dispatch(closeSheet(sheetId, sheetKey));

    const dispatchUpdateActiveSnapPoint = (activeSnapPoint: number) =>
        dispatch(updateSheetActiveSnapPoint(activeSnapPoint, sheetId, sheetKey));

    return {
        sheetRef,
        sheetContentRef,
        sheetId,
        sheetKey,
        isSheetOpen,
        activeSnapPoint,
        dispatchOpenSheet,
        dispatchCloseSheet,
        dispatchUpdateActiveSnapPoint,
    };
};

export default useSheet;
