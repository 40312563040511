import { RefObject } from 'react';
import { getSheetSnapPointWindowHeight } from './sheetHeightUtils';
import { MAX_SUFFIX } from '../hooks/useSheetContextState';
import { isNumber, isString } from 'lodash';

const MAX_DEFAULT_REGEX = /(\d|.)+max$/;

const getDefaultSnapPointDetails = (snapPoint: number | string | undefined): { point?: number; suffix?: string } => {
    if (!snapPoint) return {};

    // Check if it has the max suffix
    if (isString(snapPoint) && snapPoint.match(MAX_DEFAULT_REGEX)) {
        return { point: Number(snapPoint.split(MAX_SUFFIX)[0]), suffix: MAX_SUFFIX };
    }

    if (isNumber(snapPoint)) {
        return { point: snapPoint };
    }

    return {};
};

const getInitialSnapPoint = (
    defaultSnapPoint: number | string | undefined,
    sheetRef: RefObject<HTMLDivElement>,
): number | undefined => {
    const { point, suffix } = getDefaultSnapPointDetails(defaultSnapPoint);
    const defaultSnapIsMax = suffix && point && suffix === MAX_SUFFIX;

    if (point && !defaultSnapIsMax) {
        return point;
    }

    if (!sheetRef.current) return;

    const contentHeightAsDecimal = sheetRef.current.clientHeight / getSheetSnapPointWindowHeight();
    const maxInitialSnapPoint = defaultSnapIsMax ? point : 1;
    return Math.min(maxInitialSnapPoint, contentHeightAsDecimal);
};

const setInitialSheetHeight = (
    sheetRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    goToSnapPoint: (snapPoint: number) => void,
    setSheetInitialised: (sheetInitialised: boolean) => void,
    cancelInProgressAnimations: () => void,
    sheetContentRef: RefObject<HTMLDivElement>,
    onOpenTransitionEnd = () => {},
) => {
    if (!sheetRef.current || !sheetContentRef.current) return;

    sheetContentRef.current.style.height = 'auto';
    sheetRef.current.style.height = 'auto';

    requestAnimationFrame(() => {
        if (!sheetRef.current || !sheetContentRef.current) return;

        cancelInProgressAnimations();

        const initialSnapPoint = getInitialSnapPoint(defaultSnapPoint, sheetRef);
        if (!initialSnapPoint) return;

        sheetRef.current.style.height = '100%';
        addSnapPoint(initialSnapPoint);
        goToSnapPoint(initialSnapPoint);
        setSheetInitialised(true);

        sheetRef.current.addEventListener('transitionend', onOpenTransitionEnd, { once: true });
    });
};

export default setInitialSheetHeight;
