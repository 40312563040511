// Utils
import { getScrollAsPoint } from '../../utils/dom/scrollableParentUtils';
import { getElementDOMMeasurement } from '../../utils/domUtil';

/**
 * Keeps track of the current measured elements & the current canvas dom element.
 *
 * This is used by the measureElementDecorator to determine when to fire the measurementsRemove action,
 * as well as many places to find the Canvas DOM element or Canvas scroll position.
 *
 * It also keeps a map of each measureElementDecorator, so that they can be forced to re-measure when
 * required. This is useful when a column changes size, we can force all its children to get re-measured,
 * thus keeping the measurements accurate.
 */
class MeasurementsRegistry {
    constructor() {
        this.elementReferenceCount = {};
        this.measureElementDecorators = {};
    }

    // ==== ELEMENTS ====

    // This is required to ensure that measurement removal events are not fired prematurely
    registerElement = (id, measureElementDecorator) => {
        this.elementReferenceCount[id] = this.elementReferenceCount[id] || 0;
        this.elementReferenceCount[id]++;

        this.measureElementDecorators[id] = measureElementDecorator;
    };

    removeElement = (id, measureElementDecorator) => {
        this.elementReferenceCount[id]--;

        if (measureElementDecorator === this.measureElementDecorators[id]) {
            this.measureElementDecorators[id] = null;
        }
    };

    getElementReferenceCount = (id) => this.elementReferenceCount[id] || 0;

    /**
     * Forces the element to be re-measured.
     * The react-measure component only measures when the dimensions of an element changes,
     * not when their position (top / left) changes.
     * We're interested in the position changes too, so this allows us to force a re-measure
     * when we think the position might have changed.
     */
    forceElementMeasure = (id) => {
        const decorator = this.measureElementDecorators[id];

        if (!decorator || !decorator.forceMeasure) return;

        decorator.forceMeasure();
    };

    updateElementCachedMeasurements = (id, measurements) => {
        const decorator = this.measureElementDecorators[id];

        if (!decorator || !decorator.updateCachedMeasurements) return;

        decorator.updateCachedMeasurements(measurements);
    };

    // ==== CANVAS ====

    registerCanvasViewport = (canvas) => {
        this.canvasViewport = canvas;
    };

    removeCanvasViewport = () => {
        this.canvasViewport = null;
    };

    /**
     * Retrieves the measurement of an element.
     *
     * NOTE: This will not update the measurement Redux store
     */
    getElementMeasurementFromCanvasDocument = (id) => {
        const decorator = this.measureElementDecorators[id];
        const elementBoundingRect = getElementDOMMeasurement(id);

        if (!decorator || !elementBoundingRect) return;

        return decorator.getElementMeasurementFromCanvasDocument(elementBoundingRect.toJSON());
    };

    getCanvasViewportDomElement = () => this.canvasViewport;

    /**
     * Retrieves the canvas DOM element's scroll as a point { x, y }.
     */
    getCanvasViewportScrollAsPoint = () => getScrollAsPoint(this.canvasViewport);

    /**
     * Retrieves the translation required to get DOM measurements into the canvas's pixels.
     */
    getCanvasViewportTranslation = () => {
        const canvasRect = this.canvasViewport && this.canvasViewport.getBoundingClientRect();
        return {
            y: (this.canvasViewport && this.canvasViewport.scrollTop - canvasRect.top) || 0,
            x: (this.canvasViewport && this.canvasViewport.scrollLeft - canvasRect.left) || 0,
        };
    };
}

/**
 * Initialises an instance, for simplicity, because it's being used on the client side.
 */
const measurementsRegistry = new MeasurementsRegistry();
export default measurementsRegistry;
