export const BOARD_ID_TRASH_APPEND = '-trash';

export enum BoardSections {
    CANVAS = 'CANVAS',
    INBOX = 'INBOX',
    TRASH = 'TRASH',
    DELETED = 'DELETED',
    ATTACHED = 'ATTACHED',
    QUICK_NOTES = 'QUICK_NOTES',
    // FIXME-FAVOURITES-SIDEBAR - Remove when not needed
    FAVOURITES = 'Favourites',
}

export enum InboxState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum RootBoardTypes {
    WORKSPACE = 'WORKSPACE',
    TEMPLATES = 'TEMPLATES',
    QUICK_NOTES = 'QUICK_NOTES',
    // FIXME-FAVOURITES-SIDEBAR - Remove when not needed
    SHARED = 'SHARED',
    ARCHIVE = 'ARCHIVE',
    FAVOURITES = 'FAVOURITES',
}

export enum RootBoardTitles {
    WORKSPACE = 'Home',
    TEMPLATES = 'Templates',
    QUICK_NOTES = 'Quick notes',
    // FIXME-FAVOURITES-SIDEBAR - Remove when not needed
    SHARED = 'Shared With you',
    ARCHIVE = 'Archive',
    FAVOURITES = 'Favourites',
}

export const BOARD_DISPLAY_NAME = 'Board';
export const BOARD_DEFAULT_TITLE = 'New Board';

export const BOARD_CANVAS_ORDER_LOAD = 'BOARD_CANVAS_ORDER_LOAD';

export const CURRENT_BOARD_HEADER_TITLE_EDITOR_KEY = 'CURRENT_BOARD_TITLE';
