import { Capacitor } from '@capacitor/core';
import { generateJSON } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { Link } from '../../../common/tiptap/extensions/hyperlink/Link';

import { createElementAsync } from '../../element/actions/elementActions';
import { setLinkElementUrl } from '../../element/link/linkActions';
import { navigateToUrl } from '../../reducers/navigationActions';

import platformSingleton from '../../platform/platformSingleton';
import { ApplicationType } from '../../../common/platform/platformTypes';

import { openPopup } from '../../components/popupPanel/popupActions';
import { PopupIds } from '../../components/popupPanel/popupConstants';
import quickNotesPopupStayActivePredicate from '../../workspace/header/workspaceToolsHeader/quickNotes/popup/quickNotesPopupStayActivePredicate';

import { getLinkifyMatchesFromText, isOnlyLinkText } from '../../../common/utils/getLinksFromText';
import { getFileContentType } from '../../../common/files/fileTypeInferenceUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getFileName } from '../../../common/utils/getFileName';

import { ElementType } from '../../../common/elements/elementTypes';
import { TiptapContent } from '../../../common/tiptap/tiptapTypes';
import { BoardSections } from '../../../common/boards/boardConstants';
import { setElementLocalData } from '../../element/local/elementLocalDataActions';
import { manuallyReportError } from '../../analytics/rollbarService';
import { ROLLBAR_LEVELS } from '../../analytics/rollbarConstants';
import { getLocationPathname } from '../../app/routingSelector';
import { getCurrentBoardIdFromState } from '../../reducers/currentBoardId/currentBoardIdSelector';

const NEW_IMAGE_FILE_NAME = 'image';
const NEW_VIDEO_FILE_NAME = 'video';
const NEW_FILE_FILE_NAME = 'file';

const convertTextToTiptapContent = (textContent = ''): TiptapContent =>
    generateJSON(textContent, [Document, Paragraph, Text, Link]) as TiptapContent;

const convertSharedTextToHTML = (text: string) => {
    return text
        .split('\n')
        .map((line) => {
            let updatedLine = line;

            getLinkifyMatchesFromText(line).forEach((match) => {
                updatedLine = updatedLine.replace(match.text, `<a href="${match.url}">${match.text}</a>`);
            });

            return updatedLine;
        })
        .map((line) => `<p>${line}</p>`)
        .join('');
};

export const navigateToMobileQuickNotes = () => (dispatch: Function, getState: Function) => {
    const state = getState();
    const pathname = getLocationPathname(state);

    if (pathname !== '/mobile/quick-notes') {
        dispatch(navigateToUrl('/mobile/quick-notes'));
        return;
    }

    // If already in quick notes page, scroll to top as shared elements will be added to the top of the list
    document.getElementsByClassName('MobilePageBody').item(0)?.scrollTo(0, 0);
};

// TODO-MINI-APP: Change to quick notes once it is implemented
const getSharedElementLocation = (state: any) => ({
    parentId: getCurrentBoardIdFromState(state),
    section: BoardSections.INBOX,
    position: { index: 0 },
});

/**
 * This is used by the Android Share Extension to create an Card/Link element from the URI provided.
 */
export const createCardElementForShareExtension = (text: string) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const transactionId = getNewTransactionId();

    if (platformSingleton.appType == ApplicationType.androidCapacitor) dispatch(navigateToMobileQuickNotes());
    if (platformSingleton.appType == ApplicationType.androidTabletCapacitor)
        dispatch(openPopup(PopupIds.QUICK_NOTES, quickNotesPopupStayActivePredicate));

    if (isOnlyLinkText(text)) {
        const location = getSharedElementLocation(state);
        const elementId = await dispatch(
            createElementAsync({
                elementType: ElementType.LINK_TYPE,
                location,
                content: { url: text },
                transactionId,
            }),
        );

        dispatch(setElementLocalData({ id: elementId, data: { url: text } }));
        dispatch(setLinkElementUrl({ id: elementId, url: text, transactionId }));
        return;
    }

    const html = convertSharedTextToHTML(text);
    const textContent = convertTextToTiptapContent(html);

    const location = getSharedElementLocation(state);

    dispatch(
        createElementAsync({
            elementType: ElementType.CARD_TYPE,
            location,
            content: { textContent },
            transactionId,
        }),
    );
};

/**
 * This is used by the Android Share Extension to create an Image element from the URI provided.
 */
export const createImageElementForShareExtension = (uri: string, fileName?: string) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const transactionId = getNewTransactionId();

    try {
        const webUri = Capacitor.convertFileSrc(uri);

        // Fetch the image for which the URI has been provided.
        const result = await fetch(webUri);
        const blob = await result.blob();

        // Set up a temporary placeholder File, in order to determine what type of image it is.
        const _tempFile = new File([blob], NEW_IMAGE_FILE_NAME);
        const fileContentType = await getFileContentType(_tempFile);

        // Create the final File's name, and create the File to be attached the Image element to create.
        const finalFileName = fileName ? fileName : getFileName(_tempFile, fileContentType);
        const file = new File([blob], finalFileName);

        const location = getSharedElementLocation(state);

        dispatch(
            createElementAsync({
                attachment: file,
                elementType: ElementType.IMAGE_TYPE,
                location,
                transactionId,
            }),
        );
    } catch (error) {
        const errorMessage = 'Unable to create and start uploading shared image.';
        console.error(errorMessage, error);
        manuallyReportError({ errorMessage, error, sensitive: false, level: ROLLBAR_LEVELS.ERROR });
    }
};

export const createVideoElementForShareExtension = (uri: string, fileName?: string) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const transactionId = getNewTransactionId();

    try {
        const webUri = Capacitor.convertFileSrc(uri);

        // Fetch the image for which the URI has been provided.
        const result = await fetch(webUri);
        const blob = await result.blob();

        // Create the final File's name, and create the File to be attached the Image element to create.
        const finalFileName = fileName ? fileName : NEW_VIDEO_FILE_NAME;
        const file = new File([blob], finalFileName);

        const location = getSharedElementLocation(state);

        dispatch(
            createElementAsync({
                attachment: file,
                elementType: ElementType.FILE_TYPE,
                location,
                transactionId,
            }),
        );
    } catch (error) {
        const errorMessage = 'Unable to create and start uploading shared video.';
        console.error(errorMessage, error);
        manuallyReportError({ errorMessage, error, sensitive: false, level: ROLLBAR_LEVELS.ERROR });
    }
};

export const createFileElementForShareExtension = (uri: string, receivedMimeType?: string, fileName?: string) => async (dispatch: Function, getState: Function) => {
    const state = getState();
    const transactionId = getNewTransactionId();

    try {
        const webUri = Capacitor.convertFileSrc(uri);

        // Fetch the image for which the URI has been provided.
        const result = await fetch(webUri);
        const blob = await result.blob();

        // Create the final File's name, and create the File to be attached the Image element to create.
        const finalFileName = fileName ? fileName : NEW_FILE_FILE_NAME;
        const file = new File([blob], finalFileName);

        const location = getSharedElementLocation(state);

        dispatch(
            createElementAsync({
                attachment: file,
                elementType: ElementType.FILE_TYPE,
                location,
                transactionId,
            }),
        );
    } catch (error) {
        const errorMessage = 'Unable to create and start uploading shared file.';
        console.error(errorMessage, error);
        manuallyReportError({ errorMessage, error, sensitive: false, level: ROLLBAR_LEVELS.ERROR });
    }
};
