import { RefObject } from 'react';
import { isNil } from 'lodash';
import { DragState } from '../hooks/useSheetHandlers';
import safeAreaSingleton from '../../../utils/safeAreaSingleton';

// Instead of animating to 0, animate a bit lower to allow for the shadow to naturally fade out
export const CLOSE_SHEET_TRANSFORM_POS = -15;

/**
 * Get the height of the area that the sheet can be snapped to.
 * Note that this value is used for calculating the snap height, but the sheet can be dragged past this point.
 */
export const getSheetSnapPointWindowHeight = () => window.innerHeight - safeAreaSingleton.getSafeArea().top;

export const getSnapPointSheetHeight = (snapPoint: number): number => snapPoint * getSheetSnapPointWindowHeight();

/**
 * Set the height of the sheet. Returns true if the height was changed.
 */
export const setSheetHeight = (sheetRef: RefObject<HTMLDivElement>, height: number | undefined): boolean => {
    if (!sheetRef.current || isNil(height)) return false;

    const transformStyle = `translateY(${-height}px)`;
    if (sheetRef.current.style.transform === transformStyle) return false;

    sheetRef.current.style.transform = transformStyle;
    return true;
};

const getNewSheetTop = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    if (!dragState.current) return;

    const { currentY, sheetTouchOffset } = dragState.current;

    const newSheetTop = currentY - sheetTouchOffset;
    // if the sheet has gone past the top snap point, only use half the distance between finger and highest snap point
    const slowDragAdjustment = newSheetTop < highestSnapPosition ? (highestSnapPosition - newSheetTop) / 2 : 0;
    const intendedSheetTop = newSheetTop + slowDragAdjustment;
    return Math.max(intendedSheetTop, 0);
};

export const getNewSheetHeight = (dragState: RefObject<DragState>, highestSnapPosition: number): number | undefined => {
    const newSheetTop = getNewSheetTop(dragState, highestSnapPosition);
    if (isNil(newSheetTop)) return;

    return window.innerHeight - newSheetTop;
};

/*
 * Get the sheet height for a given snap point, with an adjustment that puts it a bit lower if the sheet is closing
 * This is to nicely animate the shadow out
 */
export const getSheetHeightAdjustedForClose = (snapPoint: number) =>
    snapPoint === 0 ? CLOSE_SHEET_TRANSFORM_POS : getSnapPointSheetHeight(snapPoint);

export const setContentHeightToMax = (sheetContentRef: RefObject<HTMLDivElement>): void => {
    if (!sheetContentRef.current) return;

    sheetContentRef.current.style.height = '100%';
};

export const setContentHeightToVisibleSpace = (sheetContentRef: RefObject<HTMLDivElement>): void => {
    if (!sheetContentRef.current || !window.visualViewport) return;

    const newContentHeight = window.innerHeight - sheetContentRef.current?.getBoundingClientRect().top;
    sheetContentRef.current.style.height = `calc(${newContentHeight}px - var(--current-keyboard-height))`;
};
