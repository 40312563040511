/**
 * Returns the next position and velocity of a spring-damper system, given the current position and velocity.
 * @returns [nextPosition, nextVelocity, progress]
 * */
export const getInterpolatedPosition = (
    frameDelta: number,
    currentPosition: number,
    currentVelocity: number,
    targetPosition: number,
    stiffness: number,
    damping: number,
    precision = 0.1,
): [number, number] => {
    const springForce = stiffness * (targetPosition - currentPosition);
    const dampingForce = damping * currentVelocity;
    const acceleration = springForce - dampingForce;

    const nextVelocity = currentVelocity + acceleration * frameDelta;
    const nextPosition = currentPosition + nextVelocity * frameDelta;

    // if we are within the precision threshold, just snap to the target position
    if (Math.abs(nextVelocity) < precision && Math.abs(targetPosition - nextPosition) < precision) {
        return [targetPosition, 0];
    }

    return [nextPosition, nextVelocity];
};
