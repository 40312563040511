// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withHandlers } from '../../../../../../../node_module_clones/recompose';

// Utils
import { getElementId } from '../../../../../../../common/elements/utils/elementPropertyUtils';

// Actions
import { findIcons, setIconPopupMode } from './iconsPopupActions';

// Components
import IconList from './tabContent/iconList/IconList';
import IconTabContentImagePicker from './tabContent/imagePicker/IconTabContentImagePicker';

import PopupModeMenu from '../../../../../../components/popupPanel/types/modeMenu/PopupModeMenu';
import PopupModeContent from '../../../../../../components/popupPanel/types/modeContent/PopupModeContent';

// Constants
import { IconUiCategory } from '../iconToolTypes';

import iconPopupSelector from './iconPopupSelector';

import './IconPopup.scss';

const popupModeHasChanged = (props, nextProps) => nextProps.popupMode !== props.popupMode;
const popupModeIsImages = (props) => props.popupMode === IconUiCategory.IMAGES;
const popupBecameActive = (props, nextProps) => nextProps.isActive && !props.isActive;

const shouldSearchForIcons = (props, nextProps) =>
    (popupModeHasChanged(props, nextProps) || popupBecameActive(props, nextProps)) &&
    !popupModeIsImages(nextProps) &&
    !nextProps.iconList;

const mapDispatchToProps = (dispatch) => ({
    searchForIcons: ({ query, id }) => dispatch(findIcons({ query, id })),
    setMode: ({ mode, id }) => {
        if (!mode) return;
        dispatch(setIconPopupMode({ mode, id }));
    },
});

@connect(iconPopupSelector, mapDispatchToProps)
@withHandlers({
    submitSearch:
        ({ setMode, selectedElements }) =>
        (query) => {
            setMode({ mode: query, id: selectedElements && getElementId(selectedElements.first()) });
        },
    setModeDefault:
        ({ setMode, selectedElements }) =>
        (event) => {
            event && event.preventDefault();
            setMode({ mode: IconUiCategory.DEFAULT });
        },
})
class IconPopup extends React.Component {
    componentWillMount() {
        const { searchForIcons, popupQuery, selectedElements, iconList } = this.props;

        if (!iconList?.length) {
            searchForIcons({
                query: popupQuery,
                id: getElementId(selectedElements.first()),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { searchForIcons } = this.props;

        if (!nextProps.isActive) return;

        // Don't search for icons if the popup mode is images
        if (shouldSearchForIcons(this.props, nextProps)) {
            searchForIcons({
                query: nextProps.popupQuery,
                id: getElementId(nextProps.selectedElements.first()),
            });
        }
    }

    render() {
        return (
            <div className="icon-popup-content">
                <PopupModeMenu {...this.props} showSearch searchPlaceholder="Search icons..." />
                <PopupModeContent>
                    {popupModeIsImages(this.props) ? (
                        <IconTabContentImagePicker {...this.props} />
                    ) : (
                        <IconList {...this.props} />
                    )}
                </PopupModeContent>
            </div>
        );
    }
}

IconPopup.propTypes = {
    gridSize: PropTypes.number.isRequired,
    isActive: PropTypes.bool,
    selectedElements: PropTypes.object,
    iconList: PropTypes.object,
    popupMode: PropTypes.string,
    modeList: PropTypes.array,
    popupQuery: PropTypes.string,
    setMode: PropTypes.func,
    setModeDefault: PropTypes.func,
    submitQuery: PropTypes.func,
    searchForIcons: PropTypes.func,
};

export default IconPopup;
