import { useDispatch } from 'react-redux';
import { removeElementDueDate, setElementDueDate } from './taskDueDateActions';
import { startEditingElement } from '../../../../selection/selectionActions';
import { MNElement } from '../../../../../../common/elements/elementModelTypes';
import { getElement } from '../../../../selectors/elementSelector';
import { getShowDueDate } from '../../../../../../common/elements/utils/elementPropertyUtils';

type SetDueDateIfNotRemovedArgs = {
    elementId: string;
    dueDate: Date | number | string;
    hasDueDateTime: boolean;
    dueReminder: number;
    initialElement: MNElement;
};
const setDueDateIfNotRemoved =
    ({ elementId, dueDate, hasDueDateTime, dueReminder, initialElement }: SetDueDateIfNotRemovedArgs) =>
    (dispatch: Function, getState: Function) => {
        if (!dueDate) return;

        const state = getState();

        const element = getElement(state, { elementId });

        // The due date must have been toggled off, so ignore this update
        if (!getShowDueDate(element)) return;

        dispatch(setElementDueDate({ taskId: elementId, dueDate, hasDueDateTime, dueReminder, initialElement }));
    };

const useTaskDueDateDispatchFunctions = (props: { elementId: string; closePopup: () => void }) => {
    const { elementId, closePopup } = props;

    const dispatch = useDispatch();
    const dispatchSetDueDate = (args: SetDueDateIfNotRemovedArgs) => dispatch(setDueDateIfNotRemoved(args));

    const dispatchSetTemporaryDueDate = (
        elementId: string,
        dueDate: Date | number | string,
        hasDueDateTime: boolean,
        dueReminder: number,
    ) => {
        const dueDateTimestamp = new Date(dueDate).toISOString();
        dispatch(
            setElementDueDate({
                taskId: elementId,
                dueDate: dueDateTimestamp,
                hasDueDateTime,
                dueReminder,
                sync: false,
            }),
        );
    };

    const dispatchSetFocusToPopupInput = ({ elementId }: { elementId: string }) => {
        dispatch(
            startEditingElement({
                id: elementId,
                editorId: `${elementId}-due-date`,
                editorKey: 'TASK',
                preventVirtualKeyboard: true,
            }),
        );
    };

    const onDelete = (event: KeyboardEvent) => {
        event.stopPropagation();
        dispatch(removeElementDueDate({ id: elementId }));
        closePopup?.();
    };

    return {
        dispatchSetDueDate,
        dispatchSetTemporaryDueDate,
        dispatchSetFocusToPopupInput,
        onDelete,
    };
};

export default useTaskDueDateDispatchFunctions;
