// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformIpad } from '../../platform/utils/platformDetailsUtils';
import { focusFakeInputFromMouseEvent } from '../../mobile/boardsPage/editing/touchScreenFocusUtils';

/**
 * Decorator that prevents double click events from being triggered if a drag event occurred
 * during the double click. This is used specifically to determine whether to allow
 * element creation on double click.
 */
export default (DecoratedComponent) => {
    class allowDoubleClickCreateShortcutDecorator extends React.Component {
        constructor(props) {
            super(props);

            this.allowDoubleClickCreate = true;
            this.mouseDownTimestamp = null;
            this.mouseDownPos = null;

            this.mouseUpTimestamp = 0;
        }

        onMouseDown = (event) => {
            this.mouseDownTimestamp = Date.now();
            this.mouseDownPos = {
                x: event.pageX,
                y: event.pageY,
            };
        };

        // NOTE: While the double tap code is specifically used for iPads, strangely using pointer
        //  events here doesn't correctly set the focus for virtual keyboard either, so we need to
        //  use mouse events.
        onMouseUp = (event) => {
            // If we don't have a mouse down, for whatever reason, don't check if it was a drag
            if (!this.mouseDownTimestamp || !this.mouseDownPos) return;

            // Don't allow double click to create element if target is an element that does not allow double click creation
            if (event.target.closest('.Element:not(:has([data-allow-double-click-creates]))') !== null) return;

            const currentTimestamp = Date.now();
            const pressTime = currentTimestamp - this.mouseDownTimestamp;

            this.allowDoubleClickCreate =
                pressTime < 120 || (this.mouseDownPos.x === event.pageX && this.mouseDownPos.y === event.pageY);

            // HACK - Similar to in the DraggableElementCreationTool, we need to focus a fake input field
            //  synchronously to the *mouse* event in order to then asynchronously transfer focus over to the
            //  new element on double click creation.
            //  Strangely, however, we need to focus the first mouseup in the double click in order to get
            //  the focus and virtual keyboard to show on the second.
            //  This is not necessary on mobile, and it actually interrupts the focus while creating a new card
            const isDoubleTap = !!this.mouseUpTimestamp && currentTimestamp - this.mouseUpTimestamp < 300;

            if (isPlatformIpad(platformSingleton) && this.allowDoubleClickCreate) {
                focusFakeInputFromMouseEvent(event, {
                    readOnly: !isDoubleTap,
                });
            }

            this.mouseUpTimestamp = currentTimestamp;
            this.mouseDownTimestamp = null;
            this.mouseDownPos = null;
        };

        onDoubleClick = (event) => {
            if (!this.allowDoubleClickCreate) {
                this.allowDoubleClickCreate = true;
                return;
            }

            this.allowDoubleClickCreate = true;

            const { onDoubleClick } = this.props;
            onDoubleClick && onDoubleClick(event);
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                    onDoubleClick={this.onDoubleClick}
                />
            );
        }
    }

    allowDoubleClickCreateShortcutDecorator.propTypes = {
        onDoubleClick: PropTypes.func,
    };

    return allowDoubleClickCreateShortcutDecorator;
};
