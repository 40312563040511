// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { identity } from 'lodash';
import { getEmptyImage } from 'react-dnd-html5-backend';

// Styles
import './DragHandle.scss';

const HALF_BORDER_WIDTH = 0.25;

const getHandleStyle = ({ pos: { x, y } }) => ({
    left: x,
    top: y,
});

const DragHandle = (props) => {
    const {
        show,
        className,
        style,
        connectDragSource,
        connectDragPreview,
        isHitArea,
        handleMode,
        onMouseDown,
        onDoubleClick,
        domElementId,
    } = props;

    React.useEffect(() => {
        connectDragPreview && connectDragPreview(getEmptyImage());
    }, []);

    if (!show) return null;

    const positionStyle = props.pos ? getHandleStyle(props) : null;

    const handleStyles = {
        ...positionStyle,
        ...style,
    };

    const radius = global.lines.handleSize / 2;

    const wrapperFn = connectDragSource || identity;
    return wrapperFn(
        <div
            className={classNames('DragHandle', className, handleMode, { 'hit-area': isHitArea })}
            onMouseDown={onMouseDown}
            onDoubleClick={onDoubleClick}
            style={handleStyles}
            id={domElementId}
        >
            <svg
                className="handle"
                version="1.1"
                width={global.lines.handleSize}
                height={global.lines.handleSize}
                viewBox={`0 0 ${global.lines.handleSize} ${global.lines.handleSize}`}
            >
                <circle cx={radius} cy={radius} r={radius - HALF_BORDER_WIDTH} />
            </svg>
        </div>,
    );
};

DragHandle.propTypes = {
    show: PropTypes.bool,
    pos: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    isHitArea: PropTypes.bool,
    onMouseDown: PropTypes.func,
    handleMode: PropTypes.string,
    domElementId: PropTypes.string,

    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    onDoubleClick: PropTypes.func,
};

export default DragHandle;
