import makeStringIcon from '../../../../../../components/elementIcon/makeStringIconDef';

export const ICON_POPUP_MODE_SET = 'ICON_POPUP_STATE_SET';
export const ICON_POPUP_SEARCH_SUBMIT = 'ICON_POPUP_SEARCH_SUBMIT';
export const ICON_POPUP_SEARCH_SUCCESS = 'ICON_POPUP_SEARCH_SUCCESS';
export const ICON_POPUP_SEARCH_FAILURE = 'ICON_POPUP_SEARCH_FAILURE';

export const ICON_POPUP_MODE_LIST_MAX = 5;
export const ICON_POPUP_WORD_FILTERS = [
    'a',
    'an',
    'and',
    'as',
    'at',
    'but',
    'by',
    'for',
    'in',
    'into',
    'is',
    'it',
    'its',
    'nor',
    'of',
    'onto',
    'or',
    'so',
    'than',
    'that',
    'the',
    'then',
    'to',
    'too',
    'until',
    'with',
    'yet',
    'my',
    'i',
    'his',
    'her',
    'their',
    'theres',
    '/',
    '&',
    '!',
    '@',
    '#',
    '&',
];

export const ICON_POPUP_NUMBERS_LIST = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

export const ICON_POPUP_LETTERS_LIST = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '!',
    '#',
    '$',
    '%',
    '&',
    '=',
    '~',
];

export const NUMBER_ICONS = ICON_POPUP_NUMBERS_LIST.map((number) => makeStringIcon(number));
export const STRING_ICONS = ICON_POPUP_LETTERS_LIST.map((letter) => makeStringIcon(letter, 'strdefault'));
