// Lib
import { clamp } from 'lodash/fp';

// Utils
import * as pointsLib from '../../../common/maths/geometry/rect';
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';

export type ViewportOptions = {
    excludeScrollbars?: boolean;
    insetMargins?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
};

/**
 * Will get the viewport rectangle of a scrollable element (the coordinates that are currently visible).
 * Scrollbars can optionally be included or excluded.
 *
 * NOTE: This will give coordinates from the start of the scrollable element.
 *      E.g. If the element hasn't been scrolled at all, top and left will be 0,0.
 */
export const getViewportRect = (
    scrollableParentNode: HTMLElement,
    {
        excludeScrollbars = true,
        // Margins to inset the viewport by.
        insetMargins,
    }: ViewportOptions = {},
): Rect | null => {
    // Just shortening the variable name
    const domNode = scrollableParentNode;

    if (!domNode) return null;

    const boundingRect = domNode.getBoundingClientRect();
    const {
        top: viewportTopInset = 0,
        right: viewportRightInset = 0,
        bottom: viewportBottomInset = 0,
        left: viewportLeftInset = 0,
    } = insetMargins || {};

    const verticalScrollbarWidth = (excludeScrollbars && domNode.offsetWidth - domNode.clientWidth) || 0;
    const horizontalScrollbarWidth = (excludeScrollbars && domNode.offsetHeight - domNode.clientHeight) || 0;

    const width = boundingRect.width - verticalScrollbarWidth - viewportLeftInset - viewportRightInset;
    const height = boundingRect.height - horizontalScrollbarWidth - viewportTopInset - viewportBottomInset;

    return {
        x: domNode.scrollLeft,
        y: domNode.scrollTop,
        top: domNode.scrollTop + viewportTopInset,
        left: domNode.scrollLeft + viewportLeftInset,
        bottom: domNode.scrollTop + viewportTopInset + height,
        right: domNode.scrollLeft + viewportLeftInset + width,

        width,
        height,
    };
};

/**
 * Translates a DOM rectangle into the same coordinate system as the scrollable element.
 * The DOMRect is from the window's top left, this will translate it to the scrollable element's coordinates.
 */
export const translateDOMRectIntoScrollableParentCoordinates = (scrollableParent: HTMLElement, rectangle: Rect) => {
    const scrollableParentRect = scrollableParent.getBoundingClientRect();

    // Translate to "scrollable area" coordinates (either the canvas or inbox).
    // I.e. top left will be 0,0
    const scrollableAreaTranslation = {
        // Current x scroll - current left
        x: scrollableParent.scrollLeft - scrollableParentRect.left,
        // Current y scroll - current top
        y: scrollableParent.scrollTop - scrollableParentRect.top,
    };

    return pointsLib.translate(scrollableAreaTranslation, rectangle);
};

export const canScrollX = (scrollableParent: HTMLElement) =>
    scrollableParent.scrollWidth !== scrollableParent.clientWidth;
export const canScrollY = (scrollableParent: HTMLElement) =>
    scrollableParent.scrollHeight !== scrollableParent.clientHeight;
export const canScroll = (scrollableParent: HTMLElement) =>
    canScrollX(scrollableParent) || canScrollY(scrollableParent);

// Can only scroll left as far as we've already scrolled right
export const getScrollXMin = (scrollableParent: HTMLElement) => -scrollableParent.scrollLeft;
export const getScrollXMax = (scrollableParent: HTMLElement) =>
    scrollableParent.scrollWidth - scrollableParent.scrollLeft;
// Can only scroll up as much as the scrollable element is scrolled down
export const getScrollYMin = (scrollableParent: HTMLElement) => -scrollableParent.scrollTop;
export const getScrollYMax = (scrollableParent: HTMLElement) =>
    scrollableParent.scrollHeight - scrollableParent.scrollTop;

/**
 * How far needs to be scrolled to make the left edge of the target touch the left edge of the viewport.
 */
const getDistToScrollToEdge =
    (edge: keyof Rect) =>
    ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }) =>
        targetRect[edge] - viewportRect[edge];
export const getDistToScrollToLeftEdge = getDistToScrollToEdge('left');
const getDistToScrollToRightEdge = getDistToScrollToEdge('right');
const getDistToScrollToTopEdge = getDistToScrollToEdge('top');
const getDistToScrollToBottomEdge = getDistToScrollToEdge('bottom');

// Determine if the target is outside of the viewport
const getIsOverflowingLeft = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    viewportRect.left > targetRect.left;
const getIsOverflowingRight = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    viewportRect.right < targetRect.right;
const getIsOverflowingTop = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    viewportRect.top > targetRect.top;
const getIsOverflowingBottom = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    viewportRect.bottom < targetRect.bottom;

// Determine if the target is already at the extremities of the viewport
const targetIsOnViewportLeftEdge = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    getDistToScrollToLeftEdge({ viewportRect, targetRect }) === 0;
const targetIsOnViewportTopEdge = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    getDistToScrollToTopEdge({ viewportRect, targetRect }) === 0;

/**
 * If the target is overflowing the viewport to the left or right, we should scroll.
 */
const shouldScrollX = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    getIsOverflowingLeft({ viewportRect, targetRect }) ||
    (getIsOverflowingRight({ viewportRect, targetRect }) &&
        // If the target is already exactly on the viewport left edge, we shouldn't scroll right
        !targetIsOnViewportLeftEdge({ viewportRect, targetRect }));

/**
 * If the target is overflowing the viewport to the left or right, we should scroll.
 */
const shouldScrollY = ({ viewportRect, targetRect }: { viewportRect: Rect; targetRect: Rect }): boolean =>
    getIsOverflowingTop({ viewportRect, targetRect }) ||
    (getIsOverflowingBottom({ viewportRect, targetRect }) &&
        // If the target is already exactly on the viewport left edge, we shouldn't scroll right
        !targetIsOnViewportTopEdge({ viewportRect, targetRect }));

/**
 * Determines whether scrolling should occur for a target rectangle within a scrollable element.
 */
export const shouldScroll = (
    scrollableParent: HTMLElement,
    targetRect: Rect,
    viewportOptions?: ViewportOptions,
): boolean => {
    const viewportRect = getViewportRect(scrollableParent, viewportOptions);
    if (!viewportRect) return false;

    return (
        (canScrollX(scrollableParent) && shouldScrollX({ viewportRect, targetRect })) ||
        (canScrollY(scrollableParent) && shouldScrollY({ viewportRect, targetRect }))
    );
};

const getScrollLeft = ({
    scrollableParent,
    viewportRect,
    targetRect,
}: {
    scrollableParent: HTMLElement;
    viewportRect: Rect;
    targetRect: Rect;
}): number => {
    const scrollRange = clamp(getScrollXMin(scrollableParent), getScrollXMax(scrollableParent));

    const scrollLeftX = getDistToScrollToLeftEdge({ viewportRect, targetRect });
    const scrollRightX = getDistToScrollToRightEdge({ viewportRect, targetRect });

    // Don't need to scroll X if the target is already within the X range
    if (scrollLeftX >= 0 && scrollRightX <= 0) return scrollableParent.scrollLeft;

    const scrollDiffX = scrollRange(
        Math.min(
            scrollLeftX,
            // If we're scrolling left, don't attempt to scroll to the right edge
            scrollLeftX < 0 ? Infinity : scrollRightX,
        ),
    );

    // Math.min will ensure the left edge is favoured if it won't fit in the viewport
    return scrollableParent.scrollLeft + scrollDiffX;
};

const getScrollTop = ({
    scrollableParent,
    viewportRect,
    targetRect,
}: {
    scrollableParent: HTMLElement;
    viewportRect: Rect;
    targetRect: Rect;
}): number => {
    const scrollRange = clamp(getScrollYMin(scrollableParent), getScrollYMax(scrollableParent));

    const scrollTopY = getDistToScrollToTopEdge({ viewportRect, targetRect });
    // If we're scrolling up, don't attempt to scroll to the bottom edge
    const scrollBottomY = getDistToScrollToBottomEdge({ viewportRect, targetRect });

    // Don't need to scroll X if the target is already within the X range
    if (scrollTopY >= 0 && scrollBottomY <= 0) return scrollableParent.scrollTop;

    const scrollDiffY = scrollRange(
        Math.min(
            scrollTopY,
            // If we're scrolling left, don't attempt to scroll to the right edge
            scrollTopY < 0 ? Infinity : scrollBottomY,
        ),
    );

    // Math.min will ensure the top edge is favoured if it won't fit in the viewport
    return scrollableParent.scrollTop + scrollDiffY;
};

/**
 * Gets the updated scrollLeft and scrollTop to get the scrollableParent to show the targetRect.
 */
export const getScrollForTarget = ({
    scrollableParent,
    targetRect,
    viewportOptions,
}: {
    scrollableParent: HTMLElement;
    targetRect: Rect;
    viewportOptions?: ViewportOptions;
}): {
    scrollLeft: number;
    scrollTop: number;
} | null => {
    const viewportRect = getViewportRect(scrollableParent, viewportOptions);
    if (!viewportRect) return null;

    return {
        scrollLeft: getScrollLeft({ scrollableParent, viewportRect, targetRect }),
        scrollTop: getScrollTop({ scrollableParent, viewportRect, targetRect }),
    };
};

/**
 * Gets the current scroll position of a DOM element as x, y coordinates.
 */
export const getScrollAsPoint = (element: HTMLElement): { x: number; y: number } =>
    element ? { x: element.scrollLeft, y: element.scrollTop } : { x: 0, y: 0 };

export const scrollToBottom = (domElement: HTMLElement): void => {
    if (!domElement || !canScrollY(domElement)) return;

    domElement.scrollTop = domElement.scrollHeight - domElement.clientHeight;
};
