// Utils
import { calculatePermissionValue } from './permissionUtil';

// NOTE: We need to keep the access bits in a separate file to prevent a circular dependency with permissionUtil
//  and calculatePermissionValue. But exporting here because it's a natural place to access the constant.
import { ACCESS_BITS as _ACCESS_BITS } from './permissionBitsConstants';

export const ACCESS_BITS = _ACCESS_BITS;

export const PERMISSION_STATES = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    UNKNOWN: 'UNKNOWN',
};

/**
 * Minimum perissions required to see a trashed item.
 * The user must be a named editor to view a trashed item
 */
export const MIN_TRASH_PERMISSION = ACCESS_BITS.WRITE;

/**
 * Integers representing common permission states. For example:
 *  -  1 : Read only
 *  -  9 : Feedback & read
 *  - 15 : Full access
 *  - 31 : Owner
 */
export const PERMISSION_VALUES = {
    READ_ONLY: calculatePermissionValue({
        read: true,
        write: false,
        save: false,
        feedback: false,
        owner: false,
    }),
    READ_AND_FEEDBACK: calculatePermissionValue({
        read: true,
        write: false,
        save: false,
        feedback: true,
        owner: false,
    }),
    FULL_ACCESS: calculatePermissionValue({
        read: true,
        write: true,
        save: true,
        feedback: true,
        owner: false,
    }),
    OWNER: calculatePermissionValue({
        read: true,
        write: true,
        save: true,
        feedback: true,
        owner: true,
    }),

    // Sentinel values
    SPAM_REMOVAL: -384, // -110000000 - No real significance, beyond all the above bits being off & being negative
};
