export enum SheetId {
    // Containers
    Inbox = 'Inbox',
    Document = 'Document',
    Notifications = 'Notifications',

    // Menu
    CanvasContextMenu = 'CanvasContextMenu',
    QuickAddMenu = 'QuickAddMenu',
    AccountSettings = 'AccountSettings',

    // Tools
    ContextMenuTool = 'ContextMenuTool',
    TextStyleRichTextTool = 'TextStyleRichTextTool',
    ColorTool = 'ColorTool',
    IconTool = 'IconTool',
    ReactionTool = 'ReactionTool',
    ElementReactionTool = 'ElementReactionTool',
    DueDateTool = 'DueDateTool',
    SearchTool = 'SearchTool',

    // Popups
    BillingAlertPopup = 'BillingAlertPopup',
    Auth = 'Auth',
}

export type SheetConfig = {
    scrollToElementOnOpen?: boolean;
    containsElementEditor?: boolean;
    stayActiveOnLocationChange?: boolean;
};

export type SheetState = {
    active: true;
    sheetId: SheetId;
    activeSnapPoint: number;
    config?: SheetConfig;
};

export type SheetReducerState = {
    [sheetKey in string]?: SheetState;
};
