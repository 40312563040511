import { getTimestamp } from '../../../../../../../common/utils/timeUtil';
import { searchForIcon } from '../../../../../../components/elementIcon/elementIconService';

import {
    ICON_POPUP_SEARCH_SUBMIT,
    ICON_POPUP_SEARCH_SUCCESS,
    ICON_POPUP_SEARCH_FAILURE,
    ICON_POPUP_MODE_SET,
} from './iconsPopupConstants';

export const setIconPopupMode = ({ mode, id }) => ({
    type: ICON_POPUP_MODE_SET,
    mode,
    id,
    timestamp: getTimestamp(),
});

export const findIcons =
    ({ query, id }) =>
    (dispatch) => {
        dispatch({ type: ICON_POPUP_SEARCH_SUBMIT, query, id });

        searchForIcon(query, 32)
            .then((response) => {
                const { icons } = response.data;
                dispatch({ type: ICON_POPUP_SEARCH_SUCCESS, query, icons });
            })
            .catch((err) => {
                console.error('ERROR: Retrieving icons.', err, err.response);
                dispatch({ type: ICON_POPUP_SEARCH_FAILURE, query });
            });
    };
