import React, { ReactElement, useCallback, useContext, useMemo } from 'react';

type ToastRendererContextArgs = Map<string, ReactElement>;

const ToastRendererContext = React.createContext<ToastRendererContextArgs>(new Map());

export const useToastRendererContext = () => useContext(ToastRendererContext);

type ToastContextArgs = {
    addToast: (toastId: string, toast: ReactElement) => void;
    removeToast: (toastId: string) => void;
};

const ToastContext = React.createContext<ToastContextArgs>({
    addToast: () => {},
    removeToast: () => {},
});

export const useToastContext = () => useContext(ToastContext);

export const ToastProvider: React.FC = ({ children }) => {
    const [toasts, setToasts] = React.useState(new Map<string, ReactElement>());

    const addToast = useCallback((toastId: string, toast: ReactElement) => {
        setToasts((prevToasts) => new Map(prevToasts).set(toastId, toast));
    }, []);

    const removeToast = useCallback((toastId: string) => {
        setToasts((prevToasts) => {
            const newToasts = new Map(prevToasts);
            newToasts.delete(toastId);
            return newToasts;
        });
    }, []);

    const toastContextValue = useMemo(() => ({ addToast, removeToast }), [addToast, removeToast]);

    return (
        <ToastRendererContext.Provider value={toasts}>
            <ToastContext.Provider value={toastContextValue}>{children}</ToastContext.Provider>
        </ToastRendererContext.Provider>
    );
};
