// Lib
import React, { CSSProperties } from 'react';

type FloatingPanelArrowProps = {
    width: number;
    height: number;
    color: string;
    strokeWidth: number;
    strokeColor: string;
    style?: CSSProperties;
};

const FloatingPanelArrow = React.forwardRef<HTMLDivElement, FloatingPanelArrowProps>(function FloatingPanelArrow(
    props,
    ref,
) {
    const { width, height, color, strokeWidth, strokeColor, style } = props;

    return (
        <div ref={ref} className="arrow" style={style}>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
                <path
                    d={`M0 ${height} L${width / 2} 0 L${width} ${height}`}
                    fill={color}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    fillRule="evenodd"
                />
            </svg>
        </div>
    );
});

export default FloatingPanelArrow;
