// Lib
import { Capacitor } from '@capacitor/core';

// Services
import { intercomBoot, intercomShutdown, intercomShowMessenger } from './intercomService';

// Utils
import getClientConfig from '../utils/getClientConfig';
import { initialiseRollbar, setRollbarUser } from './rollbarService';
import { isCurrentBrowserLogoutAction } from '../auth/authUtils';
import { clearAmplitudeUser } from './amplitudeService';
import { getEnabledExperiments } from '../../common/users/utils/userPropertyUtils';
import { getTimestamp, TIMES } from '../../common/utils/timeUtil';
import { setNewRelicApplicationVersion, setNewRelicCustomAttribute, setNewRelicUserId } from './newRelicUtils';
import { getPlatformDetailsSelector } from '../platform/platformSelector';
import { isPlatformDesktopPwa, isPlatformElectronMac } from '../platform/utils/platformDetailsUtils';
import { getInitialPlatformDetails } from '../platform/utils/platformInitialisationUtils';

// Middleware
import analyticsEventMiddleware from './analyticsEventMiddleware';
import amplitudeAnalyticsMiddleware from './amplitudeAnalyticsMiddleware';
import analyticsTimingMiddlewareActionHandler from './timingService/analyticsTimingMiddlewareActionHandler';

// Plugins
import { IntercomPlugin } from '../../capacitor_plugins/intercom';

// Constants
import { INTERCOM_SHOW_MESSAGES } from './analyticsConstants';
import { CURRENT_USER_SET, CURRENT_USER_SET_GUEST } from '../user/currentUserConstants';
import { CAPACITOR_INTERCOM_PLUGIN_NAME } from '../../capacitor_plugins/pluginConstants';

export default () => {
    const config = getClientConfig();

    setNewRelicApplicationVersion(config.appVersion);

    const platformDetails = getInitialPlatformDetails();
    setNewRelicCustomAttribute('applicationType', platformDetails.appType);

    // if no analytics enabled, return empty middleware
    if (!config.analytics || !config.analytics.enabled) {
        return (store) => (next) => (action) => {
            // We'll record the timings for actions even if we're not connecting to analytics services
            //  because it could be useful for debugging purposes
            analyticsTimingMiddlewareActionHandler(action);
            next(action);
        };
    }

    // send user data to intercom
    const sendIntercomUserData = (user, platformDetails) => {
        const createdDate = parseInt(user._id.slice(0, 8), 16);

        const intercomUser = {
            user_id: user._id,
            created_at: createdDate,
            email: user.email,
            name: user.name && user.name.displayName,
            res_width: window.screen.availWidth,
            res_height: window.screen.availHeight,
            hide_default_launcher: true,
            experiments: getEnabledExperiments(user),
        };

        if (isPlatformElectronMac(platformDetails)) {
            intercomUser['last-seen-on-electron'] = Math.floor(getTimestamp() / TIMES.SECOND);
        }

        if (isPlatformDesktopPwa(platformDetails)) {
            intercomUser['has-used-PWA'] = true;
        }

        if (Capacitor.isPluginAvailable(CAPACITOR_INTERCOM_PLUGIN_NAME)) {
            IntercomPlugin.initialiseIntercom({ userId: user._id });
        }

        intercomBoot({
            app_id: config.analytics.appId,
            ...intercomUser,
        });
    };

    const shutdownIntercom = () => {
        intercomShutdown();
    };

    // return analytics middleware
    return (store) => (next) => {
        const eventMiddleware = analyticsEventMiddleware(store)(next);
        const amplitudeMiddleware = amplitudeAnalyticsMiddleware(store)(next);

        initialiseRollbar();

        return (action) => {
            const updated = next(action);

            eventMiddleware(action);
            analyticsTimingMiddlewareActionHandler(action);

            // Shut down intercom when user logs out
            if (isCurrentBrowserLogoutAction(action)) {
                clearAmplitudeUser();
                shutdownIntercom();
                return updated;
            }

            if (action.remote) return updated;

            const state = store.getState();
            const platformDetails = getPlatformDetailsSelector(state);

            amplitudeMiddleware(action);

            switch (action.type) {
                case CURRENT_USER_SET:
                    // when we set user data, send it to analytics services
                    sendIntercomUserData(action.user, platformDetails);
                    setRollbarUser(action.user);
                    setNewRelicUserId(action.user?._id);
                    break;
                case CURRENT_USER_SET_GUEST:
                    // if we only have a guest user, boot interom without user
                    intercomBoot({
                        app_id: config.analytics.appId,
                        hide_default_launcher: true,
                    });
                    break;
                case INTERCOM_SHOW_MESSAGES:
                    intercomShowMessenger();
                    break;
                default:
                    break;
            }

            return updated;
        };
    };
};
