// Lib
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { asObject, prop } from '../../../../../../../common/utils/immutableHelper';
import { createDeepSelector, createShallowSelector } from '../../../../../../utils/milanoteReselect/milanoteReselect';
import { getAttachments, getDataUri } from '../../../../../../element/attachments/attachmentsSelector';
import makeStringIcon from '../../../../../../components/elementIcon/makeStringIconDef';
import popupOpenSelector from '../../../../../../components/popupPanel/popupOpenSelector';
import { getSelectedElements } from '../../../../../../element/selection/selectedElementsSelector';
import { getElementId, getTitle } from '../../../../../../../common/elements/utils/elementPropertyUtils';
import {
    getAcronyms,
    filterWords,
    removeSpecialCharacters,
} from '../../../../../../components/elementIcon/elementIconUtils';

// Constants
import { LETTERS_CATEGORY, RECOMMENDED_CATEGORY } from '../iconToolConstants';
import { ICON_POPUP_MODE_LIST_MAX, NUMBER_ICONS, STRING_ICONS } from './iconsPopupConstants';
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';
import { IconUiCategory } from '../iconToolTypes';

// Helper functions
const getFirstSelected = (propertyPath) => (selectedElements) =>
    selectedElements.first() ? selectedElements.first().getIn(propertyPath) : null;
const getFirstAttachmentData = (selectedElements, attachments) => {
    const firstSelectedElement = selectedElements.first();
    const firstSelectedElementId = getElementId(firstSelectedElement);
    return getDataUri(attachments.get(firstSelectedElementId));
};
const getSelectedImage = (selectedElements, attachments) =>
    getFirstAttachmentData(selectedElements, attachments) ||
    getFirstSelected(['content', 'image', 'regular'])(selectedElements) ||
    getFirstSelected(['content', 'image', 'original'])(selectedElements);
const getSelectedIcon = getFirstSelected(['content', 'icon']);

const getFirstElementId = getFirstSelected(['id']);

// Selected property selectors
export const firstElementIdSelector = createSelector(getSelectedElements, getFirstElementId);

// Selected property selectors
export const selectedIconSelector = createSelector(getSelectedElements, getSelectedIcon);

export const selectedImageSelector = createSelector(getSelectedElements, getAttachments, getSelectedImage);

// Popup state selectors
const selectPopupModeFromState = (state) => state.getIn(['app', 'popup', 'icons', 'mode']);
export const popupModeSelector = createSelector(
    selectPopupModeFromState,
    selectedImageSelector,
    (popupMode, selectedImage) => {
        let actualPopupMode = popupMode;
        if (!actualPopupMode) {
            actualPopupMode = selectedImage ? IconUiCategory.IMAGES : IconUiCategory.DEFAULT;
        }
        return actualPopupMode;
    },
);

export const selectedElementTitleSelector = createSelector(
    (state, ownProps) => ownProps.selectedElements,
    (selectedElements) => getTitle(selectedElements?.first()),
);

const popupQuerySelector = createSelector(
    popupModeSelector,
    selectedElementTitleSelector,
    (popupMode, selectedTitle) => {
        if (popupMode === IconUiCategory.DEFAULT) return selectedTitle;

        if (popupMode === IconUiCategory.LETTERS) return IconUiCategory.LETTERS;

        return popupMode;
    },
);

export const selectLetterIcons = createDeepSelector(selectedElementTitleSelector, (selectedTitle) => {
    const iconStringList = getAcronyms(selectedTitle);
    return iconStringList.map((letter) => makeStringIcon(letter));
});

const selectIconSearchResults = (state) => state.getIn(['app', 'popup', 'icons', 'searchResults']);
export const selectPojoIconSearchResults = createSelector(selectIconSearchResults, asObject);

const iconListSelector = createShallowSelector(
    popupModeSelector,
    popupQuerySelector,
    selectLetterIcons,
    selectPojoIconSearchResults,
    (popupMode, popupQuery, lettersList, searchResults) => {
        switch (popupMode) {
            case IconUiCategory.DEFAULT:
                return searchResults[popupQuery]?.slice(0, 8).concat(lettersList).concat(NUMBER_ICONS);
            case IconUiCategory.LETTERS:
                return lettersList.concat(NUMBER_ICONS).concat(STRING_ICONS);
            default:
                return searchResults[popupQuery];
        }
    },
);

const sortByTimestamp = (a, b) => prop('timestamp', b) - prop('timestamp', a);

const selectElementSearches = (state) => state.getIn(['app', 'popup', 'icons', 'elementSearches']);
export const selectOrderedSavedSearches = createShallowSelector(
    (state, ownProps) => ownProps.selectedElements,
    selectElementSearches,
    selectPopupModeFromState,
    (selectedElements, elementSearches, selectedPopupMode) => {
        const firstEl = getElementId(selectedElements.first());

        if (!elementSearches.get(firstEl)) return [];

        return elementSearches.get(firstEl).valueSeq().toArray().sort(sortByTimestamp).map(prop('search'));
    },
);

export const iconPopupModeListSelector = createSelector(
    selectedElementTitleSelector,
    selectOrderedSavedSearches,
    selectedImageSelector,
    (selectedTitle, savedSearches, selectedImage) => {
        let popupModeList = [RECOMMENDED_CATEGORY];
        let customList = [];

        const title = selectedTitle || '';

        const splitTitle = title
            .split(' ')
            .filter(filterWords())
            .map(removeSpecialCharacters)
            .filter((word) => word.length > 0);

        if (splitTitle.length > 1) {
            splitTitle.forEach((part) => {
                customList.push({
                    slug: part,
                    title: part,
                });
            });
        }

        [...savedSearches].reverse().forEach((q) => {
            customList.push({
                title: q,
                slug: q,
            });
        });

        customList = customList.slice(-(ICON_POPUP_MODE_LIST_MAX - 3));

        popupModeList = popupModeList.concat(customList);

        popupModeList.push(LETTERS_CATEGORY);

        const imageModeTitle = selectedImage ? 'Custom image' : 'Upload an image';
        popupModeList.push({ slug: IconUiCategory.IMAGES, title: imageModeTitle });
        return popupModeList;
    },
);

const iconPopupSelector = createStructuredSelector({
    isActive: popupOpenSelector(PopupIds.ICON),
    popupMode: popupModeSelector,
    popupQuery: popupQuerySelector,
    modeList: iconPopupModeListSelector,
    iconList: iconListSelector,
    selectedImage: selectedImageSelector,
});

export default iconPopupSelector;
