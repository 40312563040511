// Lib
import { curry } from 'lodash/fp';

// Constants
import { ACCESS_BITS } from './permissionBitsConstants';

const PUBLIC_ACL_ID_REGEX = /^p_[a-zA-Z0-9]{14}$/;
export const isPublicAclId = (aclId: string): boolean => PUBLIC_ACL_ID_REGEX.test(aclId);

// Access denied means the user does not have permission to even view the board
export const isAccessDenied = (permission: number): boolean => !permission || permission <= 0;
// Blocked means that the user has been specifically removed from the board
export const isBlocked = (permission: number): boolean => permission < 0;

/**
 * Determines whether the provided permission meets the required permission while respecting
 * if the permission is blocked.
 */
export const hasPermission = curry((requiredPermission: number, permission: number): boolean => {
    // If the user is not blocked, then do the standard bit-wise permission check
    if (!isBlocked(permission)) return requiredPermission === (permission & requiredPermission);

    // Otherwise, only return true if we're specifically checking that they have the BLOCKED bit
    return requiredPermission === ACCESS_BITS.BLOCKED;
});

export const canRead = hasPermission(ACCESS_BITS.READ);
export const canWrite = hasPermission(ACCESS_BITS.WRITE);
export const canSave = hasPermission(ACCESS_BITS.SAVE);
export const canGiveFeedback = hasPermission(ACCESS_BITS.FEEDBACK);
export const canEditPermissions = hasPermission(ACCESS_BITS.PERMISSIONS);
export const isOwner = hasPermission(ACCESS_BITS.OWNER);

export const calculatePermissionValue = ({
    read = true,
    write = true,
    save = true,
    feedback = true,
    owner = false,
}: {
    read?: boolean;
    write?: boolean;
    save?: boolean;
    feedback?: boolean;
    owner?: boolean;
}): number =>
    (read ? ACCESS_BITS.READ : 0) |
    (write ? ACCESS_BITS.WRITE : 0) |
    (save ? ACCESS_BITS.SAVE : 0) |
    (feedback ? ACCESS_BITS.FEEDBACK : 0) |
    (owner ? ACCESS_BITS.OWNER : 0);

export const isReadOnly = (permission: number): boolean =>
    canRead(permission) && !canWrite(permission) && !canGiveFeedback(permission);

export const isFullAccess = (permission: number): boolean =>
    canRead(permission) && canGiveFeedback(permission) && canWrite(permission) && canSave(permission);

export const isFeedbackOnlyAccess = (permission: number): boolean =>
    canRead(permission) && canGiveFeedback(permission) && !canWrite(permission) && !canSave(permission);

export const permissionNameList = (permission: number): string[] => {
    if (isBlocked(permission)) return ['BLOCKED'];

    return Object.keys(ACCESS_BITS).reduce((acc, key) => {
        const value = ACCESS_BITS[key] || 0;

        if (hasPermission(value, permission)) {
            acc.push(key);
        }

        return acc;
    }, [] as string[]);
};
