import { RefObject } from 'react';
import { DragState } from '../../mobile/structural/sheet/hooks/useSheetHandlers';

// There is a correct type in chart.js, but we are dynamically importing it later. Also, not concerned since it's just a debug tool
let chart: any;

const colorConfig = { color: 'rgba(255, 255, 255, 0.5)' };

const scaleColorConfig = {
    grid: colorConfig,
    ticks: colorConfig,
    border: colorConfig,
};

export default (dragState: RefObject<DragState>) => {
    const debugPanel = document.querySelector<HTMLCanvasElement>('.SheetAnimationVelocityChart canvas');
    const { speedDataDragging, speedDataNotDragging } = dragState.current?.graphData || {};

    if (debugPanel) {
        import('chart.js').then(({ Chart }) => {
            chart?.destroy();
            chart = new Chart(debugPanel, {
                type: 'scatter',
                data: {
                    datasets: [
                        {
                            label: 'Dragging',
                            data: speedDataDragging,
                            backgroundColor: '#836FFF',
                        },
                        {
                            label: 'Animation',
                            data: speedDataNotDragging,
                            backgroundColor: '#15F5BA',
                        },
                    ],
                },
                options: {
                    scales: {
                        x: {
                            type: 'linear',
                            position: 'bottom',
                            ...scaleColorConfig,
                        },
                        y: {
                            type: 'linear',
                            position: 'left',
                            ...scaleColorConfig,
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Sheet Velocity',
                            color: '#F0F3FF',
                        },
                        legend: {
                            labels: colorConfig,
                        },
                    },
                },
            });
        });
    }
};
