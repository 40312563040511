// Lib
import classNames from 'classnames';

// Utils
import { prop } from '../../../common/utils/immutableHelper';
import { gridPointsToPixels } from '../../utils/grid/gridUtils';
import { getWidth } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import { ELEMENT_DEFAULT_WIDTH, ELEMENT_SMALL_WIDTH } from '../../../common/elements/elementConstants';
import { IN_LIST_MODE } from '../../../common/inList/listTypes';

export const getDomElementId = (elementId) => `el-${elementId}`;
export const getEditorDomElementId = (elementId) => `el-${elementId}-editor`;

export const isElementBelowEditableWidth = (props) => {
    const widthGU = getElementCardWidthGU(props);
    return widthGU < ELEMENT_SMALL_WIDTH;
};

/**
 * Determines the width, in grid units, that an element card (e.g. CARD, LINK, FILE, etc) is
 * intended to be rendered.
 * NOTE: This does not subtract the padding of its container or position.
 */
export const getElementCardWidthGU = ({ element, tempSize, inList, parentWidthGU }) => {
    if (inList === IN_LIST_MODE.IN_LIST_COLUMN) return parentWidthGU || ELEMENT_DEFAULT_WIDTH;

    // Otherwise - use the current resizing width
    return (
        prop('width', tempSize) ||
        // Or use the saved width
        getWidth(element) ||
        // Or use the default width
        ELEMENT_DEFAULT_WIDTH
    );
};

// On the canvas element cards sit 2px inside the grid, so they don't cover the grid points
const ELEMENT_CARD_CANVAS_INSET = 2;
// In columns, element cards have a grid unit of padding on either side
const ELEMENT_CARD_COLUMN_INSET_GU = 2;

/**
 * Determines the width in pixels that an element card will be rendered at.
 * NOTE: This subtracts pixels based on the element's context, such as on the canvas
 *  or within columns.
 */
export const getElementCardRenderedWidthPx = (props) => {
    const { gridSize, inList } = props;

    const widthGU = getElementCardWidthGU(props);

    if (inList === IN_LIST_MODE.IN_LIST_COLUMN) {
        return gridPointsToPixels(widthGU, gridSize) - gridPointsToPixels(ELEMENT_CARD_COLUMN_INSET_GU, gridSize);
    }

    return gridPointsToPixels(widthGU, gridSize) - ELEMENT_CARD_CANVAS_INSET;
};

/**
 * Determines the "cropInset" to use for the getPaddingForAspectRatio function for a standard element card.
 */
export const getElementCardCropInset = (inList) =>
    inList === IN_LIST_MODE.IN_LIST_COLUMN
        ? // In columns we need to sit perfectly on grid points to ensure the column sits within grid points
          0
        : // On the canvas we need to crop the image by 2px to sit within the grid points
          2;

export const elementClassNames = (prefix, props, otherClassnames) => {
    const {
        isFocusedBackgroundElement,
        inList,
        isEditing,
        isEditable,
        isSelected,
        isSingleSelected,
        isRemotelySelected,
        shouldFocusOnlyWhenSelected,
        className,
        isLocked,
        transparent,
        isResizing,

        // drag and drop
        isHovered,
        isReplaceModeHovered,
        canDrop,
        isAttachingInside,
        // drag and drop - lines
        isLineEdgeHovered,
        isConnectingLineEdgeInside,
        isConnectingLineEdgeInsideChild,
    } = props;

    return classNames(prefix, inList, className, {
        ...otherClassnames,
        editing: isEditing,
        editable: isEditable,
        selected: isSelected,
        // This is used for elements that are being focused but aren't the "FocusModeElement"
        //  E.g. It's the original element on the canvas
        'being-focused': isFocusedBackgroundElement,
        'selected-single': isSingleSelected,
        'remote-selected': isRemotelySelected,
        'can-drop': (isHovered && canDrop) || isReplaceModeHovered,
        locked: isLocked,
        'line-edge-can-drop': isLineEdgeHovered,
        'attach-mode': isAttachingInside,
        'line-edge-connect-inside': isConnectingLineEdgeInside,
        'line-edge-connect-inside-child': isConnectingLineEdgeInsideChild,
        transparent,
        resizing: isResizing,
        'focus-editing': shouldFocusOnlyWhenSelected && isEditing,
        'minimum-width': isElementBelowEditableWidth(props),
        'element-in-list': inList, // this is a generic class applied to elements in ANY list
    });
};
