import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarSearchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-search"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M11.375 1.354c5.534 0 10.02 4.487 10.02 10.021a9.98 9.98 0 0 1-2.382 6.487l5.395 5.397a.812.812 0 1 1-1.15 1.149l-5.396-5.395a9.981 9.981 0 0 1-6.487 2.383c-5.534 0-10.02-4.487-10.02-10.021 0-5.534 4.486-10.02 10.02-10.02zm0 1.625a8.396 8.396 0 1 0 0 16.792 8.396 8.396 0 0 0 0-16.792z"
        />
    </svg>
);
const Memo = memo(MobileToolbarSearchIcon);
export default Memo;
