export type ObserverOptions = {
    rootSelector: string;
    rootMargin: string;
};

const DEFAULT_LIST_ROOT_MARGIN = '800px 0px 800px 0px';

export const TRASH_OBSERVER_CONFIG: ObserverOptions = {
    rootSelector: '.TrashPopupContent .tab-panels',
    rootMargin: DEFAULT_LIST_ROOT_MARGIN,
};
export const INBOX_OBSERVER_CONFIG: ObserverOptions = {
    rootSelector: '.Inbox .list-scrollable',
    rootMargin: DEFAULT_LIST_ROOT_MARGIN,
};
export const QUICK_NOTES_OBSERVER_CONFIG: ObserverOptions = {
    rootSelector: '.QuickNotesScrollableList',
    rootMargin: DEFAULT_LIST_ROOT_MARGIN,
};
export const CANVAS_OBSERVER_CONFIG: ObserverOptions = {
    rootSelector: '#canvas-viewport',
    rootMargin: '400px 400px 400px 400px',
};
