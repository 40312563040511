// Utils
import { getIconId } from '../../../../../../../../../common/elements/utils/elementPropertyUtils';

import { ElementIcon } from '../../../../../../../../components/elementIcon/elementIconTypes';
import { ImMNElement } from '../../../../../../../../../common/elements/elementModelTypes';

export const getIsIconActive = (selectedElements: Immutable.List<ImMNElement>, icon?: ElementIcon) => {
    let isActive = true;

    selectedElements.forEach((element) => {
        const elementIconId = getIconId(element);
        const iconId = icon?.id;

        isActive = (isActive && !elementIconId && !iconId) || (!!iconId && elementIconId === iconId);
    });

    return isActive;
};
