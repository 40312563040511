import { DragState } from '../hooks/useSheetHandlers';
import { getSnapPointSheetHeight } from './sheetHeightUtils';

const VELOCITY_MULTIPLIER = 200;

/**
 * Initialise the snap points array.
 * Add 0 as a snap point to dismiss the sheet, and remove any invalid snap points
 */
export const initialiseSnapPoints = (snapPoints: number[], preventDismiss: boolean): number[] => {
    const points = snapPoints.filter((snapPoint) => snapPoint <= 1 && snapPoint > 0).sort();

    return preventDismiss ? points : [0, ...points];
};

export const getSnapPointTopValue = (snapPoint: number): number =>
    window.innerHeight - getSnapPointSheetHeight(snapPoint);

export const getNewActiveSnapPoint = (dragState: DragState, snapPointsState: number[]): number => {
    const { currentY, sheetTouchOffset, velocity } = dragState;

    // If user is dragging over a certain velocity, adjust the intended position to be further in that direction
    // Multiplier is just an arbitrary number based on what feels good. Velocity in px/ms
    const velocityAdjustment = VELOCITY_MULTIPLIER * velocity;
    const intendedSheetTop = currentY - sheetTouchOffset - velocityAdjustment;
    const snapDistances = snapPointsState.map((snapPoint: number) =>
        Math.abs(intendedSheetTop - getSnapPointTopValue(snapPoint)),
    );
    const smallestDistance = Math.min(...snapDistances);

    // go to the closest snap point
    const index = snapDistances.indexOf(smallestDistance);
    return snapPointsState[index];
};
