import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const GreenTickEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="Icon green-tick-empty"
        {...props}
    >
        <g fill="none">
            <circle cx={8} cy={8} r={7} stroke="#999999" />
        </g>
    </svg>
);
const Memo = memo(GreenTickEmptyIcon);
export default Memo;
