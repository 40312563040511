import { Icon } from '../../../common/icons/iconTypes';

export enum IconType {
    STRING = 'string',
    SVG = 'svg',
}

export type ElementStringIcon = {
    type: IconType.STRING;
    id: string;
    string: string;
};

export type ElementIcon = ElementStringIcon | Icon;

export type IconSearchResults = {
    [query: string]: Icon[];
};
