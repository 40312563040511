import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-settings"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M15.244 1.354c.36 0 .677.237.779.583l.87 2.946c.488.222.95.483 1.39.785l3.114-.762a.812.812 0 0 1 .89.372l2.243 3.745c.192.32.141.73-.123.993l-2.22 2.213a7.722 7.722 0 0 1 .001 1.527l2.219 2.213a.813.813 0 0 1 .123.993l-2.243 3.745a.812.812 0 0 1-.89.371l-3.115-.762a9.488 9.488 0 0 1-1.388.783l-.87 2.963a.813.813 0 0 1-.78.584h-4.488a.812.812 0 0 1-.779-.583l-.87-2.946a9.09 9.09 0 0 1-1.39-.785l-3.114.762a.812.812 0 0 1-.89-.372L1.47 16.977a.813.813 0 0 1 .123-.993l2.22-2.213A7.87 7.87 0 0 1 3.775 13c0-.246.013-.497.039-.756l-2.22-2.213a.813.813 0 0 1-.123-.993l2.243-3.745a.812.812 0 0 1 .89-.371l3.115.762a9.488 9.488 0 0 1 1.388-.783l.87-2.963a.813.813 0 0 1 .78-.584h4.488zm-3.88 1.625-.806 2.744a.812.812 0 0 1-.468.522 7.855 7.855 0 0 0-1.714.967.813.813 0 0 1-.681.14l-2.896-.71L3.19 9.33l2.05 2.043c.181.18.267.436.231.69A6.736 6.736 0 0 0 5.4 13c0 .29.025.601.073.96a.812.812 0 0 1-.232.683l-2.05 2.044 1.61 2.686 2.895-.709a.813.813 0 0 1 .68.14 7.5 7.5 0 0 0 1.708.964c.23.093.405.286.475.523l.806 2.73h3.271l.807-2.744a.812.812 0 0 1 .468-.522 7.855 7.855 0 0 0 1.714-.967.813.813 0 0 1 .681-.14l2.896.71 1.609-2.687-2.05-2.043a.813.813 0 0 1-.231-.69c.047-.334.072-.646.072-.938 0-.29-.025-.601-.073-.96a.812.812 0 0 1 .232-.683l2.05-2.044-1.61-2.686-2.895.709a.813.813 0 0 1-.68-.14 7.5 7.5 0 0 0-1.708-.964.813.813 0 0 1-.475-.523l-.806-2.73h-3.271zM13 8.938c2.248 0 4.086 1.824 4.086 4.062 0 2.238-1.838 4.063-4.086 4.063S8.914 15.238 8.914 13c0-2.238 1.838-4.063 4.086-4.063zm0 1.624c-1.353 0-2.46 1.1-2.46 2.438 0 1.337 1.107 2.438 2.46 2.438s2.46-1.1 2.46-2.438c0-1.337-1.107-2.438-2.46-2.438z"
        />
    </svg>
);
const Memo = memo(MobileToolbarSettingsIcon);
export default Memo;
