import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarNotificationsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-notifications"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M2.167 20.583v-2.166c1.196 0 2.166-.97 2.166-2.167l.502-6.522a8.19 8.19 0 0 1 16.33 0l.502 6.522c0 1.197.97 2.167 2.166 2.167v2.166h-7.312v.542a3.52 3.52 0 1 1-7.042 0v-.542H2.167zm8.937 0v.542a1.896 1.896 0 0 0 3.792 0v-.542h-3.792zM13 3.792a6.564 6.564 0 0 0-6.545 6.06l-.497 6.469a3.78 3.78 0 0 1-1.138 2.637h16.36a3.78 3.78 0 0 1-1.126-2.4l-.012-.237-.497-6.469A6.564 6.564 0 0 0 13 3.792z"
        />
    </svg>
);
const Memo = memo(MobileToolbarNotificationsIcon);
export default Memo;
