// Lib
import React, { FC, ReactElement, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect, ConnectedProps } from 'react-redux';
import { toLower } from 'lodash';

// Utils
import { getIsIconActive } from '../popup/tabContent/iconList/iconListUtils';
import { getElementId, getTitle } from '../../../../../../../common/elements/utils/elementPropertyUtils';

// Selectors
import { selectMobileIconList } from './mobileIconListSelector';

// Actions
import { findIcons } from '../popup/iconsPopupActions';

// Components
import IconButton from '../popup/tabContent/iconList/IconButton';
import Spinner from '../../../../../../components/loaders/Spinner';

// Types
import { IconUiCategory } from '../iconToolTypes';
import { MobileIconModeListProps } from './mobileIconListTypes';
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';
import { ElementIcon } from '../../../../../../components/elementIcon/elementIconTypes';

// Styles
import './MobileIconModeList.scss';

const IconListLoading = (): ReactElement => (
    <div className="IconListLoading">
        <Spinner show />
    </div>
);

const IconListEmpty = (): ReactElement => <div className="IconListEmpty">No icons found for this category</div>;

type IconListResultsProps = {
    iconList: ElementIcon[];
    selectedElements: Immutable.List<ImMNElement>;
};

const IconListResults: FC<IconListResultsProps> = ({ iconList, selectedElements }) => (
    <ul className="IconListResults">
        {iconList.map((icon, i) => (
            <li key={i}>
                <IconButton
                    selectedElements={selectedElements}
                    icon={icon}
                    isActive={getIsIconActive(selectedElements, icon)}
                />
            </li>
        ))}
    </ul>
);

type MobileIconListSwitchProps = {
    iconList?: ElementIcon[];
    selectedElements: Immutable.List<ImMNElement>;
};

const MobileIconListSwitch = ({ iconList, selectedElements }: MobileIconListSwitchProps): ReactElement => {
    if (!iconList) return <IconListLoading />;

    if (!iconList.length) return <IconListEmpty />;

    return <IconListResults iconList={iconList} selectedElements={selectedElements} />;
};

const makeMapStateToProps = () =>
    createStructuredSelector({
        // @ts-ignore Seems to struggle with createShallowSelector
        iconList: selectMobileIconList(),
    });

const mapDispatchToProps = (dispatch: Function) => ({
    dispatchSearchForIcons: (query: string, elementId: string) => dispatch(findIcons({ query, id: elementId })),
});

const connector = connect(makeMapStateToProps, mapDispatchToProps);

type ConnectedMobileIconModeListProps = ConnectedProps<typeof connector> & MobileIconModeListProps;

const MobileIconModeList = (props: ConnectedMobileIconModeListProps): ReactElement | null => {
    const { slug, title, iconList, selectedElements, dispatchSearchForIcons } = props;

    useEffect(() => {
        if (iconList?.length) return;

        if (slug === IconUiCategory.LETTERS) return;
        if (slug === IconUiCategory.IMAGES) return;

        const query = toLower(slug === IconUiCategory.DEFAULT ? getTitle(selectedElements?.first()) : title);

        dispatchSearchForIcons(query, getElementId(selectedElements.first()));
    }, []);

    return (
        <div id={`icon-mode-category-${slug}`} className="MobileIconModeList">
            <div className="title">
                <span>{title}</span>
            </div>
            <div className="results">
                <MobileIconListSwitch iconList={iconList} selectedElements={selectedElements} />
            </div>
        </div>
    );
};

export default connector(MobileIconModeList);
