import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const MobileToolbarSupportIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={26}
        viewBox="0 0 26 26"
        className="Icon mobile-toolbar-support"
        {...props}
    >
        <path
            fill="#8D929A"
            fillRule="evenodd"
            d="M13 1.083c6.581 0 11.917 5.336 11.917 11.917 0 6.581-5.336 11.917-11.917 11.917C6.419 24.917 1.083 19.58 1.083 13 1.083 6.419 6.42 1.083 13 1.083zm0 1.625C7.316 2.708 2.708 7.316 2.708 13S7.316 23.292 13 23.292 23.292 18.684 23.292 13 18.684 2.708 13 2.708zm-.305 13.808c.545 0 1 .45 1 .995a1 1 0 1 1-2 0c0-.545.45-.995 1-.995zm.255-8.482c1.835 0 3.066 1.1 3.066 2.726 0 1.125-.55 1.885-1.446 2.425l-.153.099c-.737.493-1.028 1.014-1.047 2.092v.095h-1.43l.005-.351c.058-1.653.6-2.192 1.485-2.735.595-.37 1.06-.895 1.06-1.64 0-.885-.69-1.45-1.54-1.45-.765 0-1.52.465-1.58 1.525H9.85c.064-1.8 1.415-2.786 3.1-2.786z"
        />
    </svg>
);
const Memo = memo(MobileToolbarSupportIcon);
export default Memo;
