import { initialiseSnapPoints } from '../utils/snapPointUtils';
import { RefObject, useEffect, useState } from 'react';
import {
    getSheetHeightAdjustedForClose,
    setContentHeightToMax,
    setContentHeightToVisibleSpace,
    setSheetHeight,
} from '../utils/sheetHeightUtils';

type SheetSnapPointState = {
    snapPointsState: number[];
    goToSnapPoint: (snapPoint: number) => void;
    addSnapPoint: (snapPoint: number) => void;
};

/**
 * Take care of snapPoint and activeSnapPoint state, and expose functions to update it
 */
const useSheetSnapPointState = (
    snapPoints: number[],
    sheetRef: RefObject<HTMLDivElement>,
    sheetContentRef: RefObject<HTMLDivElement>,
    activeSnapPoint: number,
    dispatchUpdateActiveSnapPoint: (snapPoint: number) => void,
    preventDismiss: boolean,
): SheetSnapPointState => {
    const [snapPointsState, setSnapPointsState] = useState<number[]>(initialiseSnapPoints(snapPoints, preventDismiss));

    const goToSnapPoint = (snapPoint: number) => {
        const newSheetHeight = getSheetHeightAdjustedForClose(snapPoint);
        const didHeightChange = setSheetHeight(sheetRef, newSheetHeight);

        if (snapPoint !== activeSnapPoint) {
            dispatchUpdateActiveSnapPoint(snapPoint);
        }

        if (didHeightChange) {
            sheetRef.current?.classList.toggle('sheet-covers-screen', newSheetHeight === window.innerHeight);
            setContentHeightToMax(sheetContentRef);

            sheetRef.current?.addEventListener(
                'transitionend',
                () => {
                    setContentHeightToVisibleSpace(sheetContentRef);
                },
                { once: true },
            );
        }
    };

    const addSnapPoint = (snapPoint: number) => {
        if (snapPointsState?.includes(snapPoint)) return;

        setSnapPointsState([...snapPointsState, snapPoint].sort());
    };

    useEffect(() => {
        goToSnapPoint(activeSnapPoint);
    }, [activeSnapPoint]);

    return { snapPointsState, goToSnapPoint, addSnapPoint };
};

export default useSheetSnapPointState;
