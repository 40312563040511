// Lib
import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { compose } from '../../../node_module_clones/recompose';

// Components
import userReFetchDecorator from '../../user/userReFetchDecorator';
import Icon from '../icons/Icon';
import UserAvatarType from './UserAvatarType';

// Util
import { prop } from '../../../common/utils/immutableHelper';
import { scaleToGrid } from '../../utils/grid/gridUtils';
import { getAvatarStyle } from './userAvatarUtils';

// Selectors
import { getGridSize } from '../../utils/grid/gridSizeSelector';

// Types
import { ImMNUser, MNUser } from '../../../common/users/userModelTypes';
import { MNFileContent } from '../../../common/elements/elementModelTypes';
import { UserAvatarInfo } from './userAvatarTypes';
import { MNCurrentUser, ImMNCurrentUser } from '../../user/currentUserSelector';

// Styles
import './UserAvatar.scss';

type UserAvatarPassedProps = {
    user: MNCurrentUser | ImMNCurrentUser | MNUser | ImMNUser | UserAvatarInfo;
    active?: boolean;
    activeIndicator?: boolean;
    size: number;
    attachment?: MNFileContent;
    onImageLoadCb?: () => void;
};

const mapStateToProps = createSelector(
    getGridSize,
    (state: Immutable.Map<string, unknown>, ownProps: UserAvatarPassedProps) => ownProps.size,
    (gridSize, size) => ({
        sizePx: Math.ceil(scaleToGrid(size, gridSize)),
    }),
);

const connector = connect(mapStateToProps);

type UserAvatarProps = ConnectedProps<typeof connector> & UserAvatarPassedProps;

const UserAvatar = (props: UserAvatarProps): ReactElement => {
    const { user, active, activeIndicator, sizePx, attachment } = props;
    const hasImage = prop('image', user) || (attachment && attachment.size);

    const indicator = activeIndicator ? <Icon name="notification-live" /> : null;

    return (
        <div
            className={classNames('UserAvatar', { active, 'has-image': hasImage })}
            style={getAvatarStyle(sizePx) as CSSProperties}
        >
            <UserAvatarType {...props} />
            {indicator}
        </div>
    );
};

// This provides the same functionality as the UserAvatar without the auto-fetching of the user-data
export const SimpleUserAvatar = connector(UserAvatar);

const enhancer = compose(connector, userReFetchDecorator);

// @ts-ignore not sure how to make compose function typesafe
// Asserting type on the component is necessary here so that the prop types are accurate when using this component elsewhere
export default enhancer(UserAvatar) as React.ComponentType<UserAvatarPassedProps>;
