import { SheetId, SheetConfig } from './sheetTypes';

const sheetConfig: { [id in SheetId]?: SheetConfig } = {
    // Containers
    Inbox: {
        containsElementEditor: true,
    },
    Document: {
        containsElementEditor: true,

        // FIXME: This is needed as currently there is an issue where a location change is triggered
        //  before (push) and right after (pop) opening the document sheet. Until this issue is fixed,
        //  we need to keep the sheet open on location change
        stayActiveOnLocationChange: true,
    },

    // Tools
    ContextMenuTool: {
        scrollToElementOnOpen: true,
    },
    ColorTool: {
        scrollToElementOnOpen: true,
    },
    ReactionTool: {
        scrollToElementOnOpen: true,
    },
    DueDateTool: {
        scrollToElementOnOpen: true,
    },
    IconTool: {
        scrollToElementOnOpen: true,
    },
    SearchTool: {
        // Keep sheet when navigating to a search result, so that it retains the search query
        stayActiveOnLocationChange: true,
    },

    AccountSettings: {
        // This sheet changes the location as part of navigating through its options
        stayActiveOnLocationChange: true,
    },
    Auth: {
        // Currently this sheet is opened & closed by route changes
        stayActiveOnLocationChange: true,
    },
};

export default sheetConfig;
