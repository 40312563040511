// Lib
import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// Selectors
import { getIsSheetOpen } from '../../../../../mobile/structural/sheet/sheetSelectors';
import { getSheetKey } from '../../../../../mobile/structural/sheet/hooks/useSheet';

// Utils
import { getDueDate, getElementId, getShowDueDate } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { stopPropagationOnly } from '../../../../../utils/domUtil';
import { getDueDateClass } from '../assignmentUiUtils';

// Actions
import { openSheet } from '../../../../../mobile/structural/sheet/sheetActions';

// Components
import Button from '../../../../../components/buttons/Button';
import MobileDueDateSheet from './MobileDueDateSheet';
import TaskPillContents from './TaskPillContents';

// Constants
import { SheetId } from '../../../../../mobile/structural/sheet/sheetTypes';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';

// Styles
import './TaskDueDatePill.scss';

const TaskDueDatePill = (props: { className?: string; element: ImMNElement; isEditable: boolean }) => {
    const { className, element, isEditable } = props;

    const elementId = getElementId(element);
    const hasDueDate = getShowDueDate(element) && !!getDueDate(element);

    const sheetId = SheetId.DueDateTool;
    const sheetKey = getSheetKey(sheetId, elementId);

    const isSheetOpen = useSelector(getIsSheetOpen(sheetKey));

    const dispatch = useDispatch();
    const openDueDateSheet = () => {
        if (!isEditable) return;

        dispatch(openSheet(sheetId, sheetKey));
    };
    const onClickHandler = (e: MouseEvent) => {
        stopPropagationOnly(e);
        openDueDateSheet();
    };

    return (
        <div className="task-pill-container">
            <Button onClick={onClickHandler}>
                <div
                    className={classNames('TaskPill', 'TaskDueDatePill', getDueDateClass(element), className, {
                        active: isSheetOpen,
                        populated: hasDueDate,
                    })}
                >
                    <div className="pill-contents">
                        <TaskPillContents element={element} />
                    </div>
                </div>
            </Button>
            <MobileDueDateSheet elementId={elementId} />
        </div>
    );
};

export default TaskDueDatePill;
