// Lib
import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';

// Styles
import './TextColorButton.scss';

interface TextColorButtonProps {
    active: boolean;
    // The colour option to display
    color: string | null;
    className?: string;
    onSelectTextColor: (textColor: string | null) => void;
    customTextColor?: string | null;
    customBackgroundColor?: string | null;
}

/**
 * If the card has a custom text color or background color, this function will return the appropriate style object
 * so that the options match the card.
 */
const getTextColorButtonStyle = (
    customTextColor?: string | null,
    customBackgroundColor?: string | null,
): Partial<{ color: string; backgroundColor: string }> => {
    const style: Partial<{ color: string; backgroundColor: string }> = {};
    if (customTextColor) style.color = customTextColor;
    if (customBackgroundColor) style.backgroundColor = customBackgroundColor;
    return style;
};

/**
 * Base TextColorButton component that will be used by the DraftJS and Tiptap implementations.
 */
const TextColorButton = (props: TextColorButtonProps): ReactElement => {
    const {
        active,
        color,
        className = 'text-standard',
        onSelectTextColor,
        customTextColor,
        customBackgroundColor,
    } = props;

    /**
     * Trigger the onSelectTextColor function when the user clicks on the button.
     */
    const onClick = useCallback(
        (event: React.MouseEvent) => {
            // Prevents the blue text selection highlight from showing in the card while choosing colours
            event.preventDefault();

            onSelectTextColor(color);
        },
        [color, onSelectTextColor],
    );

    const style = getTextColorButtonStyle(customTextColor, customBackgroundColor);

    return (
        <div
            className={classNames('TextColorButton', className, { active })}
            onClick={onClick}
            onPointerDown={(event) => event.preventDefault()}
            style={style}
        >
            A
        </div>
    );
};

export default TextColorButton;
