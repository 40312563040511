import * as Immutable from 'immutable';
import { ImMNElement } from '../../../../../../common/elements/elementModelTypes';
import { Icon } from '../../../../../../common/icons/iconTypes';
import {
    getIcon,
    getImageOriginal,
    getImageRegular,
} from '../../../../../../common/elements/utils/elementPropertyUtils';

export const getSelectedImage = (selectedElements: Immutable.List<ImMNElement>): string | null => {
    const firstSelectedElement = selectedElements.first();

    if (!firstSelectedElement) return null;

    return (
        // Temporarily uploading image data
        firstSelectedElement.getIn(['attachment', 'data']) ||
        getImageRegular(firstSelectedElement) ||
        getImageOriginal(firstSelectedElement)
    );
};

export const getSelectedIcon = (selectedElements: Immutable.List<ImMNElement>): Icon | null => {
    const firstSelectedElement = selectedElements.first();

    if (!firstSelectedElement) return null;

    return getIcon(firstSelectedElement);
};
