// Lib
import React, { useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';

// Utils
import { isTitleRow } from './reactionPopupUtil';

// Hooks
import useListResizeHandlers from './hooks/useListResizeHandlers';
import useReactionPopupData from './hooks/useReactionPopupData';
import { UseSheetReturnType } from '../../../mobile/structural/sheet/hooks/useSheet';

// Components
import ReactionPopupRow from './ReactionPopupRow';
import SheetSearchBar from '../../../mobile/structural/sheet/searchBar/SheetSearchBar';
import SheetContainer, {
    CloseButton,
    Content,
    Header,
    Heading,
    NonDraggableArea,
    Overlay,
    Sheet,
} from '../../../mobile/structural/sheet';
import EmojiImage from '../EmojiImage';
import Spinner from '../../loaders/Spinner';

// Types
import { EmojisRowData } from '../emojiTypes';

// Constants
import { DEFAULT_COLUMN_COUNT } from './reactionPopupConstants';

// Styles
import './ReactionPopup.scss';
import './MobileReactionSheet.scss';

export const TITLE_ROW_HEIGHT = 53;
export const EMOJI_MIN_SIZE = 44;
export const COLUMN_GAP = 4;
export const REACTION_DEFAULT_SNAP_POINT = 0.5;

export type ReactionPopupContentProps = {
    gridSize: number;
    elementIds: string[];
    closePopup: () => void;
    sheetProps: UseSheetReturnType;
};

const MobileReactionSheet = (props: ReactionPopupContentProps) => {
    const { gridSize, sheetProps, elementIds, closePopup } = props;

    const heightControllerRef = useRef<HTMLDivElement>(null);

    const [columnCount, setColumnCount] = useState(DEFAULT_COLUMN_COUNT);
    const [emojiButtonSize, setEmojiButtonSize] = useState(EMOJI_MIN_SIZE);

    const getRowHeight = (index: number, emojisRowData: EmojisRowData) =>
        isTitleRow(emojisRowData[index]) ? TITLE_ROW_HEIGHT : emojiButtonSize + COLUMN_GAP;

    const {
        emojiDataLoaded,
        emojisRowData,
        handleEmojiListScroll,
        searchFilter,
        setSearchFilter,
        listRef,
        getKeyOfItemAtIndex,
        getHeightOfItemAtIndex,
        handleClickEmoji,
        skinTone,
        selectedReactionIds,
    } = useReactionPopupData(elementIds, closePopup, getRowHeight, columnCount);

    const { listHeight, listWidth } = useListResizeHandlers(
        listRef,
        heightControllerRef,
        setColumnCount,
        setEmojiButtonSize,
        sheetProps.isSheetOpen,
    );

    const scrollToTop = () => {
        listRef.current && listRef.current.scrollTo(0);
    };

    return (
        <SheetContainer
            className="reaction-tool"
            {...sheetProps}
            defaultSnapPoint={REACTION_DEFAULT_SNAP_POINT}
            snapPoints={[0.5, 0.9]}
        >
            <Overlay />
            <Sheet>
                <Header>
                    <Heading>Add reaction</Heading>
                    <CloseButton />
                </Header>
                <SheetSearchBar searchFilter={searchFilter} setSearchFilter={setSearchFilter} onChange={scrollToTop} />
                <Content>
                    <NonDraggableArea>
                        <div className="height-controller" ref={heightControllerRef}>
                            {emojiDataLoaded && emojisRowData.length ? (
                                <List
                                    className="emoji-list"
                                    onScroll={handleEmojiListScroll}
                                    ref={listRef}
                                    width={listWidth}
                                    height={listHeight}
                                    itemCount={emojisRowData.length}
                                    itemSize={getHeightOfItemAtIndex}
                                    itemKey={getKeyOfItemAtIndex}
                                    itemData={{
                                        rows: emojisRowData,
                                        skinTone,
                                        selectedReactionIds,
                                        handleClickEmoji,
                                        gridSize,
                                        emojiButtonSize,
                                    }}
                                    overscanCount={10}
                                >
                                    {ReactionPopupRow}
                                </List>
                            ) : (
                                <div className="no-results">
                                    {emojiDataLoaded ? (
                                        <div className="no-results-text none-found">
                                            No emoji found <EmojiImage emojiCode="1F614" size={24} />
                                        </div>
                                    ) : (
                                        <div className="no-results-text spinner">
                                            <Spinner show />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </NonDraggableArea>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};

export default MobileReactionSheet;
