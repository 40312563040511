// Lib
import React, { ReactElement } from 'react';

// Utils
import { getSelectedIcon, getSelectedImage } from '../iconToolUtils';

// Hooks
import useSheet from '../../../../../../mobile/structural/sheet/hooks/useSheet';

// Components
import IconToolImage from '../popup/IconToolImage';
import MobileIconSheet from './MobileIconSheet';
import ElementIcon from '../../../../../../components/elementIcon/ElementIcon';

// Types
import { SheetId } from '../../../../../../mobile/structural/sheet/sheetTypes';
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

// Styles
import './MobileIconTool.scss';

interface MobileIconToolProps {
    selectedElements: Immutable.List<ImMNElement>;
    gridSize: number;
}

const MobileIconTool = (props: MobileIconToolProps): ReactElement => {
    const { selectedElements } = props;

    const sheetProps = useSheet(SheetId.IconTool);
    const { dispatchOpenSheet } = sheetProps;

    const selectedImage = getSelectedImage(selectedElements);
    const selectedIcon = getSelectedIcon(selectedElements);

    const iconElement = selectedImage ? (
        <IconToolImage {...props} imageSource={selectedImage} />
    ) : (
        <ElementIcon {...props} icon={selectedIcon} />
    );

    return (
        <div className="Tool ToolbarTool MobileIconTool">
            <div className="contents custom-mobile-tool" onClick={dispatchOpenSheet}>
                <div className="icon-container">{iconElement}</div>
                <span className="tool-name">Icon</span>
            </div>
            <MobileIconSheet sheetProps={sheetProps} selectedElements={selectedElements} />
        </div>
    );
};

export default MobileIconTool;
