// Lib
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import classNames from 'classnames';

import SheetContainer, { Content, Overlay, Sheet } from '../../../mobile/structural/sheet';
import useSheet from '../../../mobile/structural/sheet/hooks/useSheet';
import { SheetId } from '../../../mobile/structural/sheet/sheetTypes';

export const AuthSheet: React.FC<{ className: string }> = ({ children, className }) => {
    const sheetProps = useSheet(SheetId.Auth);

    const closeRoute = '/';
    const dispatch = useDispatch();
    const onCloseTransitionStart = useCallback(() => {
        dispatch(push(closeRoute));
    }, [closeRoute]);

    return (
        <SheetContainer {...sheetProps} openByDefault onCloseTransitionStart={onCloseTransitionStart}>
            <Overlay />
            <Sheet className="AuthSheetContainer AuthModal">
                <Content>
                    <div className="PageContent modal-content StyledPageContent">
                        <div className={classNames('AuthSheetContents', 'AuthModal', className)}>{children}</div>
                    </div>
                </Content>
            </Sheet>
        </SheetContainer>
    );
};
