// Lib
import { push, replace, goBack, goForward } from 'react-router-redux';
import { isEmpty, get, pick, omit, startsWith } from 'lodash/fp';
import { Capacitor } from '@capacitor/core';

import getClientConfig from '../utils/getClientConfig';

import { slugify, createQueryString } from '../../common/utils/stringUtil';

import { deselectAllElements, finishEditingElement } from '../element/selection/selectionActions';

import { buildUrl, decodeParams, getIsMilanoteDomain } from '../../common/utils/urlUtil';
import { getPhysicalId } from '../../common/elements/utils/elementPropertyUtils';

import { getUtmDataFromObject } from '../../common/utils/utmUtils';
import { getLocationPathname, getLocationQuery, getLocationSearch, getUrlInviteCode } from '../app/routingSelector';
import { updateCurrentUser } from '../user/currentUserActions';
import { getCurrentUserRootBoardId, isGuestSelector } from '../user/currentUserSelector';
import logger from '../logger/logger';
import { getCurrentBoardId } from './currentBoardId/currentBoardIdSelector';
import { elementFilterDataSelector } from '../element/elementFilter/elementFilterSelector';
import { clearBoardsFromCanvasReturnState, getCanvasReturnState } from '../canvas/canvasReturnStateService';
import { getSelectedElementIds } from '../element/selection/selectedElementsSelector';
import { length } from '../../common/utils/immutableHelper';
import { getElements } from '../element/selectors/elementsSelector';
import { getElement } from '../../common/elements/utils/elementTraversalUtils';
import { getClosestPermissionIdForElementIdSelector } from '../utils/permissions/permissionsSelector';
import { isCurrentVersionSafeSelector } from './version/versionSelector';
import { isPublishPreviewModeEnabledSelector } from '../workspace/preview/previewSelector';
import { isPlatformNativeApp } from '../platform/utils/platformDetailsUtils';
import { getPlatformDetailsSelector } from '../platform/platformSelector';
import { clearCachedReduxState } from '../offline/cache/localCache';
import { getCurrentBoardElementCountAttributes } from '../analytics/timingService/analyticsTimingServiceAttributeManager';
import { fetchBoardsFromLocalCache } from '../element/board/boardCacheService';
import { NavigationPlugin } from '../../capacitor_plugins/navigation';

// Analytics
import * as analyticsTimingService from '../analytics/timingService/analyticsTimingService';
import { NewRelicPageActions } from '../analytics/newRelicUtils';

// Constants
import { APP_QUERY_PARAMS, ELEMENT_MODAL_SUB_PATH } from '../../common/utils/urlConstants';
import { PUBLISH_PREVIEW_PATHNAME } from '../workspace/preview/previewConstants';
import { ELEMENT_QUERY_PARAMS_ARRAY } from '../element/elementFilter/elementFilterConstants';
import { CAPACITOR_NAVIGATION_PLUGIN } from '../../capacitor_plugins/pluginConstants';

export const refreshPage = () => window.location.reload(false);
export const fullPageNavigation = (url) => window.location.assign(url);
export const openInNewTab = (url) => window.open(url, '_blank');

const clientConfig = getClientConfig();

export const isSafeRedirectUrl = (url) => {
    if (!url) return false;

    if (startsWith('/', url)) return true;
    if (startsWith(clientConfig.marketingUrl, url)) return true;
    if (getIsMilanoteDomain(url)) return true;

    return false;
};

/**
 * Performs a safe page redirection.
 * Needs to be safe to prevent malicious sites from trying to login using the Milanote login page and then trick them
 * into thinking that they are within Milanote after the redirection.
 */
export const safeRedirectTo = (url) => {
    // If url is not relative or a Milanote URL then ignore it
    if (!isSafeRedirectUrl(url)) {
        url && logger.warn('The following URL was deemed unsafe to redirect to', url);
        return;
    }

    fullPageNavigation(url);
};

const appendQueryString = (path, queryParams) => {
    const queryString = createQueryString(queryParams);

    return queryString.length ? `${path}?${queryString}` : path;
};

export const navigateBack = () => goBack();
export const navigateForward = () => goForward();

export const navigateToLogin = (queryParams = {}) => push(appendQueryString('/login', queryParams));
export const navigateToRegister = (queryParams = {}) => push(appendQueryString('/register', queryParams));
export const navigateToRegisterTerms = (queryParams = {}) => push(appendQueryString('/register-terms', queryParams));
export const navigateToAdminConsole = () => push('/admin');

export const navigateToUrl = (url) => push(url);
export const navigateReplaceUrl = (url) => replace(url);
export const navigateToForgotPassword = () => push('/forgotten-password');
export const navigateToAccountSettings = () => push('/account/settings');
export const navigateToAccountNotificationSettings = () => push('/account/notifications');
export const navigateToSegmentPicker = () => replace('/onboarding/segment');
export const navigateToMobileOnboardingIntro = () => push('/onboarding/mobile');
export const navigateToOnboardingExamples = () => push('/onboarding/intro');
export const navigateToEmailVerification = () => push('/email-verification-required');
export const navigateToFreePlanUpgrade = ({ featureHighlight }) =>
    featureHighlight ? replace(`/free-plan/upgrade?feature=${featureHighlight}`) : replace('/free-plan/upgrade');

export const REFERRAL_MODAL_PATH = '/free-plan/refer';

export const COMPOSE_EMAIL_PATH = '/compose/email';
export const navigateToComposeEmail = (queryParams) =>
    push({
        pathname: COMPOSE_EMAIL_PATH,
        search: `?${createQueryString(queryParams)}`,
    });

export const SELECT_GOOGLE_CONTACTS_PATH = '/compose/select-google-contacts';
export const navigateToSelectGoogleContacts = (queryParams) =>
    push({
        pathname: SELECT_GOOGLE_CONTACTS_PATH,
        search: `?${createQueryString(queryParams)}`,
    });

export const redirectTo = (path) => (dispatch, getState) => dispatch(replace(path));

/**
 * Updates the route to the new board's location.
 */
export const navigateToBoard =
    ({
        boardId,
        filter = null,
        replaceUrl = false,
        keepSelection = false,
        resetScroll = false,
        newTab = false,
        trackTiming = true,
    }) =>
    async (dispatch, getState) => {
        // Prior to navigating to a board, trigger a retrieval of the board data from the local cache (if required)
        dispatch(fetchBoardsFromLocalCache([boardId]));

        if (trackTiming) {
            const analyticsAttributes = getCurrentBoardElementCountAttributes('previousBoard');
            analyticsTimingService.startOperation(NewRelicPageActions.BOARD_NAVIGATION, analyticsAttributes);
        }

        const navigationMethod = replaceUrl ? replace : push;

        if (!boardId) return dispatch(navigationMethod('/'));

        const state = getState();
        const board = state.getIn(['elements', boardId]);

        const title = board && board.getIn(['content', 'title']);

        const slug = title ? `/${slugify(title)}` : '';

        const locationQuery = getLocationQuery(state);

        let queryParams = {};

        if (filter) {
            queryParams = filter;
        } else {
            const elementFilterData = elementFilterDataSelector(state, {});
            const targetBoardChangeCount = get([boardId, 'changeCount'], elementFilterData);

            if (targetBoardChangeCount) {
                queryParams = locationQuery;
            }
        }

        const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId: boardId });

        // Force the permission ID onto the URL in case of refresh
        if (permissionId) {
            queryParams[APP_QUERY_PARAMS.PERMISSION] = permissionId;
        }

        // Maintain the invite code if necessary
        // NOTE: This seems a little dodgy (do we really want to list all the params we might need??)
        //      - might be worth revisiting at some stage
        const inviteCode = getUrlInviteCode(state);
        if (inviteCode && isGuestSelector(state)) {
            queryParams[APP_QUERY_PARAMS.INVITE_CODE] = inviteCode;
        }

        const utmParams = getUtmDataFromObject(locationQuery);
        if (!isEmpty(utmParams)) {
            queryParams = {
                ...queryParams,
                ...utmParams,
            };
        }

        const isPublishPreviewMode = isPublishPreviewModeEnabledSelector(state);
        const publishPath = isPublishPreviewMode ? `${PUBLISH_PREVIEW_PATHNAME}/` : '';

        const path = buildUrl(`/${publishPath}${boardId}${slug}`, queryParams);

        if (!keepSelection) dispatch(deselectAllElements());

        if (resetScroll) {
            // If zoom is enabled, only reset scroll when navigating "down" the board and user is zoomed in/not zoomed
            const boardReturnState = getCanvasReturnState(boardId);
            if (boardReturnState?.zoomScale >= 1) clearBoardsFromCanvasReturnState(boardId);
        }

        if (newTab) return openInNewTab(path);

        const platformDetails = getPlatformDetailsSelector(state);

        // Force a refresh on navigation if the current version of the app is not safe anymore
        // NOTE: Don't do this on the hybrid apps because they won't update on page refresh
        if (!keepSelection && !isPlatformNativeApp(platformDetails) && !isCurrentVersionSafeSelector(state)) {
            history.pushState(null, null, path);
            return refreshPage();
        }

        await dispatch(navigationMethod(path));

        if (Capacitor.isPluginAvailable(CAPACITOR_NAVIGATION_PLUGIN)) {
            NavigationPlugin.onNavigate();
        }

        return;
    };

export const navigateToCurrentBoard =
    ({ replaceUrl } = {}) =>
    (dispatch, getState) => {
        const state = getState();
        const currentBoardId = getCurrentBoardId(state);
        const rootBoardId = getCurrentUserRootBoardId(state);

        const boardId = currentBoardId || rootBoardId;

        return dispatch(navigateToBoard({ boardId, replaceUrl }));
    };

export const refreshPageAndClearLocalCache = () => async (dispatch) => {
    await clearCachedReduxState();

    return refreshPage();
};

export const navigateToElement =
    ({ elementId, params }) =>
    (dispatch, getState) => {
        dispatch(finishEditingElement(elementId));

        // Need to wait for the element to finish its pending update before transitioning.
        // E.g. If a card element has unsaved changes, we must wait for the card to save the content
        // then navigate to the open modal
        requestAnimationFrame(() => {
            let queryParams = params ? `?${createQueryString(params)}` : '';

            const state = getState();
            const permissionId = getClosestPermissionIdForElementIdSelector()(state, { elementId });

            // Force the permission ID onto the URL in case of refresh
            if (permissionId) {
                const hasParams = !isEmpty(queryParams);
                const separator = hasParams ? '&' : '?';
                queryParams = `${queryParams}${separator}${APP_QUERY_PARAMS.PERMISSION}=${permissionId}`;
            }

            const isPublishPreviewMode = isPublishPreviewModeEnabledSelector(state);
            const publishPath = isPublishPreviewMode ? `/${PUBLISH_PREVIEW_PATHNAME}` : '';

            dispatch(push(`${publishPath}/${ELEMENT_MODAL_SUB_PATH}/${elementId}${queryParams}`));
        });
    };

export const clearFilter =
    (clearFilterFields = ELEMENT_QUERY_PARAMS_ARRAY) =>
    (dispatch, getState) => {
        const state = getState();
        const queryState = getLocationQuery(state);
        const filterState = pick(clearFilterFields, queryState);
        if (isEmpty(filterState)) return;

        const pathname = getLocationPathname(state);

        const remainingQuery = omit(clearFilterFields, queryState);
        const url = buildUrl(pathname, remainingQuery);

        return dispatch(push(url));
    };

export const navigateToRootWorkspace =
    (replaceUrl = false) =>
    (dispatch, getState) => {
        const state = getState();

        const redirectFrom = getLocationQuery(state).from;
        if (redirectFrom) return dispatch(push(decodeURIComponent(redirectFrom)));

        const rootBoardId = getCurrentUserRootBoardId(state);
        if (rootBoardId) return dispatch(navigateToBoard({ boardId: rootBoardId, resetScroll: true }));

        replaceUrl ? dispatch(replace('/')) : dispatch(push('/'));
    };

/**
 * Replaces the current URL for a board to update the slug or or permission ID.
 */
export const replaceBoardUrl =
    ({ boardId, slug = '', permissionId }) =>
    (dispatch, getState) => {
        const state = getState();

        const urlQuery = getLocationSearch(state);
        const queryParams = decodeParams(urlQuery);

        // If the slug and the permission ID are already set then just ignore
        const ignoreUpdate = isEmpty(slug) && queryParams[APP_QUERY_PARAMS.PERMISSION] === permissionId;

        if (ignoreUpdate) return;

        const isPublishPreviewMode = isPublishPreviewModeEnabledSelector(state);
        const publishPath = isPublishPreviewMode ? `${PUBLISH_PREVIEW_PATHNAME}/` : '';

        queryParams[APP_QUERY_PARAMS.PERMISSION] = permissionId;

        let updatedUrlQuery = createQueryString(queryParams);
        updatedUrlQuery = updatedUrlQuery ? `?${updatedUrlQuery}` : '';

        let path = `/${publishPath}${boardId}`;
        path = slug ? `${path}/${slug}${updatedUrlQuery}` : path;

        return dispatch(replace(path));
    };

// redirects to root workspace, but keeps a flag if the user has been redirected
// from login to avoid redirect loops with mangled auth tokens
export const redirectLoggedInUserToWorkspace = () => (dispatch, getState) => {
    const state = getState();
    if (state.getIn(['app', 'currentUser', 'didRedirect'])) return;

    dispatch(
        updateCurrentUser({
            changes: { didRedirect: true },
            sync: false,
        }),
    );

    dispatch(navigateToRootWorkspace());
};

export const navigateToSelectedBoardInNewTab = () => (dispatch, getState) => {
    const state = getState();
    const selectedElementIds = getSelectedElementIds(state);

    if (length(selectedElementIds) !== 1) return;

    const selectedElementId = selectedElementIds.first();
    const elements = getElements(state);
    const element = getElement(elements, selectedElementId);

    const boardId = getPhysicalId(element);

    dispatch(
        navigateToBoard({
            boardId,
            newTab: true,
        }),
    );
};
