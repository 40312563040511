// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { getDueDate, getElementId, getShowDueDate } from '../../../../../../common/elements/utils/elementPropertyUtils';
import { getDueDateClass, getDueDatePopupId } from '../assignmentUiUtils';

// Actions
import { closePopup } from '../../../../../components/popupPanel/popupActions';

// Selectors
import { getMilanoteApplicationModeSelector } from '../../../../../platform/platformSelector';

// Components
import TaskPill from '../DesktopTaskPill';
import TaskDueDatePopup from './TaskDueDatePopup';
import TaskPillContents from './TaskPillContents';

// Constants
import { MilanoteApplicationMode } from '../../../../../../common/platform/platformTypes';

// Styles
import './TaskDueDatePill.scss';

const TaskDueDatePill = (props) => {
    const { className, element, isEditable } = props;

    const appMode = useSelector(getMilanoteApplicationModeSelector);
    const shouldBlockEdits = appMode === MilanoteApplicationMode.mobileLegacy;

    const elementId = getElementId(element);
    const popupId = getDueDatePopupId(elementId);

    const dispatch = useDispatch();
    const closeDueDatePopup = (closeAll) => {
        dispatch(closePopup(popupId, closeAll));
    };
    const hasDueDate = getShowDueDate(element) && !!getDueDate(element);

    return (
        <TaskPill
            className={classNames('TaskDueDatePill', className, getDueDateClass(element))}
            popupId={popupId}
            elementId={elementId}
            populated={hasDueDate}
            isEditable={isEditable && !shouldBlockEdits}
            popup={<TaskDueDatePopup popupId={popupId} closePopup={closeDueDatePopup} elementId={elementId} />}
        >
            <TaskPillContents element={element} />
        </TaskPill>
    );
};

TaskDueDatePill.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    element: PropTypes.object.isRequired,
    isEditable: PropTypes.bool,
};

export default TaskDueDatePill;
