// Lib
import React, { ReactElement, ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { capitalize } from 'lodash';

// Styles
import './PortalMountPoint.scss';

type PortalComponentContextArgs = {
    mountPointRef: React.RefObject<HTMLDivElement>;
};

interface PortalProps {
    children: ReactNode;
}

export enum PortalName {
    Sheet = 'sheet',
    Toast = 'toast',
}

export const createPortalComponent = (portalName: string) => {
    const PortalContext = React.createContext<PortalComponentContextArgs>({
        mountPointRef: React.createRef<HTMLDivElement>(),
    });

    const usePortalContext = () => useContext(PortalContext);

    /**
     * This allows the content to be rendered within the portal.
     */
    const Portal = (props: PortalProps): ReactElement | null => {
        const { children } = props;

        const { mountPointRef } = usePortalContext();

        if (!mountPointRef.current) return null;

        return createPortal(children, mountPointRef.current);
    };

    /**
     * This creates the div that will be used to mount the portal content.
     */
    const PortalMountPoint = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
        const { mountPointRef } = usePortalContext();

        return (
            <div
                {...props}
                ref={mountPointRef}
                className={classNames('PortalMountPoint', `${capitalize(portalName)}PortalMountPoint`)}
            />
        );
    };

    return {
        Portal,
        PortalMountPoint,
    };
};
