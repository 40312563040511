// Lib
import { toLower } from 'lodash';
import { ParametricSelector } from 'reselect';
import { createDeepSelector } from '../../../../../../utils/milanoteReselect/milanoteReselect';

// Selectors
import {
    selectedElementTitleSelector,
    selectLetterIcons,
    selectOrderedSavedSearches,
    selectPojoIconSearchResults,
} from '../popup/iconPopupSelector';

// Constants
import { NUMBER_ICONS, STRING_ICONS } from '../popup/iconsPopupConstants';
import { LETTERS_CATEGORY, RECOMMENDED_CATEGORY } from '../iconToolConstants';

// Types
import {
    ElementIcon,
    ElementStringIcon,
    IconSearchResults,
} from '../../../../../../components/elementIcon/elementIconTypes';
import { IconUiCategory } from '../iconToolTypes';

export const selectMobileIconList = () =>
    createDeepSelector(
        selectedElementTitleSelector,
        (state: any, { slug }: { slug: string }) => slug,
        (state: any, { title }: { title: string }) => title,
        selectLetterIcons,
        selectPojoIconSearchResults as ParametricSelector<any, unknown, IconSearchResults>,
        (
            selectedElementTitle: string,
            slug: string,
            title: string,
            lettersIcons: ElementStringIcon[],
            searchResults: IconSearchResults,
        ): ElementIcon[] | undefined => {
            switch (slug) {
                case IconUiCategory.DEFAULT: {
                    const selectedElementTitleLower = toLower(selectedElementTitle);

                    if (!searchResults[selectedElementTitleLower]) return undefined;

                    return (
                        (searchResults[selectedElementTitleLower] as ElementIcon[])
                            .slice(0, 8)
                            .concat(...lettersIcons)
                            .concat(...NUMBER_ICONS)
                            .slice(0, 8) || lettersIcons
                    );
                }
                case IconUiCategory.LETTERS:
                    return lettersIcons.concat(NUMBER_ICONS).concat(STRING_ICONS);
                case IconUiCategory.IMAGES:
                    return [];
                default: {
                    const titleLower = toLower(title);
                    return searchResults[titleLower];
                }
            }
        },
    );

type IconMode = {
    slug: string;
    title: string;
};

export const selectMobileIconModes = createDeepSelector(
    selectOrderedSavedSearches,
    (orderedSavedSearches: string[]): IconMode[] => {
        const customSearches = orderedSavedSearches
            .map((search) => ({
                title: search,
                slug: search,
            }))
            .slice(0, 5);

        return [...customSearches, RECOMMENDED_CATEGORY, LETTERS_CATEGORY];
    },
);
