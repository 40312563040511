import sheetConfig from './sheetConfig';

import { SheetActionTypes, SheetCloseAction, SheetOpenAction } from './sheetActionTypes';
import { SheetId } from './sheetTypes';

export const openSheet = (sheetId: SheetId, sheetKey?: string): SheetOpenAction => ({
    type: SheetActionTypes.SHEET_OPEN,
    sheetId,
    sheetKey: sheetKey || sheetId,
    config: sheetConfig[sheetId],
});

export const closeSheet = (sheetId: SheetId, sheetKey?: string): SheetCloseAction => ({
    type: SheetActionTypes.SHEET_CLOSE,
    sheetId,
    sheetKey: sheetKey || sheetId,
});

export const updateSheetActiveSnapPoint = (activeSnapPoint: number, sheetId: SheetId, sheetKey?: string) => ({
    type: SheetActionTypes.SHEET_UPDATE_ACTIVE_SNAP_POINT,
    sheetId,
    sheetKey: sheetKey || sheetId,
    activeSnapPoint,
});
