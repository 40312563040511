/*eslint-disable */


import AiAdvance from './jsx/ai-advance';
import AiAuto from './jsx/ai-auto';
import AiBack from './jsx/ai-back';
import AiHistory from './jsx/ai-history';
import AiRefresh from './jsx/ai-refresh';
import AiSubmit from './jsx/ai-submit';
import AlignBottom from './jsx/align-bottom';
import AlignCenterHoriz from './jsx/align-center-horiz';
import AlignCenterVert from './jsx/align-center-vert';
import AlignLeft from './jsx/align-left';
import AlignRight from './jsx/align-right';
import AlignTop from './jsx/align-top';
import BlockStyleTick from './jsx/block-style-tick';
import BoardAccessDenied from './jsx/board-access-denied';
import BoardLink from './jsx/board-link';
import BoardPasswordProtected from './jsx/board-password-protected';
import BoardPublished from './jsx/board-published';
import BoardSharedUsers from './jsx/board-shared-users';
import BoardShortcut from './jsx/board-shortcut';
import BoardStatusCommentOnly from './jsx/board-status-comment-only';
import BoardStatusReadOnly from './jsx/board-status-read-only';
import BoardTemplate from './jsx/board-template';
import ButtonToggleDown from './jsx/button-toggle-down';
import ButtonToggleTop from './jsx/button-toggle-top';
import CellTypeAuto from './jsx/cell-type-auto';
import CellTypeCheckbox from './jsx/cell-type-checkbox';
import CellTypeCurrency from './jsx/cell-type-currency';
import CellTypeDatetime from './jsx/cell-type-datetime';
import CellTypeNumber from './jsx/cell-type-number';
import CellTypePercentage from './jsx/cell-type-percentage';
import CellTypeProgress from './jsx/cell-type-progress';
import CellTypeRating from './jsx/cell-type-rating';
import CellTypeText from './jsx/cell-type-text';
import CheckboxAnimated from './jsx/checkbox-animated';
import CheckboxChecked from './jsx/checkbox-checked';
import CheckboxTick from './jsx/checkbox-tick';
import CheckboxUnchecked from './jsx/checkbox-unchecked';
import ChevronLeftLarge from './jsx/chevron-left-large';
import ChevronLeft from './jsx/chevron-left';
import ChevronRightLarge from './jsx/chevron-right-large';
import ChevronRightOrange from './jsx/chevron-right-orange';
import ChevronRight from './jsx/chevron-right';
import Clock from './jsx/clock';
import CloneOutlined from './jsx/clone-outlined';
import Clone from './jsx/clone';
import CloseXSmall from './jsx/close-x-small';
import CloseX from './jsx/close-x';
import ColorWheel from './jsx/color-wheel';
import ColumnCollapse from './jsx/column-collapse';
import ColumnExpand from './jsx/column-expand';
import CommentPin from './jsx/comment-pin';
import ContentEmpty from './jsx/content-empty';
import ContextMenuCamera from './jsx/context-menu-camera';
import ContextMenuCard from './jsx/context-menu-card';
import ContextMenuCopy from './jsx/context-menu-copy';
import ContextMenuCut from './jsx/context-menu-cut';
import ContextMenuDuplicate from './jsx/context-menu-duplicate';
import ContextMenuGroup from './jsx/context-menu-group';
import ContextMenuLock from './jsx/context-menu-lock';
import ContextMenuOrderBack from './jsx/context-menu-order-back';
import ContextMenuOrderFront from './jsx/context-menu-order-front';
import ContextMenuPaste from './jsx/context-menu-paste';
import ContextMenuRedo from './jsx/context-menu-redo';
import ContextMenuSelectAll from './jsx/context-menu-select-all';
import ContextMenuTaskList from './jsx/context-menu-task-list';
import ContextMenuTrash from './jsx/context-menu-trash';
import ContextMenuUndo from './jsx/context-menu-undo';
import CrossLarge from './jsx/cross-large';
import CrossSmall from './jsx/cross-small';
import CursorRemoteEraser from './jsx/cursor-remote-eraser';
import CursorRemotePen from './jsx/cursor-remote-pen';
import CursorRemoteSelect from './jsx/cursor-remote-select';
import DistributeHorizontally from './jsx/distribute-horizontally';
import DistributeVertically from './jsx/distribute-vertically';
import Drag from './jsx/drag';
import DrawingEditorRedo from './jsx/drawing-editor-redo';
import DrawingEditorStroke1 from './jsx/drawing-editor-stroke-1';
import DrawingEditorStroke2 from './jsx/drawing-editor-stroke-2';
import DrawingEditorStroke3 from './jsx/drawing-editor-stroke-3';
import DrawingEditorStroke4 from './jsx/drawing-editor-stroke-4';
import DrawingEditorToolDraw from './jsx/drawing-editor-tool-draw';
import DrawingEditorToolErase from './jsx/drawing-editor-tool-erase';
import DrawingEditorToolSelect from './jsx/drawing-editor-tool-select';
import DrawingEditorUndo from './jsx/drawing-editor-undo';
import DrawingEmptyPlaceholder from './jsx/drawing-empty-placeholder';
import DrawingInputApplePencil from './jsx/drawing-input-apple-pencil';
import DrawingInputTouch from './jsx/drawing-input-touch';
import DropdownArrow from './jsx/dropdown-arrow';
import ElementAudio from './jsx/element-audio';
import ElementBoard from './jsx/element-board';
import ElementCard from './jsx/element-card';
import ElementClone from './jsx/element-clone';
import ElementColorSwatch from './jsx/element-color-swatch';
import ElementColumn from './jsx/element-column';
import ElementCommentThread from './jsx/element-comment-thread';
import ElementDocument from './jsx/element-document';
import ElementFile from './jsx/element-file';
import ElementFolder from './jsx/element-folder';
import ElementHeader from './jsx/element-header';
import ElementImageMobile from './jsx/element-image-mobile';
import ElementImage from './jsx/element-image';
import ElementLine from './jsx/element-line';
import ElementLinkMobile from './jsx/element-link-mobile';
import ElementLink from './jsx/element-link';
import ElementMap from './jsx/element-map';
import ElementMiniBoardSelected from './jsx/element-mini-board-selected';
import ElementMiniBoard from './jsx/element-mini-board';
import ElementMiniCardSelected from './jsx/element-mini-card-selected';
import ElementMiniCard from './jsx/element-mini-card';
import ElementMiniColorSwatchSelected from './jsx/element-mini-color-swatch-selected';
import ElementMiniColorSwatch from './jsx/element-mini-color-swatch';
import ElementMiniColumnSelected from './jsx/element-mini-column-selected';
import ElementMiniColumn from './jsx/element-mini-column';
import ElementMiniCommentThreadSelected from './jsx/element-mini-comment-thread-selected';
import ElementMiniCommentThread from './jsx/element-mini-comment-thread';
import ElementMiniDocumentSelected from './jsx/element-mini-document-selected';
import ElementMiniDocument from './jsx/element-mini-document';
import ElementMiniFileSelected from './jsx/element-mini-file-selected';
import ElementMiniFile from './jsx/element-mini-file';
import ElementMiniImageSelected from './jsx/element-mini-image-selected';
import ElementMiniImage from './jsx/element-mini-image';
import ElementMiniLinkSelected from './jsx/element-mini-link-selected';
import ElementMiniLink from './jsx/element-mini-link';
import ElementMiniSketchSelected from './jsx/element-mini-sketch-selected';
import ElementMiniSketch from './jsx/element-mini-sketch';
import ElementMiniTableSelected from './jsx/element-mini-table-selected';
import ElementMiniTable from './jsx/element-mini-table';
import ElementMiniTaskListSelected from './jsx/element-mini-task-list-selected';
import ElementMiniTaskList from './jsx/element-mini-task-list';
import ElementResizeHandle from './jsx/element-resize-handle';
import ElementSketch from './jsx/element-sketch';
import ElementTable from './jsx/element-table';
import ElementTaskList from './jsx/element-task-list';
import ElementVideo from './jsx/element-video';
import Embed from './jsx/embed';
import ErrorAlertRound from './jsx/error-alert-round';
import ErrorAlert from './jsx/error-alert';
import ExportPdf from './jsx/export-pdf';
import ExportText from './jsx/export-text';
import ExportWord from './jsx/export-word';
import ExternalLink from './jsx/external-link';
import FeatureTick from './jsx/feature-tick';
import FileAftereffects from './jsx/file-aftereffects';
import FileFormUpload from './jsx/file-form-upload';
import GestureCopy from './jsx/gesture-copy';
import GestureCut from './jsx/gesture-cut';
import GestureDragCard from './jsx/gesture-drag-card';
import GestureEditImage from './jsx/gesture-edit-image';
import GestureMultiSelect from './jsx/gesture-multi-select';
import GestureNewNote from './jsx/gesture-new-note';
import GesturePaste from './jsx/gesture-paste';
import GestureRedo from './jsx/gesture-redo';
import GestureUndo from './jsx/gesture-undo';
import Graph from './jsx/graph';
import GreenTick from './jsx/green-tick';
import GreenTickEmpty from './jsx/green-tick-empty';
import HeaderEditableLink from './jsx/header-editable-link';
import HeaderPublishedLink from './jsx/header-published-link';
import HelpCenter from './jsx/help-center';
import IconArrow from './jsx/icon-arrow';
import ImageFormUpload from './jsx/image-form-upload';
import ImageHoverReplace from './jsx/image-hover-replace';
import ImageMissing from './jsx/image-missing';
import ImagePopupUpload from './jsx/image-popup-upload';
import InboxEmpty from './jsx/inbox-empty';
import LinkAudioIcon from './jsx/link-audio-icon';
import LinkFail from './jsx/link-fail';
import LinkIcon from './jsx/link-icon';
import LinkMapIcon from './jsx/link-map-icon';
import LinkVideoIcon from './jsx/link-video-icon';
import Lock from './jsx/lock';
import MediaPlayerFullscreen from './jsx/media-player-fullscreen';
import MediaPlayerPause from './jsx/media-player-pause';
import MediaPlayerPlay from './jsx/media-player-play';
import MediaPlayerVolumeLoud from './jsx/media-player-volume-loud';
import MediaPlayerVolumeMute from './jsx/media-player-volume-mute';
import MediaPlayerVolumeQuiet from './jsx/media-player-volume-quiet';
import MenuDownArrow from './jsx/menu-down-arrow';
import MenuEllipsis from './jsx/menu-ellipsis';
import MilanoteLogoLarge from './jsx/milanote-logo-large';
import MilanoteLogoMedium from './jsx/milanote-logo-medium';
import MilanoteLogoSmall from './jsx/milanote-logo-small';
import Minus from './jsx/minus';
import MobileHeaderBackChevron from './jsx/mobile-header-back-chevron';
import MobileToolbarAddElement from './jsx/mobile-toolbar-add-element';
import MobileToolbarMore from './jsx/mobile-toolbar-more';
import MobileToolbarNotifications from './jsx/mobile-toolbar-notifications';
import MobileToolbarSearch from './jsx/mobile-toolbar-search';
import MobileToolbarSettings from './jsx/mobile-toolbar-settings';
import MobileToolbarSupport from './jsx/mobile-toolbar-support';
import ModalToolbarCrop from './jsx/modal-toolbar-crop';
import ModalToolbarDownload from './jsx/modal-toolbar-download';
import ModalToolbarDraw from './jsx/modal-toolbar-draw';
import ModalToolbarOpenNewTab from './jsx/modal-toolbar-open-new-tab';
import ModalToolbarRotate from './jsx/modal-toolbar-rotate';
import MoreEllipsis from './jsx/more-ellipsis';
import NavCreate from './jsx/nav-create';
import NavHelp from './jsx/nav-help';
import NavNotifications from './jsx/nav-notifications';
import NavQuickNotes from './jsx/nav-quick-notes';
import NavRedo from './jsx/nav-redo';
import NavSearch from './jsx/nav-search';
import NavSettings from './jsx/nav-settings';
import NavUndo from './jsx/nav-undo';
import NotificationAnnotation from './jsx/notification-annotation';
import NotificationBoard from './jsx/notification-board';
import NotificationCard from './jsx/notification-card';
import NotificationColor_swatch from './jsx/notification-color_swatch';
import NotificationComment from './jsx/notification-comment';
import NotificationDocument from './jsx/notification-document';
import NotificationFile from './jsx/notification-file';
import NotificationImage from './jsx/notification-image';
import NotificationLink from './jsx/notification-link';
import NotificationLive from './jsx/notification-live';
import NotificationMention from './jsx/notification-mention';
import NotificationNewTemplate from './jsx/notification-new-template';
import NotificationReaction from './jsx/notification-reaction';
import NotificationReminderClock from './jsx/notification-reminder-clock';
import NotificationShare from './jsx/notification-share';
import NotificationSketch from './jsx/notification-sketch';
import NotificationTable from './jsx/notification-table';
import NotificationTaskChecked from './jsx/notification-task-checked';
import NotificationTaskUpdate from './jsx/notification-task-update';
import NotificationTask from './jsx/notification-task';
import NotificationTemplate from './jsx/notification-template';
import NotificationUnshareBoard from './jsx/notification-unshare-board';
import NotificationUnshare from './jsx/notification-unshare';
import NotificationViewed from './jsx/notification-viewed';
import OldNotificationTemplate from './jsx/old-notification-template';
import PaymentPerson from './jsx/payment-person';
import PlayArrow from './jsx/play-arrow';
import Plus from './jsx/plus';
import Pointer from './jsx/pointer';
import Present from './jsx/present';
import PreviewImage from './jsx/preview-image';
import QuickLineArrow from './jsx/quick-line-arrow';
import QuickLineCircle from './jsx/quick-line-circle';
import ReactionAdd from './jsx/reaction-add';
import ReminderBellMini from './jsx/reminder-bell-mini';
import ReminderBell from './jsx/reminder-bell';
import SaveWorkspaceArrow from './jsx/save-workspace-arrow';
import SearchClear from './jsx/search-clear';
import SearchCurrentBoard from './jsx/search-current-board';
import SearchMagnifyingGlass from './jsx/search-magnifying-glass';
import Share from './jsx/share';
import TaskDrag from './jsx/task-drag';
import TaskTrash from './jsx/task-trash';
import TextClipper from './jsx/text-clipper';
import TickGreen from './jsx/tick-green';
import Tick from './jsx/tick';
import ToastDismiss from './jsx/toast-dismiss';
import ToolbarAddColumn from './jsx/toolbar-add-column';
import ToolbarAddRow from './jsx/toolbar-add-row';
import ToolbarAlignBottom from './jsx/toolbar-align-bottom';
import ToolbarAlignCenter from './jsx/toolbar-align-center';
import ToolbarAlignJustified from './jsx/toolbar-align-justified';
import ToolbarAlignLeft from './jsx/toolbar-align-left';
import ToolbarAlignMiddle from './jsx/toolbar-align-middle';
import ToolbarAlignRight from './jsx/toolbar-align-right';
import ToolbarAlignTop from './jsx/toolbar-align-top';
import ToolbarAlignment from './jsx/toolbar-alignment';
import ToolbarArrowLeft from './jsx/toolbar-arrow-left';
import ToolbarArrowRight from './jsx/toolbar-arrow-right';
import ToolbarAssign from './jsx/toolbar-assign';
import ToolbarAutoplay from './jsx/toolbar-autoplay';
import ToolbarBold from './jsx/toolbar-bold';
import ToolbarCamera from './jsx/toolbar-camera';
import ToolbarCaption from './jsx/toolbar-caption';
import ToolbarCardView from './jsx/toolbar-card-view';
import ToolbarCellType from './jsx/toolbar-cell-type';
import ToolbarCenterText from './jsx/toolbar-center-text';
import ToolbarCode from './jsx/toolbar-code';
import ToolbarCollapse from './jsx/toolbar-collapse';
import ToolbarColorModeBackground from './jsx/toolbar-color-mode-background';
import ToolbarColorModeBar from './jsx/toolbar-color-mode-bar';
import ToolbarConnect from './jsx/toolbar-connect';
import ToolbarContextMenu from './jsx/toolbar-context-menu';
import ToolbarDisconnect from './jsx/toolbar-disconnect';
import ToolbarDismiss from './jsx/toolbar-dismiss';
import ToolbarDistribute from './jsx/toolbar-distribute';
import ToolbarDraw from './jsx/toolbar-draw';
import ToolbarDueDate from './jsx/toolbar-due-date';
import ToolbarFormula from './jsx/toolbar-formula';
import ToolbarH1 from './jsx/toolbar-h1';
import ToolbarHighlight from './jsx/toolbar-highlight';
import ToolbarHyperlink from './jsx/toolbar-hyperlink';
import ToolbarImageEdit from './jsx/toolbar-image-edit';
import ToolbarImagePreview from './jsx/toolbar-image-preview';
import ToolbarImage from './jsx/toolbar-image';
import ToolbarIndent from './jsx/toolbar-indent';
import ToolbarItalic from './jsx/toolbar-italic';
import ToolbarLineLabel from './jsx/toolbar-line-label';
import ToolbarLineStyleDash from './jsx/toolbar-line-style-dash';
import ToolbarLineStyleDot from './jsx/toolbar-line-style-dot';
import ToolbarLineStyleSolid from './jsx/toolbar-line-style-solid';
import ToolbarLineWeight1Px from './jsx/toolbar-line-weight-1-px';
import ToolbarLineWeight2Px from './jsx/toolbar-line-weight-2-px';
import ToolbarLineWeight4Px from './jsx/toolbar-line-weight-4-px';
import ToolbarLineWeight from './jsx/toolbar-line-weight';
import ToolbarList from './jsx/toolbar-list';
import ToolbarLock from './jsx/toolbar-lock';
import ToolbarLongform from './jsx/toolbar-longform';
import ToolbarLoop from './jsx/toolbar-loop';
import ToolbarMediaPreview from './jsx/toolbar-media-preview';
import ToolbarMore from './jsx/toolbar-more';
import ToolbarNumlist from './jsx/toolbar-numlist';
import ToolbarQuote from './jsx/toolbar-quote';
import ToolbarReaction from './jsx/toolbar-reaction';
import ToolbarStrikethrough from './jsx/toolbar-strikethrough';
import ToolbarTextColor from './jsx/toolbar-text-color';
import ToolbarTitle from './jsx/toolbar-title';
import ToolbarTransparent from './jsx/toolbar-transparent';
import ToolbarTrashEmpty from './jsx/toolbar-trash-empty';
import ToolbarTrash from './jsx/toolbar-trash';
import ToolbarUnindent from './jsx/toolbar-unindent';
import ToolbarUpload from './jsx/toolbar-upload';
import UserGuest from './jsx/user-guest';
import UtilZoomToActual from './jsx/util-zoom-to-actual';
import UtilZoomToFit from './jsx/util-zoom-to-fit';

export default {
    'ai-advance': AiAdvance,
    'ai-auto': AiAuto,
    'ai-back': AiBack,
    'ai-history': AiHistory,
    'ai-refresh': AiRefresh,
    'ai-submit': AiSubmit,
    'align-bottom': AlignBottom,
    'align-center-horiz': AlignCenterHoriz,
    'align-center-vert': AlignCenterVert,
    'align-left': AlignLeft,
    'align-right': AlignRight,
    'align-top': AlignTop,
    'block-style-tick': BlockStyleTick,
    'board-access-denied': BoardAccessDenied,
    'board-link': BoardLink,
    'board-password-protected': BoardPasswordProtected,
    'board-published': BoardPublished,
    'board-shared-users': BoardSharedUsers,
    'board-shortcut': BoardShortcut,
    'board-status-comment-only': BoardStatusCommentOnly,
    'board-status-read-only': BoardStatusReadOnly,
    'board-template': BoardTemplate,
    'button-toggle-down': ButtonToggleDown,
    'button-toggle-top': ButtonToggleTop,
    'cell-type-auto': CellTypeAuto,
    'cell-type-checkbox': CellTypeCheckbox,
    'cell-type-currency': CellTypeCurrency,
    'cell-type-datetime': CellTypeDatetime,
    'cell-type-number': CellTypeNumber,
    'cell-type-percentage': CellTypePercentage,
    'cell-type-progress': CellTypeProgress,
    'cell-type-rating': CellTypeRating,
    'cell-type-text': CellTypeText,
    'checkbox-animated': CheckboxAnimated,
    'checkbox-checked': CheckboxChecked,
    'checkbox-tick': CheckboxTick,
    'checkbox-unchecked': CheckboxUnchecked,
    'chevron-left-large': ChevronLeftLarge,
    'chevron-left': ChevronLeft,
    'chevron-right-large': ChevronRightLarge,
    'chevron-right-orange': ChevronRightOrange,
    'chevron-right': ChevronRight,
    'clock': Clock,
    'clone-outlined': CloneOutlined,
    'clone': Clone,
    'close-x-small': CloseXSmall,
    'close-x': CloseX,
    'color-wheel': ColorWheel,
    'column-collapse': ColumnCollapse,
    'column-expand': ColumnExpand,
    'comment-pin': CommentPin,
    'content-empty': ContentEmpty,
    'context-menu-camera': ContextMenuCamera,
    'context-menu-card': ContextMenuCard,
    'context-menu-copy': ContextMenuCopy,
    'context-menu-cut': ContextMenuCut,
    'context-menu-duplicate': ContextMenuDuplicate,
    'context-menu-group': ContextMenuGroup,
    'context-menu-lock': ContextMenuLock,
    'context-menu-order-back': ContextMenuOrderBack,
    'context-menu-order-front': ContextMenuOrderFront,
    'context-menu-paste': ContextMenuPaste,
    'context-menu-redo': ContextMenuRedo,
    'context-menu-select-all': ContextMenuSelectAll,
    'context-menu-task-list': ContextMenuTaskList,
    'context-menu-trash': ContextMenuTrash,
    'context-menu-undo': ContextMenuUndo,
    'cross-large': CrossLarge,
    'cross-small': CrossSmall,
    'cursor-remote-eraser': CursorRemoteEraser,
    'cursor-remote-pen': CursorRemotePen,
    'cursor-remote-select': CursorRemoteSelect,
    'distribute-horizontally': DistributeHorizontally,
    'distribute-vertically': DistributeVertically,
    'drag': Drag,
    'drawing-editor-redo': DrawingEditorRedo,
    'drawing-editor-stroke-1': DrawingEditorStroke1,
    'drawing-editor-stroke-2': DrawingEditorStroke2,
    'drawing-editor-stroke-3': DrawingEditorStroke3,
    'drawing-editor-stroke-4': DrawingEditorStroke4,
    'drawing-editor-tool-draw': DrawingEditorToolDraw,
    'drawing-editor-tool-erase': DrawingEditorToolErase,
    'drawing-editor-tool-select': DrawingEditorToolSelect,
    'drawing-editor-undo': DrawingEditorUndo,
    'drawing-empty-placeholder': DrawingEmptyPlaceholder,
    'drawing-input-apple-pencil': DrawingInputApplePencil,
    'drawing-input-touch': DrawingInputTouch,
    'dropdown-arrow': DropdownArrow,
    'element-audio': ElementAudio,
    'element-board': ElementBoard,
    'element-card': ElementCard,
    'element-clone': ElementClone,
    'element-color-swatch': ElementColorSwatch,
    'element-column': ElementColumn,
    'element-comment-thread': ElementCommentThread,
    'element-document': ElementDocument,
    'element-file': ElementFile,
    'element-folder': ElementFolder,
    'element-header': ElementHeader,
    'element-image-mobile': ElementImageMobile,
    'element-image': ElementImage,
    'element-line': ElementLine,
    'element-link-mobile': ElementLinkMobile,
    'element-link': ElementLink,
    'element-map': ElementMap,
    'element-mini-board-selected': ElementMiniBoardSelected,
    'element-mini-board': ElementMiniBoard,
    'element-mini-card-selected': ElementMiniCardSelected,
    'element-mini-card': ElementMiniCard,
    'element-mini-color-swatch-selected': ElementMiniColorSwatchSelected,
    'element-mini-color-swatch': ElementMiniColorSwatch,
    'element-mini-column-selected': ElementMiniColumnSelected,
    'element-mini-column': ElementMiniColumn,
    'element-mini-comment-thread-selected': ElementMiniCommentThreadSelected,
    'element-mini-comment-thread': ElementMiniCommentThread,
    'element-mini-document-selected': ElementMiniDocumentSelected,
    'element-mini-document': ElementMiniDocument,
    'element-mini-file-selected': ElementMiniFileSelected,
    'element-mini-file': ElementMiniFile,
    'element-mini-image-selected': ElementMiniImageSelected,
    'element-mini-image': ElementMiniImage,
    'element-mini-link-selected': ElementMiniLinkSelected,
    'element-mini-link': ElementMiniLink,
    'element-mini-sketch-selected': ElementMiniSketchSelected,
    'element-mini-sketch': ElementMiniSketch,
    'element-mini-table-selected': ElementMiniTableSelected,
    'element-mini-table': ElementMiniTable,
    'element-mini-task-list-selected': ElementMiniTaskListSelected,
    'element-mini-task-list': ElementMiniTaskList,
    'element-resize-handle': ElementResizeHandle,
    'element-sketch': ElementSketch,
    'element-table': ElementTable,
    'element-task-list': ElementTaskList,
    'element-video': ElementVideo,
    'embed': Embed,
    'error-alert-round': ErrorAlertRound,
    'error-alert': ErrorAlert,
    'export-pdf': ExportPdf,
    'export-text': ExportText,
    'export-word': ExportWord,
    'external-link': ExternalLink,
    'feature-tick': FeatureTick,
    'file-aftereffects': FileAftereffects,
    'file-form-upload': FileFormUpload,
    'gesture-copy': GestureCopy,
    'gesture-cut': GestureCut,
    'gesture-drag-card': GestureDragCard,
    'gesture-edit-image': GestureEditImage,
    'gesture-multi-select': GestureMultiSelect,
    'gesture-new-note': GestureNewNote,
    'gesture-paste': GesturePaste,
    'gesture-redo': GestureRedo,
    'gesture-undo': GestureUndo,
    'graph': Graph,
    'green-tick': GreenTick,
    'green-tick-empty': GreenTickEmpty,
    'header-editable-link': HeaderEditableLink,
    'header-published-link': HeaderPublishedLink,
    'help-center': HelpCenter,
    'icon-arrow': IconArrow,
    'image-form-upload': ImageFormUpload,
    'image-hover-replace': ImageHoverReplace,
    'image-missing': ImageMissing,
    'image-popup-upload': ImagePopupUpload,
    'inbox-empty': InboxEmpty,
    'link-audio-icon': LinkAudioIcon,
    'link-fail': LinkFail,
    'link-icon': LinkIcon,
    'link-map-icon': LinkMapIcon,
    'link-video-icon': LinkVideoIcon,
    'lock': Lock,
    'media-player-fullscreen': MediaPlayerFullscreen,
    'media-player-pause': MediaPlayerPause,
    'media-player-play': MediaPlayerPlay,
    'media-player-volume-loud': MediaPlayerVolumeLoud,
    'media-player-volume-mute': MediaPlayerVolumeMute,
    'media-player-volume-quiet': MediaPlayerVolumeQuiet,
    'menu-down-arrow': MenuDownArrow,
    'menu-ellipsis': MenuEllipsis,
    'milanote-logo-large': MilanoteLogoLarge,
    'milanote-logo-medium': MilanoteLogoMedium,
    'milanote-logo-small': MilanoteLogoSmall,
    'minus': Minus,
    'mobile-header-back-chevron': MobileHeaderBackChevron,
    'mobile-toolbar-add-element': MobileToolbarAddElement,
    'mobile-toolbar-more': MobileToolbarMore,
    'mobile-toolbar-notifications': MobileToolbarNotifications,
    'mobile-toolbar-search': MobileToolbarSearch,
    'mobile-toolbar-settings': MobileToolbarSettings,
    'mobile-toolbar-support': MobileToolbarSupport,
    'modal-toolbar-crop': ModalToolbarCrop,
    'modal-toolbar-download': ModalToolbarDownload,
    'modal-toolbar-draw': ModalToolbarDraw,
    'modal-toolbar-open-new-tab': ModalToolbarOpenNewTab,
    'modal-toolbar-rotate': ModalToolbarRotate,
    'more-ellipsis': MoreEllipsis,
    'nav-create': NavCreate,
    'nav-help': NavHelp,
    'nav-notifications': NavNotifications,
    'nav-quick-notes': NavQuickNotes,
    'nav-redo': NavRedo,
    'nav-search': NavSearch,
    'nav-settings': NavSettings,
    'nav-undo': NavUndo,
    'notification-annotation': NotificationAnnotation,
    'notification-board': NotificationBoard,
    'notification-card': NotificationCard,
    'notification-color_swatch': NotificationColor_swatch,
    'notification-comment': NotificationComment,
    'notification-document': NotificationDocument,
    'notification-file': NotificationFile,
    'notification-image': NotificationImage,
    'notification-link': NotificationLink,
    'notification-live': NotificationLive,
    'notification-mention': NotificationMention,
    'notification-new-template': NotificationNewTemplate,
    'notification-reaction': NotificationReaction,
    'notification-reminder-clock': NotificationReminderClock,
    'notification-share': NotificationShare,
    'notification-sketch': NotificationSketch,
    'notification-table': NotificationTable,
    'notification-task-checked': NotificationTaskChecked,
    'notification-task-update': NotificationTaskUpdate,
    'notification-task': NotificationTask,
    'notification-template': NotificationTemplate,
    'notification-unshare-board': NotificationUnshareBoard,
    'notification-unshare': NotificationUnshare,
    'notification-viewed': NotificationViewed,
    'old-notification-template': OldNotificationTemplate,
    'payment-person': PaymentPerson,
    'play-arrow': PlayArrow,
    'plus': Plus,
    'pointer': Pointer,
    'present': Present,
    'preview-image': PreviewImage,
    'quick-line-arrow': QuickLineArrow,
    'quick-line-circle': QuickLineCircle,
    'reaction-add': ReactionAdd,
    'reminder-bell-mini': ReminderBellMini,
    'reminder-bell': ReminderBell,
    'save-workspace-arrow': SaveWorkspaceArrow,
    'search-clear': SearchClear,
    'search-current-board': SearchCurrentBoard,
    'search-magnifying-glass': SearchMagnifyingGlass,
    'share': Share,
    'task-drag': TaskDrag,
    'task-trash': TaskTrash,
    'text-clipper': TextClipper,
    'tick-green': TickGreen,
    'tick': Tick,
    'toast-dismiss': ToastDismiss,
    'toolbar-add-column': ToolbarAddColumn,
    'toolbar-add-row': ToolbarAddRow,
    'toolbar-align-bottom': ToolbarAlignBottom,
    'toolbar-align-center': ToolbarAlignCenter,
    'toolbar-align-justified': ToolbarAlignJustified,
    'toolbar-align-left': ToolbarAlignLeft,
    'toolbar-align-middle': ToolbarAlignMiddle,
    'toolbar-align-right': ToolbarAlignRight,
    'toolbar-align-top': ToolbarAlignTop,
    'toolbar-alignment': ToolbarAlignment,
    'toolbar-arrow-left': ToolbarArrowLeft,
    'toolbar-arrow-right': ToolbarArrowRight,
    'toolbar-assign': ToolbarAssign,
    'toolbar-autoplay': ToolbarAutoplay,
    'toolbar-bold': ToolbarBold,
    'toolbar-camera': ToolbarCamera,
    'toolbar-caption': ToolbarCaption,
    'toolbar-card-view': ToolbarCardView,
    'toolbar-cell-type': ToolbarCellType,
    'toolbar-center-text': ToolbarCenterText,
    'toolbar-code': ToolbarCode,
    'toolbar-collapse': ToolbarCollapse,
    'toolbar-color-mode-background': ToolbarColorModeBackground,
    'toolbar-color-mode-bar': ToolbarColorModeBar,
    'toolbar-connect': ToolbarConnect,
    'toolbar-context-menu': ToolbarContextMenu,
    'toolbar-disconnect': ToolbarDisconnect,
    'toolbar-dismiss': ToolbarDismiss,
    'toolbar-distribute': ToolbarDistribute,
    'toolbar-draw': ToolbarDraw,
    'toolbar-due-date': ToolbarDueDate,
    'toolbar-formula': ToolbarFormula,
    'toolbar-h1': ToolbarH1,
    'toolbar-highlight': ToolbarHighlight,
    'toolbar-hyperlink': ToolbarHyperlink,
    'toolbar-image-edit': ToolbarImageEdit,
    'toolbar-image-preview': ToolbarImagePreview,
    'toolbar-image': ToolbarImage,
    'toolbar-indent': ToolbarIndent,
    'toolbar-italic': ToolbarItalic,
    'toolbar-line-label': ToolbarLineLabel,
    'toolbar-line-style-dash': ToolbarLineStyleDash,
    'toolbar-line-style-dot': ToolbarLineStyleDot,
    'toolbar-line-style-solid': ToolbarLineStyleSolid,
    'toolbar-line-weight-1-px': ToolbarLineWeight1Px,
    'toolbar-line-weight-2-px': ToolbarLineWeight2Px,
    'toolbar-line-weight-4-px': ToolbarLineWeight4Px,
    'toolbar-line-weight': ToolbarLineWeight,
    'toolbar-list': ToolbarList,
    'toolbar-lock': ToolbarLock,
    'toolbar-longform': ToolbarLongform,
    'toolbar-loop': ToolbarLoop,
    'toolbar-media-preview': ToolbarMediaPreview,
    'toolbar-more': ToolbarMore,
    'toolbar-numlist': ToolbarNumlist,
    'toolbar-quote': ToolbarQuote,
    'toolbar-reaction': ToolbarReaction,
    'toolbar-strikethrough': ToolbarStrikethrough,
    'toolbar-text-color': ToolbarTextColor,
    'toolbar-title': ToolbarTitle,
    'toolbar-transparent': ToolbarTransparent,
    'toolbar-trash-empty': ToolbarTrashEmpty,
    'toolbar-trash': ToolbarTrash,
    'toolbar-unindent': ToolbarUnindent,
    'toolbar-upload': ToolbarUpload,
    'user-guest': UserGuest,
    'util-zoom-to-actual': UtilZoomToActual,
    'util-zoom-to-fit': UtilZoomToFit,
};
