// Lib
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';

// Utils
import { getTaskPillsList } from './taskPillsUtils';

// Selectors
import { getCurrentUserId } from '../../../user/currentUserSelector';

// Components
import TaskAssignmentPill from './assignment/TaskAssignmentPill';
import TaskDueDatePill from './assignment/dueDate/TaskDueDatePill';
import TaskReactionPill from './reactions/TaskReactionPill';
import TaskAddReactionButton from './reactions/TaskAddReactionButton';

// Types
import { TaskPillData } from './taskPillsTypes';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';

// Styles
import './TaskPillsPanel.scss';

interface TaskPillsPanelProps {
    elementId: string;
    element: ImMNElement;
    gridSize: number;
    permissions: number;
    isEditable: boolean;
}

interface TaskPillsSwitchProps extends TaskPillsPanelProps {
    taskPillData: TaskPillData;
}

const TaskPillsSwitch = (props: TaskPillsSwitchProps): ReactElement | null => {
    const { taskPillData } = props;

    switch (taskPillData.type) {
        case 'DUE_DATE':
            return <TaskDueDatePill {...props} />;
        case 'ASSIGNMENT':
            return <TaskAssignmentPill {...props} />;
        case 'REACTION':
            return <TaskReactionPill {...props} {...taskPillData} />;
        case 'ADD_REACTION':
            return <TaskAddReactionButton {...props} />;
    }
};

const mapStateToProps = createStructuredSelector({
    currentUserId: getCurrentUserId,
});

const TaskPillsPanel = (props: TaskPillsPanelProps): ReactElement | null => {
    const { element, permissions } = props;

    const taskPills = getTaskPillsList(element, permissions);

    if (isEmpty(taskPills)) return null;

    return (
        <div className="TaskPillsPanel">
            {taskPills.map((taskPillData: TaskPillData) => (
                <TaskPillsSwitch key={taskPillData.id} {...props} taskPillData={taskPillData} />
            ))}
        </div>
    );
};

export default connect(mapStateToProps)(TaskPillsPanel);
