/* eslint-disable max-len */
// Lib
import { clamp } from 'lodash/fp';

// Utils
import { scaleToGrid } from '../../utils/grid/gridUtils';
import { asRect } from '../../../common/maths/geometry/rect';

// Constants
import { HIDDEN_POSITION, TooltipAlignment, TooltipPositions } from './tooltipConstants';
import { GRID } from '../../utils/grid/gridConstants';

// Types
import { Rect } from '../../../common/maths/geometry/rect/rectTypes';
import { TooltipOptions } from './tooltopTypes';
import { MilanoteApplicationMode } from '../../../common/platform/platformTypes';

interface ArrowPosition {
    top: number;
    left: number;
    rotate: number;
    side: TooltipPositions;
}

type TooltipShiftFn = (options: TooltipOptions) => number;

type VerticalPositionFn = (
    targetRect: Rect,
    tipRect: Rect,
    windowRect: Rect,
    options: TooltipOptions,
    getVerticalShift: TooltipShiftFn,
) => number;

type HorizontalPositionFn = (
    targetRect: Rect,
    tipRect: Rect,
    windowRect: Rect,
    options: TooltipOptions,
    getHorizontalShift: TooltipShiftFn,
) => number;

type RectPositionFn = (targetRect: Rect, tipRect: Rect, windowRect: Rect, options: TooltipOptions) => Rect;
type RectPositionFallbackPredicateFn = (
    targetRect: Rect,
    tipRect: Rect,
    windowRect: Rect,
    options: TooltipOptions,
) => boolean;

const ARROW_DEFAULT = {
    top: 0,
    left: 0,
    rotate: 0,
    side: TooltipPositions.TOP,
};

const DEFAULT_INSETS = asRect({
    left: 10,
    right: 10,
    top: 20,
    bottom: 20,
});

const getCenter = (dimensionA: number, dimensionB: number) => dimensionA / 2 - dimensionB / 2;

const clampHorizontal = (windowRect: Rect, tipRect: Rect, insets: Rect) =>
    clamp(insets.left, windowRect.width + windowRect.left - tipRect.width - insets.right);
const clampVertical = (windowRect: Rect, tipRect: Rect, insets: Rect) =>
    clamp(insets.top, windowRect.height + windowRect.top - tipRect.height - insets.bottom);

/* ---- VERTICAL ALIGNMENT ---- */

/**
 * Gets the top dimension for a tooltip that's vertically aligned to the center.
 */
const getVerticalCenterTop: VerticalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getVerticalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampVertical(
        windowRect,
        tipRect,
        insets,
    )(targetRect.top + getCenter(targetRect.height, tipRect.height) + scaledShift + windowRect.top);
};

/**
 * Gets the top dimension for a tooltip that's vertically aligned to the top.
 */
const getVerticalAlignStartTop: VerticalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    // If the tooltip is bigger than the window height, just use the centered vertically logic
    if (tipRect.height > windowRect.height + windowRect.top - (insets.top + insets.bottom)) {
        return getVerticalCenterTop(targetRect, tipRect, windowRect, options, getVerticalShift);
    }

    const shift = getVerticalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    const top = targetRect.top - 2 * gridSize + scaledShift + windowRect.top;

    const bottom = top + tipRect.height;

    const overflow = bottom + insets.bottom - windowRect.height - windowRect.top;

    // If the tooltip would overflow the bottom of the window, shift it up with a margin to keep it inside
    if (overflow > 0) return clamp(insets.top, top - overflow - insets.bottom, Infinity);

    return top;
};

/**
 * Gets the top dimension for a tooltip that's vertically aligned to the bottom.
 */
const getVerticalAlignEndTop: VerticalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    // If the tooltip is bigger than the window height, just use the centered vertically logic
    if (tipRect.height > windowRect.height + windowRect.top - (insets.top + insets.bottom)) {
        return getVerticalCenterTop(targetRect, tipRect, windowRect, options, getVerticalShift);
    }

    const shift = getVerticalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    const bottom = targetRect.bottom + 2 * gridSize - scaledShift;
    const top = bottom - tipRect.height + windowRect.top;

    // If the tooltip would overflow the bottom of the window, shift it up with a margin to keep it inside
    return clamp(insets.top, top, Infinity);
};

const getVerticalAlignmentTop: VerticalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { alignment } = options;

    switch (alignment) {
        case TooltipAlignment.START:
            return getVerticalAlignStartTop(targetRect, tipRect, windowRect, options, getVerticalShift);
        case TooltipAlignment.END:
            return getVerticalAlignEndTop(targetRect, tipRect, windowRect, options, getVerticalShift);
        default:
            return getVerticalCenterTop(targetRect, tipRect, windowRect, options, getVerticalShift);
    }
};

/* ---- HORIZONTAL ALIGNMENT ---- */
const getHorizontalCenterLeft: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getHorizontalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampHorizontal(
        windowRect,
        tipRect,
        insets,
    )(targetRect.left + getCenter(targetRect.width, tipRect.width) - scaledShift);
};

const getHorizontalAlignLeftStart: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS, platformDetails } = options;

    // If is mobile, or the tooltip is bigger than the window height, just use the centered vertically logic
    const isMobile = platformDetails?.appMode === MilanoteApplicationMode.mobile;
    const isTooltipWiderThanWindow = tipRect.width > windowRect.width - (insets.left + insets.right);
    if (isMobile || isTooltipWiderThanWindow) {
        return getHorizontalCenterLeft(targetRect, tipRect, windowRect, options, getHorizontalShift);
    }

    const shift = getHorizontalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    const left = targetRect.left - 2 * gridSize + scaledShift;

    const right = left + tipRect.width;

    const overflow = right + insets.right - windowRect.width;

    // If the tooltip would overflow the bottom of the window, shift it up with a margin to keep it inside
    if (overflow > 0) return clamp(insets.left, left - overflow - insets.right, Infinity);

    return left;
};

const getHorizontalAlignLeftEnd: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS, platformDetails } = options;

    // If is mobile, the tooltip is bigger than the window height, just use the centered vertically logic
    const isMobile = platformDetails?.appMode === MilanoteApplicationMode.mobile;
    const isTooltipWiderThanWindow = tipRect.width > windowRect.width - 2 * (insets.left + insets.right);
    if (isMobile || isTooltipWiderThanWindow) {
        return getHorizontalCenterLeft(targetRect, tipRect, windowRect, options, getHorizontalShift);
    }

    const shift = getHorizontalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    const right = targetRect.right + 2 * gridSize - scaledShift;
    const left = right - tipRect.width;

    // If the tooltip would overflow the bottom of the window, shift it up with a margin to keep it inside
    return clamp(insets.left, left, Infinity);
};

const getHorizontalAlignmentLeft: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { alignment } = options;

    switch (alignment) {
        case TooltipAlignment.START:
            return getHorizontalAlignLeftStart(targetRect, tipRect, windowRect, options, getHorizontalShift);
        case TooltipAlignment.END:
            return getHorizontalAlignLeftEnd(targetRect, tipRect, windowRect, options, getHorizontalShift);
        default:
            return getHorizontalCenterLeft(targetRect, tipRect, windowRect, options, getHorizontalShift);
    }
};

/* ---- POSITIONING ---- */

const getRectLeftPositionLeft: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getHorizontalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampHorizontal(windowRect, tipRect, insets)(targetRect.left - tipRect.width - scaledShift);
};

const getRectLeftPositionRight: HorizontalPositionFn = (
    targetRect,
    tipRect,
    windowRect,
    options,
    getHorizontalShift,
) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getHorizontalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampHorizontal(windowRect, tipRect, insets)(targetRect.right + scaledShift);
};

const getRectTopPositionBottom: HorizontalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getVerticalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampVertical(windowRect, tipRect, insets)(targetRect.bottom + windowRect.top + scaledShift);
};

const getRectTopPositionTop: VerticalPositionFn = (targetRect, tipRect, windowRect, options, getVerticalShift) => {
    const { gridSize = GRID.LARGE.size, insets = DEFAULT_INSETS } = options;

    const shift = getVerticalShift(options);
    const scaledShift = scaleToGrid(shift, gridSize);

    return clampVertical(windowRect, tipRect, insets)(targetRect.top + windowRect.top - tipRect.height - scaledShift);
};

const createRectPositionFn =
    (
        getLeft: HorizontalPositionFn,
        getTop: VerticalPositionFn,
        getVerticalShift: TooltipShiftFn,
        getHorizontalShift: TooltipShiftFn,
    ) =>
    (targetRect: Rect, tipRect: Rect, windowRect: Rect, options: TooltipOptions): Rect => {
        const top = getTop(targetRect, tipRect, windowRect, options, getVerticalShift);
        const left = getLeft(targetRect, tipRect, windowRect, options, getHorizontalShift);

        return {
            x: left,
            y: top,
            top,
            left,
            right: left + tipRect.width,
            bottom: top + tipRect.height,
            width: tipRect.width,
            height: tipRect.height,
        };
    };

const getDistance = (options: TooltipOptions): number =>
    options.distance || options.distance === 0 ? options.distance : 10;
const getOffset = (options: TooltipOptions): number => options.offset || 0;

const getScaledDistance = (options: TooltipOptions) => {
    const { gridSize = GRID.LARGE.size } = options;
    return scaleToGrid(getDistance(options), gridSize);
};

const rectPositionCenter = createRectPositionFn(getHorizontalCenterLeft, getVerticalCenterTop, getDistance, getOffset);
const rectPositionLeft = createRectPositionFn(getRectLeftPositionLeft, getVerticalAlignmentTop, getOffset, getDistance);
const rectPositionRight = createRectPositionFn(
    getRectLeftPositionRight,
    getVerticalAlignmentTop,
    getOffset,
    getDistance,
);
const rectPositionBottom = createRectPositionFn(
    getHorizontalAlignmentLeft,
    getRectTopPositionBottom,
    getDistance,
    getOffset,
);
const rectPositionTop = createRectPositionFn(getHorizontalAlignmentLeft, getRectTopPositionTop, getDistance, getOffset);

// (adding line breaks just made it less clear what is happening)
const getRectPosition =
    (
        positionFn: RectPositionFn,
        positionFallbackFn: RectPositionFn,
        fallbackPredicate: RectPositionFallbackPredicateFn,
    ) =>
    (targetRect: Rect, tipRect: Rect, windowRect: Rect, options: TooltipOptions) => {
        // get tooltip position based on default method
        const rect = positionFn(targetRect, tipRect, windowRect, options);

        // if that method would overlap or display weirdly, use the fallback method for positioning the tooltip instead
        return fallbackPredicate(rect, targetRect, windowRect, options)
            ? positionFallbackFn(targetRect, tipRect, windowRect, options)
            : rect;
    };

// If shifting in a negative direction, allow the tooltip to overlap the target by the shift amount
const leftFallbackPredicate: RectPositionFallbackPredicateFn = (rect, target, windowRect, options) =>
    rect.right > target.left - getScaledDistance(options);
const rightFallbackPredicate: RectPositionFallbackPredicateFn = (rect, target, windowRect, options) =>
    rect.left - getScaledDistance(options) < target.right;
const bottomFallbackPredicate: RectPositionFallbackPredicateFn = (rect, target, windowRect, options) =>
    rect.top - getScaledDistance(options) < target.bottom;
const topFallbackPredicate: RectPositionFallbackPredicateFn = (rect, target, windowRect, options) =>
    rect.bottom > target.top - getScaledDistance(options);

const getRectPositionFn = (position: TooltipPositions) => {
    switch (position) {
        case TooltipPositions.CENTER:
            return rectPositionCenter;
        case TooltipPositions.LEFT:
            return getRectPosition(rectPositionLeft, rectPositionRight, leftFallbackPredicate);
        case TooltipPositions.RIGHT:
            return getRectPosition(rectPositionRight, rectPositionLeft, rightFallbackPredicate);
        case TooltipPositions.BOTTOM:
            return getRectPosition(rectPositionBottom, rectPositionTop, bottomFallbackPredicate);
        case TooltipPositions.TOP:
        default:
            return getRectPosition(rectPositionTop, rectPositionBottom, topFallbackPredicate);
    }
};

export const getTooltipPosition = (targetRect: Rect, tipRect: Rect, options: TooltipOptions = {}): Rect => {
    const { position = TooltipPositions.BOTTOM } = options;

    if (!targetRect)
        return {
            x: HIDDEN_POSITION.left,
            y: HIDDEN_POSITION.top,
            right: HIDDEN_POSITION.left,
            bottom: HIDDEN_POSITION.top,
            left: HIDDEN_POSITION.left,
            top: HIDDEN_POSITION.top,
            width: 0,
            height: 0,
        };

    const windowRect = {
        x: window.scrollX,
        y: window.scrollY,
        top: window.scrollY,
        left: window.scrollX,
        right: window.innerWidth + window.scrollX,
        bottom: window.innerHeight + window.scrollY,
        width: window.innerWidth,
        height: window.innerHeight,
    };

    return getRectPositionFn(position)(targetRect, tipRect, windowRect, options);
};

export const getShouldDisplayArrow = ({
    position,
    showArrow = true,
}: TooltipOptions & { showArrow?: boolean }): boolean => showArrow && position !== TooltipPositions.CENTER;

export const getArrowPosition = (
    targetRect: Rect,
    tipRect: Rect,
    arrowRect: Rect,
    options: TooltipOptions = {},
): ArrowPosition => {
    if (!targetRect || !tipRect) return ARROW_DEFAULT;
    const { offset = 0, gridSize = GRID.LARGE.size } = options;

    const windowRect = {
        top: window.scrollY,
        left: window.scrollX,
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const scaledOffset = scaleToGrid(offset, gridSize);

    if (tipRect.left > targetRect.right) {
        // Arrow pointing left
        return {
            top:
                targetRect.top -
                tipRect.top +
                getCenter(targetRect.height, arrowRect.height) +
                scaledOffset +
                windowRect.top,
            left: -arrowRect.height + 2,
            rotate: 270,
            side: TooltipPositions.RIGHT,
        };
    }

    if (tipRect.right < targetRect.left) {
        // Arrow pointing right
        return {
            top:
                targetRect.top -
                tipRect.top +
                getCenter(targetRect.height, arrowRect.height) +
                scaledOffset +
                scaledOffset +
                windowRect.top,
            left: tipRect.width - 2,
            rotate: 90,
            side: TooltipPositions.LEFT,
        };
    }

    if (tipRect.bottom > targetRect.top) {
        // Arrow pointing up
        return {
            top: -arrowRect.height,
            left:
                targetRect.left -
                tipRect.left +
                getCenter(targetRect.width, arrowRect.width) -
                scaledOffset +
                windowRect.left,
            rotate: 0,
            side: TooltipPositions.BOTTOM,
        };
    }

    // arrow pointing down
    return {
        top: tipRect.height - 2,
        left:
            targetRect.left -
            tipRect.left +
            getCenter(targetRect.width, arrowRect.width) +
            scaledOffset +
            windowRect.left,
        rotate: 180,
        side: TooltipPositions.TOP,
    };
};
