// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { onlyUpdateForPropTypes, compose } from '../../../node_module_clones/recompose';
import { createStructuredSelector } from 'reselect';
import { trim, isString } from 'lodash/fp';

// Utils
import { getTitle } from '../../../common/elements/utils/elementPropertyUtils';
import { preventOrphanedWords, stripOrphanedWordsSpecialCharacters } from '../../../common/utils/stringUtil';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';

// Actions
import { getIsCurrentlyEditingThisElement } from '../../element/selection/currentlyEditingSelector';
import { updateElement } from '../../element/actions/elementActions';
import {
    startEditingElement,
    startEditingElementWithDelay,
    finishEditingElement,
    deselectAllElements,
} from '../../element/selection/selectionActions';

// Components
import ElementSimpleContentEditable from './ElementSimpleContentEditable';

// Styles
import './EditableTitle.scss';

const DEFAULT_MAX_LENGTH = 100;

const getTitleSelector = (state, { element, initialValue }) => getTitle(element) || initialValue;

const titleEditorSelector = createStructuredSelector({
    title: getTitleSelector,
    isEditing: getIsCurrentlyEditingThisElement,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateTitle: (id, title, transactionId) =>
        dispatch(updateElement({ id, changes: { title }, transactionId })),
    dispatchStartEditingTitle: (id, editDelay, editorKey, editorFocusClientCoords) =>
        editDelay
            ? dispatch(startEditingElementWithDelay({ id, editorKey, editDelay, editorFocusClientCoords }))
            : dispatch(startEditingElement({ id, editorKey, editorFocusClientCoords })),
    dispatchStopEditingTitle: (id, keepSelection = false) => {
        dispatch(finishEditingElement(id));
        !keepSelection && dispatch(deselectAllElements());
    },
});

const enhance = compose(connect(titleEditorSelector, mapDispatchToProps), onlyUpdateForPropTypes);

class EditableTitle extends React.Component {
    onStartEditing = (editorFocusClientCoords) => {
        const { editDelay, elementId, dispatchStartEditingTitle, editorKey } = this.props;

        dispatchStartEditingTitle(elementId, editDelay, editorKey, editorFocusClientCoords);
    };

    onStopEditing = ({ keepSelection }) => {
        const { elementId, dispatchStopEditingTitle, onStopEditing: onStopEditingCallbackFn } = this.props;

        if (onStopEditingCallbackFn) onStopEditingCallbackFn({ keepSelection });

        dispatchStopEditingTitle(elementId, keepSelection);
    };

    onUpdate = (title) => {
        const { elementId, dispatchUpdateTitle, onUpdate: onUpdateCallbackFn } = this.props;

        if (!title || !title.length) return;

        if (onUpdateCallbackFn) return onUpdateCallbackFn({ title, transactionId: getNewTransactionId() });

        const updatedTitle = stripOrphanedWordsSpecialCharacters(title);

        if (updatedTitle === this.props.title) return;
        dispatchUpdateTitle(elementId, updatedTitle, getNewTransactionId());
    };

    cleanContent = (content) => {
        const { maxLength = DEFAULT_MAX_LENGTH } = this.props;

        if (!content) return;

        const contentString = isString(content) ? content : content.toString();

        return preventOrphanedWords(trim(contentString.substring(0, maxLength)));
    };

    render() {
        const {
            title,
            isEditing,
            isEditable,
            isSelected,
            selectAllOnEdit,
            selectFirst,
            wrapTitleElement,
            maxLength,
            filterQuery,
        } = this.props;

        const showEditing = isEditable && isEditing;
        return (
            <div className={classNames('EditableTitle', { editing: showEditing })}>
                <ElementSimpleContentEditable
                    multiline
                    className="editable-title"
                    enterKeyHint="Done"
                    isEditing={isEditing}
                    isEditable={isEditable}
                    isSelected={isSelected}
                    selectAllOnEdit={selectAllOnEdit}
                    selectFirst={selectFirst}
                    wrapContent={wrapTitleElement}
                    maxLength={maxLength}
                    filterQuery={filterQuery}
                    onStartEditing={this.onStartEditing}
                    onStopEditing={this.onStopEditing}
                    onUpdate={this.onUpdate}
                    cleanContent={this.cleanContent}
                >
                    {title}
                </ElementSimpleContentEditable>
            </div>
        );
    }
}

EditableTitle.propTypes = {
    elementId: PropTypes.string,
    title: PropTypes.string,
    editorKey: PropTypes.string,
    dispatchUpdateTitle: PropTypes.func,
    dispatchStartEditingTitle: PropTypes.func,
    dispatchStopEditingTitle: PropTypes.func,
    onStopEditing: PropTypes.func,
    onUpdate: PropTypes.func,
    wrapTitleElement: PropTypes.element,
    maxLength: PropTypes.number,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    selectFirst: PropTypes.bool,
    selectAllOnEdit: PropTypes.bool,
    editDelay: PropTypes.number,
    filterQuery: PropTypes.string,
};

export default enhance(EditableTitle);
