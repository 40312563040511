// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash/fp';

// Utils
import { stopPropagationOnly } from '../../utils/domUtil';
import { markEventAsHandled } from '../../utils/react/reactEventUtil';
import { isDomSelectionFocusInside } from '../../utils/dom/domSelectionUtils';
import { hasAltModifier, hasCommandModifier, hasShiftKey } from '../../utils/keyboard/keyboardUtility';
import { getIsSelectionCollapsed, placeCaretAtEnd, selectAll } from '../../utils/keyboard/contentEditable';

// Components
import HighlightedText from '../HighlightedText';
import EditableTitleSearchHighlight from './EditableTitleSearchHighlight';

// Constants
import { KEY_CODES } from '../../utils/keyboard/keyConstants';

// Styles
import './ElementSimpleContentEditable.scss';

class ElementSimpleContentEditable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tempContent: null,
        };

        this.contentRef = React.createRef();
    }

    componentDidMount() {
        this.contentRef.current && this.contentRef.current.addEventListener('paste', this.handlePasteEvent);

        if (this.props.isEditing) this.selectContent();
    }

    componentWillReceiveProps(nextProps) {
        const { tempContent } = this.state;
        const { isEditing } = this.props;

        if (isEditing && !nextProps.isEditing) {
            this.updateContent(this.getContent());
            this.setState({ tempContent: null });
        }

        if (tempContent && !isEditing && nextProps.isEditing) {
            this.setState({ tempContent: null });
        }
    }

    componentDidUpdate(prevProps) {
        const { isEditing } = this.props;

        if (isEditing && !prevProps.isEditing) this.selectContent();
        if (!isEditing && prevProps.isEditing) this.blurContent();
    }

    componentWillUnmount() {
        if (!this.contentRef.current) return;
        this.contentRef.current.removeEventListener('paste', this.handlePasteEvent);

        if (this.props.isEditing) {
            this.updateContent(this.getContent());
        }
    }

    /**
     * Prevent inline styles and handle stop editing shortcuts.
     */
    onKeyDown = (event) => {
        // Prevent inline styles
        if (event.metaKey) {
            if (event.keyCode === KEY_CODES.B || event.keyCode === KEY_CODES.I) {
                event.preventDefault();
            }
        }

        const multilineShiftKey = this.props.multiline && hasShiftKey(event) && getIsSelectionCollapsed();

        // Stop editing on enter or escape
        if ((event.keyCode === KEY_CODES.ENTER && !multilineShiftKey) || event.keyCode === KEY_CODES.ESC) {
            event.preventDefault();

            const keepSelection = hasShiftKey(event) || hasAltModifier(event) || hasCommandModifier(event);

            const content = this.getContent();
            this.setState({ tempContent: content });

            this.props.onStopEditing && this.props.onStopEditing({ content, keepSelection });
        }
        // Handle empty text backspace
        if (event.keyCode === KEY_CODES.BACKSPACE || event.keyCode === KEY_CODES.DELETE) {
            const content = this.getContent();

            if (!content.length) {
                this.props.onEmptyBackspace && this.props.onEmptyBackspace();
            }
        }
    };

    onMouseDown = (event) => {
        // If we're editing this component and the DOM selection is inside this component, then we want to tell the
        // parent component to not perform any mousedown handling because it's already been handled.
        // Previously we were using "event.stopPropagation()" for this purpose, however mousedown stopPropagations
        // result in weird bugs and glitches in the mouse-event DnD TouchBackend as it interferes with its state
        // updates.
        if (this.props.isEditing && isDomSelectionFocusInside(this.contentRef.current)) {
            markEventAsHandled(event);
        }
    };

    /**
     * Prevent double click from firing actions while it's being edited.
     */
    onDoubleClick = (event) => {
        if (this.props.isEditing) stopPropagationOnly(event);
    };

    getContent = () => this.cleanContent(get('innerText', this.contentRef.current)) || '';

    selectContent = () => {
        const { selectAllOnEdit } = this.props;

        if (this.contentRef.current) {
            selectAllOnEdit ? selectAll(this.contentRef.current) : placeCaretAtEnd(this.contentRef.current);
        }
    };

    blurContent = () => {
        this.contentRef.current && this.contentRef.current.blur();
    };

    updateContent = (content) => {
        const { isEditable, onUpdate } = this.props;

        if (!isEditable) return;

        if (!content.length && this.contentRef.current) this.contentRef.current.innerText = this.props.children;

        if (onUpdate) return onUpdate(content);
    };

    /**
     * Ensure the content is editable
     */
    startEditingContent = (event) => {
        const { isEditable, selectFirst, isSelected, onStartEditing } = this.props;

        if (!isEditable) return;
        if (selectFirst && !isSelected) return;

        const contentRect = this.contentRef.current?.getBoundingClientRect();

        const editorFocusClientCoords = contentRect && {
            x: contentRect.x,
            y: contentRect.y,
        };

        onStartEditing(editorFocusClientCoords);
    };

    handlePasteEvent = (event) => {
        // cancel paste
        event.preventDefault();

        // get text representation of clipboard
        const text = (event.originalEvent || event).clipboardData.getData('text/plain');

        // insert text manually
        document.execCommand('insertHTML', false, this.cleanContent(text));
    };

    cleanContent = (content) => {
        const { cleanContent } = this.props;

        if (!content || !cleanContent) return content;

        return cleanContent(content);
    };

    render() {
        const { tempContent } = this.state;
        const { children, isEditing, isEditable, wrapContent, filterQuery, className, enterKeyHint, multiline } =
            this.props;

        const showEditing = isEditable && isEditing;
        const visibleContent = tempContent || children;
        const cleanedContent = this.cleanContent(visibleContent);
        const content = showEditing ? (
            cleanedContent
        ) : (
            // FIXME Externalise the HighlightComponent
            <HighlightedText
                className="title-text"
                highlightText={filterQuery}
                text={cleanedContent}
                HighlightComponent={EditableTitleSearchHighlight}
            />
        );

        return (
            <div
                className={classNames('ElementSimpleContentEditable', { editing: showEditing, multiline }, className)}
                id={this.props.domElementId}
                ref={this.contentRef}
                onClick={this.startEditingContent}
                contentEditable={showEditing}
                enterKeyHint={enterKeyHint}
                suppressContentEditableWarning
                onKeyDown={this.onKeyDown}
                onMouseDown={this.onMouseDown}
                onDoubleClick={this.onDoubleClick}
            >
                {wrapContent && !isEditing ? React.cloneElement(wrapContent, null, content) : content}
            </div>
        );
    }
}

ElementSimpleContentEditable.propTypes = {
    className: PropTypes.string,
    // This is the content
    children: PropTypes.string,

    enterKeyHint: PropTypes.string,
    filterQuery: PropTypes.string,

    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isSelected: PropTypes.bool,
    selectAllOnEdit: PropTypes.bool,
    selectFirst: PropTypes.bool,
    multiline: PropTypes.bool,
    domElementId: PropTypes.string,

    onUpdate: PropTypes.func,
    onStartEditing: PropTypes.func,
    onStopEditing: PropTypes.func,
    onEmptyBackspace: PropTypes.func,

    cleanContent: PropTypes.func,
    wrapContent: PropTypes.element,

    maxLength: PropTypes.number,
};

export default ElementSimpleContentEditable;
