import { DragState } from '../hooks/useSheetHandlers';
import { RefObject, TouchEvent } from 'react';

/**
 * Get the scrollable parent elements of the target element, within the sheet content.
 */
export const getScrollableParentElementsOfTarget = (
    touchTarget: EventTarget,
    sheetRef: RefObject<HTMLDivElement>,
): HTMLElement[] => {
    if (!sheetRef.current || !touchTarget) return [];

    const dataScrollableElements = Array.from(
        sheetRef.current.querySelectorAll("[data-scrollable='true']"),
    ) as HTMLElement[];

    return dataScrollableElements.filter(
        (element) => element.contains(touchTarget as Node) && element.scrollHeight > element.clientHeight,
    );
};

export const isMoveScroll = (
    event: TouchEvent,
    dragState: RefObject<DragState>,
    sheetRef: RefObject<HTMLDivElement>,
): boolean => {
    const scrollableElements = getScrollableParentElementsOfTarget(event.target, sheetRef);
    if (!scrollableElements.length) return false;

    // If the user is touching scrollable content, then this is the only scenario where we want to drag the sheet
    // Note that this is just for the first touch move, so they may pull up after
    const { velocity = 0 } = dragState.current || {};
    const isPullingDown = velocity < 0;
    const areAllScrollableElementsAtTop = scrollableElements?.every((element) => element.scrollTop === 0);
    const isMoveDrag = isPullingDown && areAllScrollableElementsAtTop;
    return !isMoveDrag;
};
